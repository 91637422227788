export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i;
export const EXERCISE_PERIOD_REGEX = /^(\d+\s+([dD]ays?|[yY]ears?|[wW]eeks?|[mM]onths?|[qQ]uarters?)|[uU]nlimited)$/i;

export const requiredDataValidator = (value: string, _row: any) => {
  if (!value) {
    return 'Missing data';
  }
  return true;
};

export const requiredNumberDataValidator = (value: number, _row: any) => {
  if (!value && value !== 0) {
    return 'Missing data';
  }
  return true;
};

export const emailValidator = (value: string, _row: any) => {
  if (value && EMAIL_REGEX.test(value) === false) {
    return 'Invalid email format';
  }
  return true;
};

export const exercisePeriodValidator = (value: string, _row: any) => {
  if (value && EXERCISE_PERIOD_REGEX.test(value) === false) {
    return 'Invalid exercise period value.\n Please enter a value like 2 days / 1 week / 2 quarters / 3 months / 1 years / unlimited';
  }
  return true;
};
