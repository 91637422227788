import { useAlert } from 'react-alert';
import { useMutation } from '@apollo/client';
import { showGraphQLError } from '../../../helpers/utils';
import DELETE_ATTACHMENT_MUTATION from '../mutations/deleteAttachmentMutation';

const useDeleteAttachment = (afterRemove?: () => void) => {
  const alert = useAlert();
  const [
    removeAttachment,
    { loading: mutationLoading, called, error: deleteError },
  ] = useMutation(DELETE_ATTACHMENT_MUTATION, {
    onCompleted() {
      if (afterRemove) {
        afterRemove();
      }
    },
    onError({ graphQLErrors }) {
      showGraphQLError(alert, graphQLErrors);
    },
  });

  const onDelete = (id: string) => {
    removeAttachment({ variables: { id } });
  };

  return {
    onDelete,
    deleting: mutationLoading && called,
    deleteError,
  };
};

export default useDeleteAttachment;
