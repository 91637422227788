import React from 'react';
import { Header, Stack } from '@angellist/adapt';

import { DocumentsCardProps } from './index.types';
import AttachmentList from './AttachmentList';
import FileUploadModal from './FileUploadModal';
import AttachmentListLoader from './AttachmentListLoader';

const DocumentsCard = (props: DocumentsCardProps) => {
  const {
    headerText,
    description,
    documents,
    emptyDocumentText,
    canRemove,
    canUpload,
    canDownload,
    afterRemove,
    afterUpload,
    loading,
    deleteConfirmTitle,
    deleteConfirmText,
    suffixHeaderActions,
    numLoadingRows = 3,
    onRowClick,
    rowClickAllowedFileTypes,
    ...restModalProps
  } = props;
  return (
    <Stack gap="100">
      <Stack direction="horizontal" justify="space-between">
        <Header size="200" description={description}>
          {headerText}
        </Header>
        <Stack gap="100" direction="horizontal">
          {!loading && canUpload && (
            <FileUploadModal afterUpload={afterUpload} {...restModalProps} />
          )}
          {suffixHeaderActions}
        </Stack>
      </Stack>
      {loading ? (
        <AttachmentListLoader numLoadingRows={numLoadingRows} />
      ) : (
        <AttachmentList
          documents={documents}
          canRemove={canRemove}
          canDownload={canDownload}
          emptyDocumentText={emptyDocumentText}
          afterRemove={afterRemove}
          deleteConfirmTitle={deleteConfirmTitle}
          deleteConfirmText={deleteConfirmText}
          onRowClick={onRowClick}
          rowClickAllowedFileTypes={rowClickAllowedFileTypes}
        />
      )}
    </Stack>
  );
};

DocumentsCard.defaultProps = {
  headerText: 'Documents',
  ownerObjectField: 'documents',
};

export default DocumentsCard;
