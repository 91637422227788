import React from 'react';
import { Box, Text, useToast } from '@angellist/adapt';
import { ErrorType } from '../utils/error';
import { BRANDING } from '../utils/branding';

type ToastOptions = {
  title?: string;
  description?: string;
  durationMS?: number;
  after?: {
    kind: 'button';
    variant: 'secondary' | 'primary';
    label: string;
    onPress: () => void;
  }[];
};

const GraphqlErrors = ({ graphQLErrors }: { graphQLErrors: any }) => {
  const hasError = graphQLErrors && graphQLErrors.length;
  if (hasError) {
    return (
      <Box display="flex" flexDirection="column" paddingTop="100" gap="25">
        {graphQLErrors.length > 1
          ? graphQLErrors.map((e: ErrorType, index: number) => (
              <Text key={index} color="text70">
                {index + 1}) {e.message}
              </Text>
            ))
          : graphQLErrors.map((e: ErrorType, index: number) => (
              <Text key={index} color="text70">
                {e.message}
              </Text>
            ))}
      </Box>
    );
  }

  return (
    <Box paddingTop="50" color="text70">
      There was an error. Either try again or contact{' '}
      <a href={`mailto:${BRANDING.supportEmail}`}>{BRANDING.supportEmail}</a>
    </Box>
  );
};

const useAdaptToast = () => {
  const { addToast } = useToast();

  const showSuccessToast = (title: string, options?: ToastOptions) =>
    addToast({
      title,
      prefix: {
        kind: 'icon',
        named: 'successFill',
        color: 'successFill',
      },
      ...(options || {}),
    });

  const showErrorToast = (title: string, options?: ToastOptions) =>
    addToast({
      title,
      prefix: {
        kind: 'icon',
        named: 'dangerCircleFill',
        color: 'dangerFill',
      },
      ...(options || {}),
    });

  const showWarningToast = (title: string, options?: ToastOptions) =>
    addToast({
      title,
      prefix: {
        kind: 'icon',
        named: 'warningFill',
        color: 'warningFill',
      },
      ...(options || {}),
    });

  const showGraphQLErrorToast = (graphQLErrors: any, options?: ToastOptions) =>
    addToast({
      title: 'Something went wrong',
      // @ts-ignore
      description: <GraphqlErrors graphQLErrors={graphQLErrors} />,
      prefix: {
        kind: 'icon',
        named: 'dangerCircleFill',
        color: 'dangerFill',
      },
      ...(options || {}),
    });

  return {
    showSuccessToast,
    showErrorToast,
    showWarningToast,
    showGraphQLErrorToast,
  };
};

export default useAdaptToast;
