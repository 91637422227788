import { useMutation } from '@apollo/client';
import { useAlert } from 'react-alert';

import { useCompanyContext } from '../../../context/Context';
import { showGraphQLError } from '../../../helpers/utils';
import UPLOAD_FILE_MUTATION from '../mutations/uploadFileMutation';

const useUploadFiles = (afterComplete: (data: object) => void) => {
  const { id: companyId } = useCompanyContext();
  const alert = useAlert();

  const [
    uploadFile,
    { loading: mutationLoading, called, error: mutationError },
  ] = useMutation(UPLOAD_FILE_MUTATION, {
    onCompleted(data) {
      afterComplete(data.uploadFile);
    },
    onError({ graphQLErrors }) {
      showGraphQLError(alert, graphQLErrors);
    },
    context: {
      hasUpload: true,
    },
  });

  const onUpload = (formData: object) => {
    uploadFile({
      variables: {
        companyId,
        ...formData,
      },
    });
  };

  return {
    submitting: called && mutationLoading,
    mutationError,
    onUpload,
  };
};

export default useUploadFiles;
