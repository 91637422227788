import React from 'react';
import { Link } from 'react-router-dom';

import ShowIcon from '../../../../css/icons/Show';
import EditSvg from '../../../../css/icons/Edit';
import ActionMenu from '../../common/ActionMenu';
import ActionButton from '../../common/ActionButton';

import useModal from '../../common/hooks/useModal';
import paths from '../../../paths';
import EditExerciseModal from './EditExerciseModal';
import { EsopExercise, EsopGrant } from '../../../../graphql';
import { VoidFunc } from '../../../../types';

type Props = {
  esopGrant: EsopGrant;
  esopExercise: EsopExercise;
  refetchExercises: VoidFunc;
};

const ExerciseRowActions = (props: Props) => {
  const { esopGrant, esopExercise, refetchExercises } = props;
  const editActions = useModal();

  return (
    <>
      <ActionMenu inNavbar={false} container="body">
        <ActionButton
          // @ts-ignore
          tag={Link}
          to={paths.equity.exercise(esopExercise.id)}
          buttonType="dropdown-item"
          buttonText="View"
          buttonIcon={<ShowIcon />}
        />
        <ActionButton
          buttonType="dropdown-item"
          buttonText="Edit"
          buttonIcon={<EditSvg />}
          onClick={() => editActions.openModal()}
        />
      </ActionMenu>
      {editActions.modal && (
        <EditExerciseModal
          esopGrant={esopGrant}
          modal={editActions.modal}
          closeModal={editActions.closeModal}
          esopExercise={esopExercise}
          refetchExercises={refetchExercises}
        />
      )}
    </>
  );
};

export default ExerciseRowActions;
