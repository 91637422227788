import { ObjectType } from '../../../types';
import paths from '../../paths';

export const STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  REPURCHASED: 'repurchased',
  CONVERTED: 'converted',
  TRANSFERRED: 'transferred',
  FULL_TRANSFER: 'full_transfer',
  PARTIAL_TRANSFER: 'partial_transfer',
  PENDING_ACCEPTANCE: 'pending_acceptance',
};

export const STATUS_SORT_ORDER = {
  [STATUS.DRAFT]: 0,
  [STATUS.PENDING_ACCEPTANCE]: 1,
  [STATUS.ACTIVE]: 2,
  [STATUS.CONVERTED]: 3,
  [STATUS.CANCELLED]: 4,
  [STATUS.REPURCHASED]: 5,
  [STATUS.TRANSFERRED]: 6,
  [STATUS.FULL_TRANSFER]: 7,
  [STATUS.PARTIAL_TRANSFER]: 8,
};

export const STATUS_GROUPS = [
  [STATUS.DRAFT, [STATUS.DRAFT]],
  [STATUS.ACTIVE, [STATUS.ACTIVE, null]],
  [STATUS.TRANSFERRED, [STATUS.FULL_TRANSFER, STATUS.PARTIAL_TRANSFER]],
  [STATUS.REPURCHASED, [STATUS.REPURCHASED]],
  [STATUS.CONVERTED, [STATUS.CONVERTED]],
  [STATUS.CANCELLED, [STATUS.CANCELLED]],
];

export const STATUS_GROUP_MAP = STATUS_GROUPS.reduce((acc, value) => {
  const [status, stautsGroup]: any = value;
  acc[status] = stautsGroup;
  return acc;
}, {} as ObjectType);

export const CERTIFICATE_TYPES = {
  RSA: 'rsa',
  CERTIFICATE: 'certificate',
};

export const SHARE_TYPE_OPTIONS = [
  {
    key: CERTIFICATE_TYPES.CERTIFICATE,
    label: 'Certificate',
    value: CERTIFICATE_TYPES.CERTIFICATE,
  },
  {
    key: CERTIFICATE_TYPES.RSA,
    label: 'Shares From Equity Plan',
    value: CERTIFICATE_TYPES.RSA,
  },
];

export const OUTSTANDING_STATUS = {
  DRAFT: 'draft',
  OUTSTANDING: 'outstanding',
  CANCELLED: 'cancelled',
};

export const OUTSTANDING_STATUS_OPTIONS = [
  {
    key: OUTSTANDING_STATUS.DRAFT,
    label: 'Draft',
    value: OUTSTANDING_STATUS.DRAFT,
  },
  {
    key: OUTSTANDING_STATUS.OUTSTANDING,
    label: 'Outstanding',
    value: OUTSTANDING_STATUS.OUTSTANDING,
  },
  {
    key: OUTSTANDING_STATUS.CANCELLED,
    label: 'Cancelled',
    value: OUTSTANDING_STATUS.CANCELLED,
  },
];

export const STATUS_OPTIONS = [
  {
    key: STATUS.DRAFT,
    label: 'Draft',
    value: STATUS.DRAFT,
  },
  {
    key: STATUS.TRANSFERRED,
    label: 'Transferred',
    value: STATUS.TRANSFERRED,
  },
  {
    key: STATUS.ACTIVE,
    label: 'Active',
    value: STATUS.ACTIVE,
  },
  {
    key: STATUS.CANCELLED,
    label: 'Cancelled',
    value: STATUS.CANCELLED,
  },
  {
    key: STATUS.REPURCHASED,
    label: 'Repurchased',
    value: STATUS.REPURCHASED,
  },
  {
    key: STATUS.CONVERTED,
    label: 'Converted',
    value: STATUS.CONVERTED,
  },
];

export const STATUS_FILTER_MAP: any = {
  drafts: [STATUS.DRAFT],
  issued: [
    STATUS.ACTIVE,
    STATUS.CANCELLED,
    STATUS.CONVERTED,
    STATUS.REPURCHASED,
    STATUS.TRANSFERRED,
    STATUS.FULL_TRANSFER,
    STATUS.PARTIAL_TRANSFER,
  ],
};

export const FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: 'drafts',
    value: 'drafts',
    label: 'Drafts',
  },
  {
    key: 'issued',
    value: 'issued',
    label: 'Issued',
  },
];

export const AllShareCertificateDraftsBreadcrumbs = [
  {
    title: 'Shares',
    link: paths.equity.drafts.shares.all,
  },
];

export const ShareCertificateBreadcrumbs = [
  {
    title: 'Shares',
    link: paths.equity.shareCertificates,
  },
];

export const ShareCertificateSecurityBreadcrumbs = [
  {
    title: 'Securities',
    link: paths.equity.securities.all,
  },
  {
    title: 'Shares',
    link: paths.equity.securities.shares.all,
  },
];
