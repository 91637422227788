import { gql } from '@apollo/client';

const UPLOAD_FILE_MUTATION = gql`
  mutation UploadFileMutation(
    $companyId: String!
    $ownerObjectType: String!
    $ownerObjectId: String!
    $ownerObjectField: String!
    $documents: [File!]!
  ) {
    uploadFile(
      companyId: $companyId
      ownerObjectType: $ownerObjectType
      ownerObjectId: $ownerObjectId
      ownerObjectField: $ownerObjectField
      documents: $documents
    ) {
      id
      documentName
      documentPath
      createdAt
    }
  }
`;

export default UPLOAD_FILE_MUTATION;
