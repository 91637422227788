import React from 'react';
import { Money } from '../../../../graphql';
import {
  formatDate,
  formatMoneyObject,
  formatNumber,
  formatTitle,
} from '../../../helpers/utils';
import StatusTag from '../StatusTag';
import { formatDateTime } from '../../../utils/date';

export const COLUMN_TYPES = {
  FILTER_ONLY: {
    isFilterOnly: true,
    csvExport: false,
    skipToggle: true,
    name: '',
  },
  SORT_ONLY: {
    isHidden: true,
    csvExport: false,
    skipToggle: true,
    name: '',
    allowsSorting: true,
  },
  ACTION: {
    dataField: 'action',
    csvExport: false,
    skipToggle: true,
    name: '',
  },
};

export const CELL_PROPS = {
  TEXT: {
    kind: 'text' as 'text',
  },
  TAG: {
    kind: 'tag' as 'tag',
  },
  EDITABLE: {
    kind: 'editable' as 'editable',
  },
  CUSTOM: {
    kind: 'custom' as 'custom',
  },
  OVERFLOW_MENU: {
    kind: 'overflowMenu' as 'overflowMenu',
  },
  DATE: {
    kind: 'date' as 'date',
    format: {
      year: 'numeric' as 'numeric',
      month: 'short' as 'short',
      day: 'numeric' as 'numeric',
    },
    emptyValue: '—',
    csvFormatter: (date: Date) => formatDate(date, '—'),
  },
  DATE_TIME: {
    kind: 'date' as 'date',
    format: {
      year: 'numeric' as 'numeric',
      month: 'short' as 'short',
      day: 'numeric' as 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    emptyValue: '—',
    csvFormatter: (date: Date) => formatDateTime(date, '—'),
  },
  MONEY: {
    kind: 'money' as 'money',
    emptyValue: '—',
  },
  CUSTOM_MONEY: {
    kind: 'custom' as 'custom',
    emptyValue: '—',
    formatter: (moneyObj: Money) => formatMoneyObject(moneyObj),
  },
  BETTER_MONEY: {
    kind: 'custom' as 'custom',
    emptyValue: '—',
    formatter: (moneyObj: Money) => (moneyObj ? moneyObj.betterFormat : '—'),
    csvFormatter: (moneyObj: Money) => (moneyObj ? moneyObj.betterFormat : '—'),
    searchValue: (moneyObj: Money) =>
      moneyObj ? `${moneyObj.dollars || moneyObj.betterFormat}` : '—',
  },
  NUMBER: {
    kind: 'text' as 'text',
    cellFormatter: (_row: any, _rowIndex: any, num: number) => ({
      textValue: num === undefined || num === null ? '—' : num,
    }),
    formatter: (number?: number) => formatNumber(number),
    csvFormatter: (number?: number) => formatNumber(number),
  },
  STATUS_TAG: {
    kind: 'custom' as 'custom',
    formatter: (status: string) => <StatusTag status={status} />,
    csvFormatter: (status: string) => formatTitle(status),
    searchValue: (status: string) => formatTitle(status),
  },
};
