import { gql } from 'graphql-tag';

export const BULK_ENABLE_ESOP_GRANT_EXERCISE_MUTATION = gql`
  mutation BulkEnableEsopGrantExercise(
    $companyId: ID!
    $bulkEnableEsopGrantExerciseAttributes: [BulkEnableEsopGrantExerciseAttributes!]!
  ) {
    bulkEnableEsopGrantExercise(
      companyId: $companyId
      bulkEnableEsopGrantExerciseAttributes: $bulkEnableEsopGrantExerciseAttributes
    )
  }
`;
