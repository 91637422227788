import * as React from 'react';

function SvgShow(props) {
  return (
    <svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.265894 7.33465C1.66054 4.17788 4.598 2 8.0002 2C11.4058 2 14.3403 4.17788 15.7345 7.33464C15.9217 7.7584 15.9217 8.24161 15.7345 8.66535C14.3399 11.8221 11.4024 14 8.0002 14C4.598 14 1.66054 11.8221 0.265894 8.66535C0.0786864 8.24161 0.0786864 7.75839 0.265894 7.33465ZM10.5002 8C10.5002 9.38071 9.38091 10.5 8.0002 10.5C6.61949 10.5 5.5002 9.38071 5.5002 8C5.5002 6.61929 6.61949 5.5 8.0002 5.5C9.38091 5.5 10.5002 6.61929 10.5002 8ZM12.0002 8C12.0002 10.2091 10.2093 12 8.0002 12C5.79106 12 4.0002 10.2091 4.0002 8C4.0002 5.79086 5.79106 4 8.0002 4C10.2093 4 12.0002 5.79086 12.0002 8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default SvgShow;
