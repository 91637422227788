import { Box, Dialog, Stack, Text } from '@angellist/adapt';
import { ApolloError } from '@apollo/client';
import React, { ReactNode } from 'react';
import ErrorAlert from './ErrorAlert';

interface Props {
  isOpen: boolean;
  onDelete: () => void;
  onCancel: () => void;
  headerText?: string;
  confirmText?: string | ReactNode;
  isDeleting?: boolean;
  deleteError?: string | ApolloError;
  actionButtonLabel?: string;
  actionButtonVariant?: 'primary' | 'secondary' | 'danger';
}

const AdaptDeleteDialog = (props: Props) => {
  const {
    isOpen,
    onDelete,
    onCancel,
    headerText,
    confirmText,
    isDeleting,
    deleteError,
    actionButtonLabel,
    actionButtonVariant,
  } = props;

  return (
    <Dialog
      // @ts-ignore
      title={<Box wordBreak="break-word">{headerText}</Box>}
      isOpen={isOpen}
      onClose={onCancel}
      actionButtons={[
        {
          label: actionButtonLabel,
          key: 'delete',
          variant: actionButtonVariant,
          isDisabled: isDeleting,
          isLoading: isDeleting,
          onPress: onDelete,
        },
      ]}
    >
      <Stack>
        <Text>{confirmText}</Text>
        {deleteError && <ErrorAlert error={deleteError} />}
      </Stack>
    </Dialog>
  );
};

AdaptDeleteDialog.defaultProps = {
  headerText: 'Are you sure?',
  confirmText: 'Are you sure you want to delete this?',
  actionButtonVariant: 'danger',
  actionButtonLabel: 'Delete',
};

export default AdaptDeleteDialog;
