import { useQuery } from '@apollo/client';
import GRANT_SURRENDER_QUERY from '../queries/grantSurrenderQuery';
import { cloneObject } from '../../../utils';

const useGrantSurrender = (id: string) => {
  const { loading, error, data } = useQuery(GRANT_SURRENDER_QUERY, {
    variables: { id },
  });

  if (error) {
    throw error;
  }

  const grantSurrender = cloneObject(data?.grantSurrender);

  return [loading, grantSurrender];
};

export default useGrantSurrender;
