// Library
import { useQuery } from '@apollo/client';
import { useCompanyContext } from '../../../context/Context';

// Hooks
import ALL_GRANT_DOCUMENT_SETS_QUERY from '../queries/AllGrantDocumentSetsQuery';
import { cloneList } from '../../../utils';

const useGrantDocumentSets = () => {
  const { id: companyId } = useCompanyContext();
  const { loading, error, data } = useQuery(ALL_GRANT_DOCUMENT_SETS_QUERY, {
    variables: { companyId },
  });

  if (error) {
    return null;
  }

  const grantDocumentSets = cloneList(data?.allGrantDocumentSets);

  return [loading, grantDocumentSets];
};

export default useGrantDocumentSets;
