import React, { useMemo } from 'react';

import BulkFixGridEditor from './BulkFixGridEditor';

import { EMPLOYEE_TYPE_OPTIONS } from '../../../stakeholders/constant';
import { NEW_SERVICE_PROVIDER_RELATIONSHIP_OPTIONS } from '../../../employees/constants';

import { requiredDataValidator } from '../../../share_classes/utils';
import {
  CURRENCY_PRECISION,
  formatMicroCentsToDollars,
  formatToMicroCents,
  getOptions,
} from '../../../../helpers/utils';

import useAdaptToast from '../../../../../shared/hooks/useAdaptToast';
import { useCompanyContext } from '../../../../context/CompanyContext';
import useBulkEnableExercise from '../../hooks/useBulkEnableExercise';
import useGrantValuations from '../../../esop_grants/hooks/useGrantValuations';
import useGrantDocumentSets from '../../../grant_document_set/hooks/useGrantDocumentSets';

import { GrantDocumentSet, HealthCheck } from '../../../../../graphql';
import { ModalActions } from '../../../../../types';
import { GridRowType } from '../../../../../shared/components/grid_editor/types';

type Props = {
  healthChecks: HealthCheck[];
  refetchHealthChecks: () => void;
} & Pick<ModalActions, 'modal' | 'closeModal'>;

const useImportColumns = () => {
  const { showStakeholderRelationshipUI } = useCompanyContext();
  const [documentSetLoading, documentSets] = useGrantDocumentSets();
  const { valuationOptions } = useGrantValuations(null);
  const isLoading = documentSetLoading;

  const columns = [
    {
      label: 'Name',
      key: 'name',
      type: 'nonEditable' as 'nonEditable',
    },
    {
      label: 'Stakeholder',
      key: 'stakeholder',
      width: 180,
      isResizable: false,
      type: 'nonEditable' as 'nonEditable',
    },
    {
      label: 'Service Type',
      key: 'employeeType',
      type: 'dropdown' as 'dropdown',
      items: showStakeholderRelationshipUI
        ? NEW_SERVICE_PROVIDER_RELATIONSHIP_OPTIONS
        : EMPLOYEE_TYPE_OPTIONS,
      validators: [requiredDataValidator],
    },
    {
      label: 'Exercise Document Template',
      key: 'usedGrantDocumentSetId',
      type: 'dropdown' as 'dropdown',
      width: 180,
      items: getOptions(documentSets),
      validators: [
        requiredDataValidator,
        (usedGrantDocumentSetId: string) => {
          if (usedGrantDocumentSetId) {
            const selectedDocumentSet = documentSets.find(
              (documentSet: GrantDocumentSet) =>
                documentSet.id === usedGrantDocumentSetId,
            );

            if (!selectedDocumentSet?.exerciseLetterTemplate) {
              return 'Missing exercise document template';
            }
          }
          return true;
        },
      ],
    },
    {
      label: 'Effective valuation on grant date',
      key: 'valuationId',
      type: 'dropdown' as 'dropdown',
      width: 230,
      items: valuationOptions,
      validators: [
        (valuationId: string, row: any) => {
          if (!valuationId && !row.fairMarketValueCents) {
            return 'Please select the effective valuation on grant date or input a custom FMV';
          }
          return true;
        },
      ],
    },
    {
      label: 'Custom FMV on grant date',
      key: 'fairMarketValueCents',
      type: 'money' as 'money',
      currencyKeyField: 'fairMarketValueCurrency',
      decimalsLimit: CURRENCY_PRECISION.MAX,
      validators: [
        (fairMarketValueCents: string, row: any) => {
          if (!fairMarketValueCents && !row.valuationId) {
            return 'Please select the effective valuation on grant date or input a custom FMV';
          }
          return true;
        },
      ],
    },
  ];

  return {
    columns,
    isLoading,
  };
};

const BulkEnableExerciseModal = (props: Props) => {
  const { healthChecks, modal, closeModal, refetchHealthChecks } = props;

  const { showSuccessToast, showErrorToast } = useAdaptToast();
  const { isLoading, columns } = useImportColumns();
  const { submitting, onSave } = useBulkEnableExercise(() => {
    closeModal();
    refetchHealthChecks();
    showSuccessToast('Option grant exercise enabled successfully');
  });

  const initialData = useMemo(
    () =>
      healthChecks?.map((healthCheck) => {
        const {
          id,
          name,
          stakeholder,
          employee,
          grantTerms,
          grantDocumentSet,
        } = healthCheck.esopGrant;
        const { fairMarketValueCents, fairMarketValueCurrency } =
          grantTerms || {};

        return {
          grantId: id,
          name,
          stakeholder: stakeholder.name || '',
          employeeType: employee?.employeeType || '',
          usedGrantDocumentSetId: grantDocumentSet?.id || '',
          fairMarketValueCents: formatMicroCentsToDollars(fairMarketValueCents),
          fairMarketValueCurrency,
          valuationId: healthCheck.esopGrant.valuation?.id || '',
        };
      }),
    [healthChecks],
  );

  const handleOnSave = (formdata: any) => {
    const attributes: any = [];

    formdata.forEach((row: GridRowType) => {
      if (row) {
        attributes.push({
          grantId: row.grantId,
          valuationId: row.valuationId,
          fairMarketValueCents: formatToMicroCents(row.fairMarketValueCents),
          fairMarketValueCurrency: row.fairMarketValueCurrency,
          usedGrantDocumentSetId: row.usedGrantDocumentSetId,
          employeeType: row.employeeType,
        });
      }
    });
    if (attributes.length > 0) {
      onSave(attributes);
    } else {
      showErrorToast('Please select at least one grant to enable exercise');
    }
  };

  if (isLoading) return null;

  return (
    <BulkFixGridEditor
      title="Bulk Enable Exercise"
      modal={modal}
      closeModal={closeModal}
      submitting={submitting}
      onSave={handleOnSave}
      initialData={initialData}
      // @ts-ignore
      columns={columns}
    />
  );
};

export default BulkEnableExerciseModal;
