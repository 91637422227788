import { gql } from '@apollo/client';
import ESOP_GRANT_FRAGMENT from '../fragments/esopGrantFragment';

const GRANT_SURRENDER_QUERY = gql`
  query GrantSurrender($id: ID!) {
    grantSurrender(id: $id) {
      id
      state
      numUnits
      surrenderType
      surrenderedAt
      grantType
      createdAt
      reason
      adminNotes
      surrenderedAt
      paymentMode
      price {
        fractional
        dollars
        currency
        betterFormat
      }
      buybackEligibility {
        validFrom
        description
        maxBuybackShares
        maxBuybackPercentOfVested
        buyback {
          id
          name
        }
        priceOfferedPerShare {
          fractional
          betterFormat
        }
      }
      company {
        currency
      }
      grant {
        ...EsopGrantFragment
      }
    }
  }
  ${ESOP_GRANT_FRAGMENT}
`;

export default GRANT_SURRENDER_QUERY;
