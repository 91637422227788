import { Box, Button, Stack } from '@angellist/adapt';
import React, { ReactNode } from 'react';
import GridEditorFilters from './GridEditorFilters';
import { FilterChangeFunc } from '../types';

type Props = {
  addRowsCount?: number;
  onAddRowsClick: (numRows?: number) => void;
  onFilterChange: FilterChangeFunc;
  canAddRows: boolean;
  buttons?: Array<ReactNode>;
  isLoading?: boolean;
};

const TopActionBar = (props: Props) => {
  const {
    onAddRowsClick,
    onFilterChange,
    addRowsCount = 10,
    canAddRows,
    buttons = [],
    isLoading = false,
  } = props;

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      height="400"
      backgroundColor="surface5"
      padding="100"
    >
      <GridEditorFilters onFilterChange={onFilterChange} />
      <Stack direction="horizontal">
        {canAddRows && (
          <Button
            size="compact"
            label={addRowsCount > 1 ? 'Add rows' : 'Add row'}
            prefix={'plus' as 'plus'}
            variant="secondary"
            onPress={() => onAddRowsClick(addRowsCount)}
            isDisabled={isLoading}
          />
        )}
        {buttons}
      </Stack>
    </Box>
  );
};

export default TopActionBar;
