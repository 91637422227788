import { gql, useQuery } from '@apollo/client';
import { useCompanyContext } from '../../../context/Context';
import { OptionType } from '../../../../types';
import { ShareCertificate } from '../../../../graphql';

const SHARE_CERTICATE_OPTIONS_QUERY = gql`
  query shareCertifiacteOptions($companyId: ID!) {
    allCertificates(companyId: $companyId) {
      id
      certificateId
      shareholder {
        id
        entityName
      }
    }
  }
`;

const useCertificatesOptionsList = (shareCertificateId?: string) => {
  const { id: companyId } = useCompanyContext();

  const { loading, data } = useQuery(SHARE_CERTICATE_OPTIONS_QUERY, {
    variables: { companyId },
  });

  const defaultData: any = { allCertificates: [] };

  const { allCertificates } = data || defaultData;

  const shareCertificateOptions: OptionType[] = [];
  allCertificates.forEach((shareCert: ShareCertificate) => {
    if (shareCertificateId !== shareCert.id) {
      shareCertificateOptions.push({
        value: shareCert.id,
        label: `${shareCert.certificateId}: ${shareCert.shareholder.entityName}`,
      });
    }
  });

  return [loading, shareCertificateOptions];
};

export default useCertificatesOptionsList;
