import { useMutation } from '@apollo/client';
import { VoidFunc } from '../../../../types';
import { useCompanyContext } from '../../../context/CompanyContext';
import ALL_EQUITY_TRANSACTIONS_QUERY from '../../equity_transactions/queries/allEquityTransactionsQuery';
import UPDATE_ESOP_EXERCISE_MUTATION from '../mutations/updateExerciseMutation';
import ALL_CERTIFICATES_QUERY from '../../share_certificates/queries/allCertificatesQuery';

const useUpdateEsopExercise = (
  id: string,
  addNewShareCertificate: boolean,
  afterUpdate: VoidFunc,
) => {
  const { id: companyId } = useCompanyContext();

  const refetchQueries: any = [
    {
      query: ALL_EQUITY_TRANSACTIONS_QUERY,
      variables: { companyId },
    },
  ];

  if (addNewShareCertificate) {
    refetchQueries.push({
      query: ALL_CERTIFICATES_QUERY,
      variables: { id: companyId },
    });
  }

  const [
    updateExercise,
    { loading: mutationLoading, error: mutationError, called },
  ] = useMutation(UPDATE_ESOP_EXERCISE_MUTATION, {
    onCompleted() {
      afterUpdate();
    },
    refetchQueries,
    context: {
      hasUpload: true,
    },
  });

  const onUpdate = (formData: any) => {
    const {
      pricePaid,
      shareCertificateName,
      pricePerShare,
      ...restFormData
    } = formData;
    const esopExerciseAttributes = {
      pricePaidCents: pricePaid?.fractional,
      pricePaidCurrency: pricePaid?.currency,
      ...restFormData,
    };

    if (shareCertificateName) {
      esopExerciseAttributes.shareCertificateName = shareCertificateName;
      esopExerciseAttributes.pricePerShareCents = pricePerShare?.fractional;
      esopExerciseAttributes.pricePerShareCurrency = pricePerShare?.currency;
    }

    updateExercise({
      variables: {
        id,
        esopExerciseAttributes,
      },
    });
  };

  return {
    submitting: mutationLoading && called,
    mutationError,
    onUpdate,
  };
};

export default useUpdateEsopExercise;
