import { useAlert } from 'react-alert';
import { useMutation } from '@apollo/client';

import { showGraphQLError } from '../../../helpers/utils';
import { useCompanyContext } from '../../../context/CompanyContext';
import HEALTH_CHECK_STATUSES_QUERY from '../queries/HealthCheckStatusesQuery';
import { BULK_ENABLE_ESOP_GRANT_EXERCISE_MUTATION } from '../mutations/BulkEnableEsopGrantExerciseMutation';

const useBulkEnableExercise = (afterSave: () => void) => {
  const alert = useAlert();
  const { id: companyId } = useCompanyContext();

  const [
    bulkEnableEsopGrantExercise,
    { loading: mutationLoading, called },
  ] = useMutation(BULK_ENABLE_ESOP_GRANT_EXERCISE_MUTATION, {
    onCompleted() {
      afterSave();
    },
    onError: ({ graphQLErrors }) => {
      showGraphQLError(alert, graphQLErrors);
    },
    refetchQueries: [
      {
        query: HEALTH_CHECK_STATUSES_QUERY,
        variables: { companyId },
      },
    ],
  });

  const onSave = async (bulkEnableEsopGrantExerciseAttributes: any) =>
    bulkEnableEsopGrantExercise({
      variables: {
        companyId,
        bulkEnableEsopGrantExerciseAttributes,
      },
    });

  return { submitting: mutationLoading && called, onSave };
};

export default useBulkEnableExercise;
