import React from 'react';

import AdaptModalButton from '../../../common/AdaptModalButton';
import BulkEnableExerciseModal from './BulkEnableExerciseModal';

import { HealthCheck } from '../../../../../graphql';
import { CHECK_TYPES } from '../../constant';

type Props = {
  checkType: string;
  healthChecks: HealthCheck[];
  refetchHealthChecks: () => void;
};

const getButtonProps = (checkType: string) => {
  if (checkType === CHECK_TYPES.EQUITY_GRANT_NOT_EXERCISE_ELIGIBLE) {
    return {
      label: 'Bulk Enable Exercise',
      modalComponent: BulkEnableExerciseModal,
    };
  }

  return {};
};

const BulkEnableExerciseCheckButton = (props: Props) => {
  const { checkType, healthChecks, refetchHealthChecks } = props;

  if (healthChecks?.length) {
    const { label, modalComponent } = getButtonProps(checkType);
    return (
      <AdaptModalButton
        label={label}
        buttonProps={{ variant: 'accent' }}
        modalComponent={modalComponent}
        modalProps={{ healthChecks, refetchHealthChecks }}
      />
    );
  }

  return null;
};

export default BulkEnableExerciseCheckButton;
