import { gql } from '@apollo/client';

const REPURCHASE_EVENT_QUERY = gql`
  query RepurchaseEvent($id: ID!) {
    repurchaseEvent(id: $id) {
      id
      numUnits
      reason
      effectiveAt
      paidAt
      payout {
        fractional
        dollars
        currency
      }
      adminNotes
      instrumentTo {
        id
        certificateId
      }
      instrument {
        id
        certificateId
      }
      documents {
        id
        documentName
        documentPath
        createdAt
      }
    }
  }
`;

export default REPURCHASE_EVENT_QUERY;
