import React from 'react';

import { HealthCheck, RsuGrant } from '../../../graphql';
import {
  DECIMAL_LIMIT,
  formatNumber,
  formatNumberWithPrecision,
  getEmployeeName,
} from '../../helpers/utils';
import UnitsColumn from '../common/UnitsColumn';
import { STATUS } from '../grants/constants';
import { NameColumn } from '../../helpers/formatters';
import paths from '../../paths';

export const getStakeholderName = (grant: RsuGrant, emptyString = '') => {
  const { employee } = grant;
  if (employee) {
    return employee?.stakeholder?.name || getEmployeeName(employee, '');
  }
  return emptyString;
};

export const getOptionHolderValue = (
  _: never,
  grant: RsuGrant,
  emptyString = '',
) => getStakeholderName(grant, emptyString);

export const nameFormatter = (name: string, grant: RsuGrant) => {
  const healthChecks =
    grant.healthChecks?.reduce((acc: { [key: string]: HealthCheck }, curr) => {
      if (!curr.source) acc[curr.title] = curr;
      if (curr.source && curr.status === 'active') acc[curr.title] = curr;
      return acc;
    }, {}) || {};

  return (
    <NameColumn
      name={name}
      isDraft={grant.transactionStatus === STATUS.DRAFT}
      healthChecks={Object.values(healthChecks)}
    />
  );
};

export const unitsColumnFormatter = (_: never, grant: RsuGrant) => {
  const { unitsVested, grantTerms } = grant;
  const { numOptions } = grantTerms || {};
  if (numOptions) {
    return (
      <UnitsColumn
        numerator={unitsVested}
        denominator={numOptions}
        denominatorText={formatNumberWithPrecision(
          numOptions,
          DECIMAL_LIMIT.NUM_OPTIONS,
        )}
        hideProgress={grant.transactionStatus === STATUS.DRAFT}
      />
    );
  }
  return null;
};

export const unitsCsvFormatter = (_: never, grant: RsuGrant) => {
  const { unitsVested, grantTerms } = grant;
  const { numOptions } = grantTerms || {};
  if (numOptions) {
    return `${unitsVested}/${formatNumberWithPrecision(
      numOptions,
      DECIMAL_LIMIT.NUM_OPTIONS,
    )}`;
  }
  return null;
};

export const unitsSearchValue = (_: never, grant: RsuGrant) => {
  const { unitsVested, grantTerms } = grant;
  const { numOptions } = grantTerms || {};

  return `${unitsVested}_${numOptions}_${formatNumber(
    unitsVested,
  )}_${formatNumberWithPrecision(numOptions, DECIMAL_LIMIT.NUM_OPTIONS)}`;
};

export const shouldRegenerateGrant = (data: any, rsuGrant: RsuGrant) => {
  const {
    grantDetailAttributes: {
      numOptions,
      exercisePrice,
      expiryDate,
      grantedAt,
      name,
      stakeholderId,
    },
    vestingAttributes: {
      vestingStartBackdate = null,
      vestingTemplateId = null,
      accelerationTemplateId = null,
      acceleratedVestingDescription = null,
    },
    grantDocumentAttributes: { usedGrantDocumentSetId },
  } = data;

  const regenFields = [];
  if (numOptions !== rsuGrant.grantTerms.numOptions) {
    regenFields.push('Number of options');
  }
  if (
    exercisePrice.fractional !== rsuGrant.grantTerms.exercisePrice.fractional
  ) {
    regenFields.push('Exercise price');
  }
  if (expiryDate !== rsuGrant.grantTerms.expiryDate) {
    regenFields.push('Expiry date');
  }
  if (vestingStartBackdate !== rsuGrant.grantTerms.vestingStartBackdate) {
    regenFields.push('Vesting start backdate');
  }
  if (vestingTemplateId !== (rsuGrant.vestingTemplate?.id || null)) {
    regenFields.push('Vesting template');
  }
  if (!!vestingTemplateId && rsuGrant.vestingEvents.length === 0) {
    regenFields.push('Vesting schedule (template set but no events)');
  }
  if (usedGrantDocumentSetId !== rsuGrant.usedGrantDocumentSetId) {
    regenFields.push('Grant document template');
  }
  if (grantedAt !== rsuGrant.grantTerms.grantedAt) {
    regenFields.push('Grant date');
  }
  if (name !== rsuGrant.name) {
    regenFields.push('Grant name');
  }
  if (stakeholderId !== rsuGrant.stakeholder?.id) {
    regenFields.push('Grant holder');
  }
  if (accelerationTemplateId !== (rsuGrant.accelerationTemplate?.id || null)) {
    regenFields.push('Acceleration template');
  }
  if (
    acceleratedVestingDescription !== rsuGrant.acceleratedVestingDescription
  ) {
    regenFields.push('Acceleration description');
  }

  return regenFields;
};

export const rsuGrantBreadcrumbs = (
  isDraft: boolean,
  securityDraftsEnabled: boolean,
) => {
  if (securityDraftsEnabled) {
    if (isDraft) {
      return [
        {
          title: 'Drafts',
          link: paths.equity.drafts.all,
        },
        {
          title: 'RSUs',
          link: paths.equity.drafts.rsu_grants.all,
        },
      ];
    }
    return [
      {
        title: 'Securities',
        link: paths.equity.securities.all,
      },
      {
        title: 'RSUs',
        link: paths.equity.securities.rsu_grants.all,
      },
    ];
  }
  return [
    {
      title: 'RSUs',
      link: paths.equity.rsuGrants.base,
    },
  ];
};

export const formatUpdateArgs = (formData: any) => {
  const {
    exercisePrice,
    fairMarketValue,
    valuationId,
    ...restGrantDetailAttributes
  } = formData.grantDetailAttributes;

  const fmvAttributes: { [key: string]: any } = {};
  if (valuationId === 'custom') {
    fmvAttributes.fairMarketValueCurrency = fairMarketValue?.currency;
    fmvAttributes.fairMarketValueCents = fairMarketValue?.fractional;
  } else {
    fmvAttributes.valuationId = valuationId;
  }

  const newGrantDetailAttributes = {
    exercisePriceCurrency: exercisePrice?.currency,
    exercisePriceCents: exercisePrice?.fractional,
    ...fmvAttributes,
    ...restGrantDetailAttributes,
  };

  return {
    ...newGrantDetailAttributes,
    ...formData.boardConsentAttributes,
    ...formData.documentsAndNotesAttributes,
    ...formData.grantDocumentAttributes,
    ...formData.securitiesComplianceAttributes,
    ...formData.vestingAttributes,
  };
};

export const getRsuGrantPath = (
  rsuGrant: RsuGrant,
  tab?: string,
  securityDraftsEnabled: boolean = false,
) => {
  if (!rsuGrant) return null;
  if (securityDraftsEnabled) {
    if (rsuGrant.transactionStatus === STATUS.DRAFT) {
      return paths.equity.drafts.rsu_grants.show(rsuGrant.id, tab);
    }
    return paths.equity.securities.rsu_grants.show(rsuGrant.id, tab);
  }

  return paths.equity.rsuGrants.show(rsuGrant.id, tab);
};
