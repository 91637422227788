import { gql } from '@apollo/client';

const ALL_EQUITY_TRANSACTIONS_QUERY = gql`
  query AllEquityTransactions($companyId: ID!) {
    allEquityTransactions(companyId: $companyId) {
      id
      state
      numUnits
      createdAt
      instrumentType
      transactionDate
      transactionType
      instrumentId
      instrumentName
      stakeholder {
        id
        name
      }
    }
  }
`;

export default ALL_EQUITY_TRANSACTIONS_QUERY;
