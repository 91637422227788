import { useQuery } from '@apollo/client';

import REPURCHASE_EVENT_QUERY from '../queries/RepurchaseEventQuery';
import { cloneObject } from '../../../utils';

const useRepurchaseEvent = (id: string) => {
  const { loading, error, data } = useQuery(REPURCHASE_EVENT_QUERY, {
    variables: { id },
  });

  if (error) {
    throw error;
  }

  const repurchaseEvent = cloneObject(data?.repurchaseEvent);
  return [loading, repurchaseEvent];
};

export default useRepurchaseEvent;
