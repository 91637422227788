import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Heading, Stack } from '@angellist/adapt';

import SlidingPane from '../../common/SlidingPane';
import StatusTag from '../../common/StatusTag';
import { formatDate } from '../../../utils/date';
import { LazyText } from '../../common/RowLoader';
import { AdaptRowList } from '../../common/AdaptRowItem';
import {
  formatMoneyObject,
  formatNumber,
  formatTitle,
  formatToNumber,
} from '../../../helpers/utils';

import useRepurchaseEvent from '../hooks/useRepurchaseEvent';

import { RepurchaseEvent } from '../../../../graphql';
import { AttachmentList } from '../../common/adapt-documents';
import { useCompanyContext } from '../../../context/CompanyContext';
import { getShareCertificatePath } from '../../share_certificates/utils';

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

const getShareRepurchaseDetails = (
  repurchaseEvent: RepurchaseEvent,
  loading: boolean,
  push: any,
  securityDraftsEnabled: boolean,
) => [
  {
    label: 'Repurchased From',
    suffix: {
      kind: 'textButton',
      label: repurchaseEvent?.instrument?.certificateId,
      onPress: () =>
        push(
          getShareCertificatePath(
            repurchaseEvent?.instrument,
            undefined,
            securityDraftsEnabled,
          ),
        ),
    },
    loading,
  },
  {
    label: 'Balance Re-issued To',
    suffix: {
      kind: 'textButton',
      label: repurchaseEvent?.instrumentTo?.certificateId,
      onPress: () =>
        push(
          getShareCertificatePath(
            repurchaseEvent?.instrumentTo,
            undefined,
            securityDraftsEnabled,
          ),
        ),
    },
    loading,
  },
  {
    label: 'Number of Shares',
    suffix: { label: formatNumber(formatToNumber(repurchaseEvent.numUnits)) },
    loading,
  },
  {
    label: 'Repurchase Date',
    suffix: { label: formatDate(repurchaseEvent.effectiveAt, '—') },
    loading,
  },
  {
    label: 'Price Paid',
    suffix: { label: formatMoneyObject(repurchaseEvent.payout) },
    loading,
  },

  {
    label: 'Payment Completion Date',
    suffix: { label: formatDate(repurchaseEvent.paidAt, '—') },
    loading,
  },
  {
    label: 'Repurchase Reason',
    suffix: { label: formatTitle(repurchaseEvent.reason) || '—' },
    loading,
  },
  {
    label: 'Admin Notes',
    suffix: { label: repurchaseEvent.adminNotes || '—' },
    loading,
  },
];

const ShareRepurchaseDetailPane = (props: Props) => {
  const { isOpen, onClose, id } = props;
  const [loading, repurchaseEvent] = useRepurchaseEvent(id);
  const { documents } = repurchaseEvent;

  const { push } = useHistory();
  const { securityDraftsEnabled } = useCompanyContext();
  const items = getShareRepurchaseDetails(
    repurchaseEvent,
    loading,
    push,
    securityDraftsEnabled,
  );

  return (
    <SlidingPane isOpen={isOpen} closeSlidingPane={onClose} size="sm">
      <Stack gap="250" key={id}>
        <Stack gap="50">
          <Heading>
            <LazyText
              loading={loading}
              text={repurchaseEvent.instrument?.certificateId}
            />
          </Heading>
          <Box>{!!repurchaseEvent && <StatusTag status="repurchased" />}</Box>
        </Stack>
        <AdaptRowList items={items} />
        {!!documents?.length && (
          <Stack gap="100">
            <Heading size="100">Documents</Heading>
            <AttachmentList documents={documents} />
          </Stack>
        )}
      </Stack>
    </SlidingPane>
  );
};

export default ShareRepurchaseDetailPane;
