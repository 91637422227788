import { gql } from '@apollo/client';

const ESOP_GRANT_FRAGMENT = gql`
  fragment EsopGrantFragment on EsopGrant {
    id
    name
    currency
    employee {
      id
      user {
        id
        name
      }
    }
  }
`;

export default ESOP_GRANT_FRAGMENT;
