import { formatServerDate, isInvalidDate } from '../../utils/date';
import {
  ActiveDropdownType,
  GridColumnType,
  GridFilterValueType,
  GridRowType,
  GridRowValue,
  InvalidColumnType,
  InvalidRowType,
  ReactGridColumn,
  ReactGridRow,
  RowConfigType,
  RowOptions,
  ValidatorFunc,
} from './types';
import { HEADER_HEIGHT, ROW_HEIGHT, ROW_STATES } from './constants';
import { getCells, getEmptyCellValue } from './cell_utils';

export const formatRowValues = (row: GridRowType) => {
  const extractedRow: { [key: string]: GridRowValue } = {};
  Object.keys(row).forEach((key) => {
    extractedRow[key] = row[key] as GridRowValue;
  });
  return extractedRow;
};

const filterNonEmptyValues = (row: GridRowType) =>
  Object.values(row).filter((x) => {
    if (x instanceof Array) {
      return x.length > 0;
    }
    if (typeof x === 'number' && x === 0) {
      return true;
    }
    return x;
  });

const isRowEmpty = (row: GridRowType) => filterNonEmptyValues(row).length === 0;

export const isGridRowTouched = (row: GridRowType) =>
  !isRowEmpty(formatRowValues(row));

export const getHeaderRow = (columns: GridColumnType[]): ReactGridRow => ({
  rowId: 'header',
  height: HEADER_HEIGHT,
  cells: columns.map((column) => ({
    type: 'header',
    text: column.label,
    description: column.description,
    className: column.className,
  })),
});

export const getGridColumns = (columns: GridColumnType[]): ReactGridColumn[] =>
  columns.map((column) => ({
    columnId: column.key,
    width: column.width,
    reorderable: column.allowsSorting ?? false,
    resizable: column.isResizable ?? true,
    className: column.className,
  }));

export const validateColumn = (
  validators: ValidatorFunc[],
  value: string | number | string[],
  row: any,
) => {
  if (validators.length === 0) {
    return true;
  }
  let isValid: string | boolean = true;
  validators.forEach((validator) => {
    if (isValid === true) {
      isValid = validator(value, formatRowValues(row));
    }
  });
  return isValid;
};

export const getFilteredRow = (
  row: GridRowType,
  columns: GridColumnType[],
  rowOptions: RowOptions,
  gridFilters: GridFilterValueType,
) => {
  const { rowId, isDirtyRow } = rowOptions;
  const cells = getCells(columns, row, rowOptions);
  let showRow = true;
  if (gridFilters.isFilterApplied) {
    const filterText = cells
      .map((cell: any) => cell.filterText || '')
      .filter((x) => x)
      .join(' ')
      .toLowerCase();
    const hasInvalidCell = cells.some((cell: any) => cell.isInvalidCell);
    const isCleanState = gridFilters.state === ROW_STATES.CLEAN;
    const isIssuesState = gridFilters.state === ROW_STATES.ISSUES;
    if (gridFilters.searchText) {
      const searchText = gridFilters.searchText.toLowerCase();
      if (!filterText.includes(searchText)) {
        showRow = false;
      }
    }
    if (
      (isCleanState && !isDirtyRow) ||
      (isCleanState && hasInvalidCell) ||
      (isIssuesState && !hasInvalidCell)
    ) {
      showRow = false;
    }
  }

  if (showRow) {
    return {
      rowId,
      height: ROW_HEIGHT,
      cells,
    };
  }
  return null;
};

export const getFilteredRows = (
  rows: any[],
  columns: GridColumnType[],
  gridFilters: GridFilterValueType,
  rowConfig: RowConfigType,
) => {
  const filteredRows: any[] = [];

  rows.forEach((row, rowIndex) => {
    const rowId = rowIndex;
    const rowOptions = {
      isDirtyRow: isGridRowTouched(row),
      rowId,
      rowIndex,
      ...rowConfig,
    };
    const filteredRow = getFilteredRow(row, columns, rowOptions, gridFilters);
    if (filteredRow) {
      filteredRows.push(filteredRow);
    }
  });

  return filteredRows;
};

export const getDefaultEmptyRow = (columns: GridColumnType[]) =>
  columns.reduce(
    (acc, column) => ({
      ...acc,
      [column.key]: getEmptyCellValue(column),
    }),
    {},
  );

export const getChangedRows = (
  rows: GridRowType[],
  changes: any[],
  usePreviousCell: boolean = false,
) => {
  const activeDropdown: ActiveDropdownType = {
    rowId: -1,
    columnId: '',
    inputValue: '',
  };
  const newRows = [...rows];
  changes.forEach((change) => {
    const { type, rowId, columnId, newCell, previousCell } = change;
    const rowIndex = rowId as number;
    let newRow: GridRowType = {
      ...newRows[rowIndex],
    };
    const cellToUse = usePreviousCell ? previousCell : newCell;

    if (type === 'text') {
      newRow[columnId] = cellToUse.text;
    } else if (type === 'dropdown' || type === 'multiSelect') {
      const shouldForceCloseDropdown = changes.length > 1 || usePreviousCell;
      if (!shouldForceCloseDropdown && cellToUse.inputValue) {
        activeDropdown.rowId = rowId;
        activeDropdown.columnId = columnId;
        activeDropdown.inputValue = cellToUse.inputValue;
      }
      newRow[columnId] = cellToUse.selectedValue || cellToUse.text;
    } else if (type === 'date') {
      newRow[columnId] = isInvalidDate(cellToUse.date)
        ? ''
        : formatServerDate(cellToUse.date);
    } else if (type === 'checkbox') {
      newRow[columnId] = cellToUse.checked;
    } else if (type === 'number' || type === 'money') {
      const cellValue =
        Number.isNaN(cellToUse.value) ||
        (!cellToUse.value && cellToUse.value !== 0)
          ? undefined
          : cellToUse.value;
      newRow[columnId] = cellValue;
    } else {
      newRow[columnId] = cellToUse.text || cellToUse.value;
    }

    if (cellToUse.getModifiedRow) {
      newRow = cellToUse.getModifiedRow(newRow[columnId], newRow);
    }
    // eslint-disable-next-line no-param-reassign
    newRows[rowIndex] = newRow;
  });

  return {
    changedRows: newRows,
    activeDropdown,
  };
};

export const isUnsavedRowsPresent = (rows: GridRowType[]) =>
  rows.some((row) => isGridRowTouched(row));

export const validateAndFormatRows = (rows: GridRowType[], columns: any[]) => {
  const invalidRows: InvalidRowType[] = [];
  const formattedRows: { [key: string]: GridRowValue }[] = [];
  rows.forEach((row, rowIndex) => {
    const inValidColumns: InvalidColumnType[] = [];
    const formattedRow = formatRowValues(row);
    const isDirtyRow = !isRowEmpty(formattedRow);

    if (isDirtyRow) {
      columns.forEach((column) => {
        if (column.validators?.length) {
          const value: any = row[column.key];
          const isValid = validateColumn(column?.validators, value, row);
          if (typeof isValid === 'string') {
            inValidColumns.push({
              columnId: column.key,
              columnName: column.label,
              message: isValid,
            });
          }
        }
      });
      if (inValidColumns.length) {
        invalidRows.push({
          rowIndex,
          errors: inValidColumns,
        });
      }
      formattedRows.push(formattedRow);
    }
  });
  return { invalidRows, formattedRows };
};
