import { getOptionsNameMap } from '../../helpers/utils';
import paths from '../../paths';

export const STATUS = {
  PENDING_APPROVAL: 'pending_approval',
  PENDING_PAYMENT: 'pending_payment',
  EXECUTED: 'executed',
  CANCELLED: 'cancelled',
};

export const EXERCISE_STATUS_MAP = {
  [STATUS.PENDING_APPROVAL]: 'Pending Approval',
  [STATUS.PENDING_PAYMENT]: 'Pending Payment',
  [STATUS.EXECUTED]: 'Completed',
  [STATUS.CANCELLED]: 'Cancelled',
};

export const AMENDMENT_STATUS = {
  AMENDMENT_COMPLETE: 'amendment_complete',
};

export const AMENDMENT_STATUS_MAP = {
  [AMENDMENT_STATUS.AMENDMENT_COMPLETE]: 'Completed',
};
export const TRANSACTION_TYPES = {
  OPTION_EXERCISE: 'option_exercise',
  ORIGINAL_ISSUANCE: 'original_issuance',

  SHARE_REPURCHASED: 'share_repurchase',
  BALANCE_TRANSFER: 'balance_transfer',
  TRANSFER: 'transfer',
  CONVERSION: 'conversion_event',
  AMENDMENT: 'amendment_event',
  STOCK_SPLIT: 'stock_split',
  OTHER_CANCELLATION_EVENT: 'other_cancellation_event',
};

export const TRANSACTION_TYPE_OPTIONS = [
  {
    key: TRANSACTION_TYPES.OPTION_EXERCISE,
    label: 'Option Exercise',
    value: TRANSACTION_TYPES.OPTION_EXERCISE,
  },
  {
    key: TRANSACTION_TYPES.ORIGINAL_ISSUANCE,
    label: 'Original Issuance',
    value: TRANSACTION_TYPES.ORIGINAL_ISSUANCE,
  },
  {
    key: TRANSACTION_TYPES.SHARE_REPURCHASED,
    label: 'Repurchase',
    value: TRANSACTION_TYPES.SHARE_REPURCHASED,
  },
  {
    key: TRANSACTION_TYPES.BALANCE_TRANSFER,
    label: 'Balance Transfer',
    value: TRANSACTION_TYPES.BALANCE_TRANSFER,
  },
  {
    key: TRANSACTION_TYPES.TRANSFER,
    label: 'Transfer',
    value: TRANSACTION_TYPES.TRANSFER,
  },
  {
    key: TRANSACTION_TYPES.CONVERSION,
    label: 'Conversion',
    value: TRANSACTION_TYPES.CONVERSION,
  },
  {
    key: TRANSACTION_TYPES.AMENDMENT,
    label: 'Amendment',
    value: TRANSACTION_TYPES.AMENDMENT,
  },
  {
    key: TRANSACTION_TYPES.STOCK_SPLIT,
    label: 'Stock Split',
    value: TRANSACTION_TYPES.STOCK_SPLIT,
  },
  {
    key: TRANSACTION_TYPES.OTHER_CANCELLATION_EVENT,
    label: 'Other cancellation',
    value: TRANSACTION_TYPES.OTHER_CANCELLATION_EVENT,
  },
];

export const TRANSACTION_TYPE_NAME_MAP = getOptionsNameMap(
  TRANSACTION_TYPE_OPTIONS,
);

export const getTransactionTypeValue = (transactionType: string) =>
  TRANSACTION_TYPE_NAME_MAP[transactionType];

export const transactionsBreadcrumbs = [
  {
    title: 'Transactions',
    link: paths.equity.transactions,
  },
];
