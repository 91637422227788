import { BRANDING } from '../../context/BrandingContext';
import { getOptionsNameMap } from '../../helpers/utils';

export const STATUS = {
  DRAFT: 'draft',
  PENDING_EMPLOYEE_SIGNATURE: 'pending_employee_signature',
  PENDING_COMPANY_SIGNATURE: 'pending_company_signature',
  PENDING_SPOUSE_SIGNATURE: 'pending_spouse_signature',
  AWAITING_BOARD_CONSENT: 'awaiting_board_consent',
  BOARD_APPROVED: 'board_approved',
  CANCELLED: 'cancelled',
  REPURCHASED: 'repurchased',
  ACTIVE: 'active',
  CONVERTED: 'converted',
  GRANTED: 'granted',
  FULLY_EXERCISED: 'fully_exercised',
  OUTSTANDING: 'outstanding',
  PENDING_ISSUANCE: 'pending_issuance',
  FORFEITED: 'forfeited',
  PARTIALLY_EXERCISED: 'partially_exercised',
  EXPIRED: 'expired',
};

export const STATUS_SORT_ORDER = {
  [STATUS.DRAFT]: 0,
  [STATUS.PENDING_ISSUANCE]: 1,
  [STATUS.OUTSTANDING]: 2,
  [STATUS.CONVERTED]: 3,
  [STATUS.CANCELLED]: 4,
  [STATUS.FORFEITED]: 5,
  [STATUS.REPURCHASED]: 6,
  [STATUS.FULLY_EXERCISED]: 7,
  [STATUS.PARTIALLY_EXERCISED]: 8,
};

export const STATUS_OPTIONS = [
  {
    key: STATUS.DRAFT,
    label: 'Draft',
    value: STATUS.DRAFT,
  },
  {
    key: STATUS.PENDING_ISSUANCE,
    label: 'Pending Issuance',
    value: STATUS.PENDING_ISSUANCE,
  },
  {
    key: STATUS.OUTSTANDING,
    label: 'Outstanding',
    value: STATUS.OUTSTANDING,
  },
  {
    key: STATUS.CONVERTED,
    label: 'Converted',
    value: STATUS.CONVERTED,
  },
  {
    key: STATUS.CANCELLED,
    label: 'Cancelled',
    value: STATUS.CANCELLED,
  },
  {
    key: STATUS.FORFEITED,
    label: 'Forfeited',
    value: STATUS.FORFEITED,
  },
  {
    key: STATUS.REPURCHASED,
    label: 'Repurchased',
    value: STATUS.REPURCHASED,
  },
  {
    key: STATUS.FULLY_EXERCISED,
    label: 'Fully Exercised',
    value: STATUS.FULLY_EXERCISED,
  },
  {
    key: STATUS.PARTIALLY_EXERCISED,
    label: 'Partially Exercised',
    value: STATUS.PARTIALLY_EXERCISED,
  },
];

export const STATUS_FILTER_MAP: any = {
  drafts: [
    STATUS.AWAITING_BOARD_CONSENT,
    STATUS.BOARD_APPROVED,
    STATUS.DRAFT,
    STATUS.PENDING_EMPLOYEE_SIGNATURE,
    STATUS.PENDING_COMPANY_SIGNATURE,
    STATUS.PENDING_SPOUSE_SIGNATURE,
    STATUS.PENDING_ISSUANCE,
  ],
  issued: [
    STATUS.GRANTED,
    STATUS.OUTSTANDING,
    STATUS.CANCELLED,
    STATUS.CONVERTED,
    STATUS.REPURCHASED,
    STATUS.FULLY_EXERCISED,
    STATUS.FORFEITED,
  ],
};

export const ISSUED_STATUS_OPTIONS = STATUS_OPTIONS.filter(
  (option) => !STATUS_FILTER_MAP.drafts.includes(option.key),
);

export const DRAFT_STATUS_OPTIONS = STATUS_OPTIONS.filter((option) =>
  STATUS_FILTER_MAP.drafts.includes(option.key),
);

export const NON_EXERCISABLE_STATUSES = [
  STATUS.CANCELLED,
  STATUS.FULLY_EXERCISED,
  STATUS.EXPIRED,
  STATUS.PENDING_ISSUANCE,
];

export const FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: 'drafts',
    value: 'drafts',
    label: 'Drafts',
  },
  {
    key: 'issued',
    value: 'issued',
    label: 'Issued',
  },
];

export const GRANT_TYPES = {
  ESOP_GRANT: 'esop_grant',
  RSA_GRANT: 'rsa_grant',
};

export const ESOP_GRANT_SUB_TYPES = {
  ESOP_GRANT_ISO: 'ISO',
  ESOP_GRANT_NSO: 'NSO',
  ESOP_GRANT_RSU: 'RSU',
  ESOP_GRANT_INTL: 'INTL',
  ESOP_GRANT_EMI: 'EMI',
};

export const ESOP_GRANT_TYPES = [
  GRANT_TYPES.ESOP_GRANT,
  ESOP_GRANT_SUB_TYPES.ESOP_GRANT_ISO,
  ESOP_GRANT_SUB_TYPES.ESOP_GRANT_NSO,
  ESOP_GRANT_SUB_TYPES.ESOP_GRANT_RSU,
  ESOP_GRANT_SUB_TYPES.ESOP_GRANT_INTL,
  ESOP_GRANT_SUB_TYPES.ESOP_GRANT_EMI,
];

export const ALL_GRANT_TYPE_OPTIONS = [
  {
    key: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_ISO,
    label: 'Option (ISO)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_ISO,
  },
  {
    key: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_NSO,
    label: 'Option (NSO)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_NSO,
  },
  {
    key: GRANT_TYPES.ESOP_GRANT,
    label: 'Option',
    value: GRANT_TYPES.ESOP_GRANT,
  },
  {
    key: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_RSU,
    label: 'RSU',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_RSU,
  },
  {
    key: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_INTL,
    label: 'Option (INTL)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_INTL,
  },
  {
    key: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_EMI,
    label: 'Option (EMI)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_EMI,
  },
];

export const NON_RSU_GRANT_TYPES = ALL_GRANT_TYPE_OPTIONS.filter(
  (option) => option.label !== 'RSU',
);

export const ALL_GRANT_TYPE_NAME_MAP = getOptionsNameMap(
  ALL_GRANT_TYPE_OPTIONS,
);

export const DELETE_GRANT_FAQ = [
  {
    question: 'Why I am not able to delete the executed grants?',
    answer: `A grant moves to executed state when the E-Sign for grant letter is completed by both parties(company and employee). Deleting the grant would mean to nullify the existing agreement and ${BRANDING.name} do not have the power to do so without approval from the company admins.`,
  },
  {
    question: 'What should I do to delete an executed grant?',
    answer: `Please contact ${BRANDING.name} at ${BRANDING.supportEmail} with details of executed grants you want to delete.`,
  },
  {
    question: 'What will happen when I delete grant',
    answer:
      'It will immediately and permanently delete all grant documents and data associated with this grant.',
  },
];

export const SEND_EMPLOYEE_EMAIL_INVITE_FAQ = [
  {
    question: 'Why I cannot send employee invite to the executed grants?',
    answer: `Employee do not need to re-sign the grant letter for executed grants. Instead use "send employee invite without E-sign" feature to invite the employee to ${BRANDING.name} or send a welcome invite email from Employee details page.`,
  },
  {
    question:
      'Why do I need to generate a grant letter before sending invite email?',
    answer: `The grant letter acts as a agreement between company and the employee and is mandatory for freshly issued grants to complete the process before inviting employees to sign the letter and use ${BRANDING.name}.`,
  },
  {
    question: 'Why do I need to configure grant letter for E-sign?',
    answer: `${BRANDING.name} uses SignEasy to digital sign a grant letter. Grant letter needs to be configured for E-sign before the invite email is sent to the employee.`,
  },
  {
    question: `How do I invite to employee to ${BRANDING.name} without sending an grant invite email?`,
    answer:
      'You can send a employee invite email from the employee details page.',
  },
];

export const NEW_GRANT_TYPE_OPTIONS = [
  {
    label: 'Option (ISO)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_ISO,
  },
  {
    label: 'Option (NSO)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_NSO,
  },
  {
    label: 'Option (EMI)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_EMI,
  },
  {
    label: 'Option (INTL)',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_INTL,
  },
  {
    label: 'RSA',
    value: GRANT_TYPES.RSA_GRANT,
  },
  {
    label: 'RSU',
    value: ESOP_GRANT_SUB_TYPES.ESOP_GRANT_RSU,
  },
];
