import { gql } from '@apollo/client';
import OUTGOING_STOCK_SPLIT_FRAGMENT from '../fragments/OutgoingStockSplitEventFragment';

const ALL_VALUATIONS_QUERY = gql`
  query allValuationsQuery($companyId: ID!) {
    allValuations(companyId: $companyId) {
      id
      name
      valuationDate
      valuerName
      fmvType
      numberOfOutstandingShares
      notes
      status
      shareClass {
        id
        name
      }
      valuePerShare {
        dollars
        fractional
        currency
        betterFormat
      }
      totalValueOfCompany {
        dollars
        fractional
        currency
        betterFormat
      }
      createdAt
      outgoingStockSplitEvents {
        ...OutgoingStockSplitEventFragment
      }
    }
  }
  ${OUTGOING_STOCK_SPLIT_FRAGMENT}
`;

export default ALL_VALUATIONS_QUERY;
