import { EquityPlan, ShareCertificate, ShareClass } from '../../../../graphql';
import { ColumnType, CELL_PROPS, COLUMN_TYPES } from '../../common/StackTable';
import useUpdateRsaGrant from '../../rsa_grant/hooks/useUpdateRsaGrant';
import { CERTIFICATE_TYPES, STATUS } from '../../share_certificates/constant';
import useUpdateShareCertificate from '../../share_certificates/hooks/useUpdateShareCertificate';
import { formatNumber } from '../../../helpers/utils';

export const isDraft = (shareCertificate: ShareCertificate) => {
  if (shareCertificate.rsaGrant) {
    if (shareCertificate.rsaGrant.groupingGrantStatus === 'draft') {
      return true;
    }
    if (shareCertificate.isPendingReview) {
      return true;
    }
  } else if (shareCertificate.status === STATUS.DRAFT) {
    return true;
  } else if (shareCertificate.isPendingReview) {
    return true;
  }

  return false;
};

export const issuanceMethod = (shareCertificate: ShareCertificate) => {
  if (!shareCertificate.rsaGrant) {
    return 'Offline';
  }
  if (shareCertificate.rsaGrant.grantState === 'issued') {
    return 'Offline';
  }
  return 'AngelList';
};

export const issuanceMethodFormatter = (
  _: string,
  shareCertificate: ShareCertificate,
) => {
  const label = issuanceMethod(shareCertificate);
  if (!label) return null;
  return label;
};

const shareTypeFormatter = (_: string, cert: ShareCertificate) => {
  if (cert.rsaGrant) {
    return CERTIFICATE_TYPES.RSA;
  }
  return CERTIFICATE_TYPES.CERTIFICATE;
};

export const shareCertificateTypeFormatter = (
  _: string,
  shareCertificate: ShareCertificate,
) => {
  if (shareCertificate.rsaGrant) {
    return 'Shares From Equity Plan';
  }
  return 'Share Certificate';
};
export const completionStatusFormatter = (
  _: string,
  shareCertificate: ShareCertificate,
) =>
  shareCertificate.fieldsMissingForPublish.some((x) => x.required)
    ? 'incomplete'
    : 'complete';

export const getColumns = ({
  onUpdateShareCertificate,
  onUpdateRsaGrant,
  onMenuActionClick,
  shareClassOptions,
  equityPlanOptions,
}: {
  onUpdateShareCertificate: ReturnType<
    typeof useUpdateShareCertificate
  >['onUpdate'];
  onUpdateRsaGrant: ReturnType<typeof useUpdateRsaGrant>['onUpdate'];
  onMenuActionClick: (key: string, shareCertificate: ShareCertificate) => void;
  shareClassOptions: Array<ShareClass>;
  equityPlanOptions: Array<EquityPlan>;
}): Array<ColumnType> => [
  {
    name: 'Security name',
    dataField: 'certificateId',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.EDITABLE,
      searchable: true,
      emphasis: true,
      csvFormatter: (certificateId: string) => certificateId || '',
      popoverFormatter: (
        certificateId: string,
        shareCertificate: ShareCertificate,
      ) => ({
        kind: 'input',
        header: {
          title: 'Update share name',
        },
        onApply: (newVal) =>
          onUpdateShareCertificate(shareCertificate.id, {
            certificateId: newVal,
          }),
        inputProps: {
          // description: `Update the name of the convertible`,
          type: 'text',
          inputMode: 'text',
          defaultValue: certificateId ?? undefined,
        },
      }),
    },
  },
  {
    name: 'Stakeholder name',
    dataField: 'shareholder.entityName',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.EDITABLE,
      searchable: true,
      csvFormatter: (entityName: string) => entityName || '',
      popoverFormatter: (_: any, shareCertificate: ShareCertificate) => ({
        kind: 'input',
        header: {
          title: 'Update stakeholder name',
        },
        onApply: (newVal) =>
          onUpdateShareCertificate(shareCertificate.id, {
            inlineStakeholderAttributes: { name: newVal },
          }),
        inputProps: {
          type: 'text',
          inputMode: 'text',
          defaultValue: shareCertificate.shareholder.entityName ?? undefined,
        },
      }),
    },
  },
  {
    name: 'Stakeholder email',
    dataField: 'shareholder.user.email',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.EDITABLE,
      searchable: true,
      // @ts-ignore
      style: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      csvFormatter: (email: string) => email || '',
      formatter: (_: any, shareCertificate: ShareCertificate) =>
        shareCertificate.shareholder.user.email || '—',
      popoverFormatter: (_: any, shareCertificate: ShareCertificate) => ({
        kind: 'input',
        header: {
          title: 'Update stakeholder email',
        },
        onApply: (newVal) =>
          onUpdateShareCertificate(shareCertificate.id, {
            inlineStakeholderAttributes: { email: newVal },
          }),
        inputProps: {
          type: 'text',
          inputMode: 'text',
          defaultValue: shareCertificate.shareholder.user.email ?? undefined,
        },
      }),
    },
  },
  {
    name: 'Type',
    dataField: 'type',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.TEXT,
      searchable: true,
      filterValue: shareTypeFormatter,
      searchValue: shareCertificateTypeFormatter,
      formatter: shareCertificateTypeFormatter,
      csvFormatter: shareCertificateTypeFormatter,
    },
  },
  {
    name: 'Issuance method',
    dataField: 'transactionStatus',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.TEXT,
      csvFormatter: (_: string, share: ShareCertificate) =>
        issuanceMethod(share) || '',
      formatter: issuanceMethodFormatter,
    },
  },
  {
    name: 'Shares',
    dataField: 'numShares',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.EDITABLE,
      searchable: true,
      formatter: (numShares: number) => formatNumber(numShares) || '—',
      csvFormatter: (numShares: string) => numShares || '',
      // @ts-ignore
      popoverFormatter: (
        numShares: number,
        shareCertificate: ShareCertificate,
      ) => ({
        kind: 'input',
        header: {
          title: 'Update number of shares',
        },
        onApply: (newVal) =>
          onUpdateShareCertificate(shareCertificate.id, {
            numShares: parseFloat(newVal),
          }),
        inputProps: {
          type: 'numeric',
          inputMode: 'numeric',
          defaultValue: numShares ?? undefined,
        },
      }),
    },
  },
  {
    // TODO disable for Shares from Equity Plan
    name: 'Share class',
    dataField: 'shareClass.name',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.EDITABLE,
      isDisabled: (shareCertificate: ShareCertificate) =>
        !!shareCertificate.rsaGrant,
      searchable: true,
      // @ts-ignore
      style: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      formatter: (shareClassName: string) => shareClassName || '—',
      csvFormatter: (name: string) => name || '',
      popoverFormatter: (_: any, shareCertificate: ShareCertificate) => ({
        kind: 'selection',
        selectionMode: 'single',
        defaultSelectedKey: shareCertificate.shareClass?.id,
        items: shareClassOptions.map((vt) => ({
          key: vt.id,
          label: vt.name,
        })),
        onChange: (newVal) =>
          onUpdateShareCertificate(shareCertificate.id, {
            shareClassId: newVal,
          }),
      }),
    },
  },
  {
    // TODO disable for Shares not from Equity Plan
    name: 'Equity Plan',
    dataField: 'rsaGrant.equityPlan.name',
    allowsSorting: true,
    cellProps: {
      ...CELL_PROPS.EDITABLE,
      isDisabled: (shareCertificate: ShareCertificate) =>
        !shareCertificate.rsaGrant,
      searchable: true,
      // @ts-ignore
      style: {
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      formatter: (equityPlanName: string) => equityPlanName || '—',
      csvFormatter: (name: string) => name || '',
      popoverFormatter: (_: any, shareCertificate: ShareCertificate) => ({
        kind: 'selection',
        selectionMode: 'single',
        defaultSelectedKey: shareCertificate.rsaGrant?.equityPlan?.id,
        items: equityPlanOptions.map((vt) => ({
          key: vt.id,
          label: vt.name,
        })),
        onChange: (newVal) =>
          onUpdateRsaGrant(shareCertificate.rsaGrant.id, {
            equityPlanId: newVal,
          }),
      }),
    },
  },
  {
    name: 'Completion status',
    dataField: 'completionStatus',
    allowsSorting: true,
    isHidden: true,
    cellProps: {
      ...CELL_PROPS.TEXT,
      searchable: true,
      filterValue: completionStatusFormatter,
      searchValue: completionStatusFormatter,
      formatter: completionStatusFormatter,
    },
  },
  {
    ...COLUMN_TYPES.SORT_ONLY,
    dataField: 'createdAt',
    cellProps: {
      ...CELL_PROPS.DATE,
    },
  },
  {
    ...COLUMN_TYPES.ACTION,
    cellProps: {
      ...CELL_PROPS.OVERFLOW_MENU,
      getMenuSections: () => [
        {
          label: 'View',
          key: 'view',
        },
        {
          label: 'Edit',
          key: 'edit',
        },
        {
          label: 'Delete',
          key: 'delete',
        },
        { label: 'Publish', key: 'publish' },
      ],
      onAction: (key: string, shareCertificate: ShareCertificate) =>
        onMenuActionClick(key, shareCertificate),
      exposedButton: { label: 'Publish', key: 'publish', variant: 'secondary' },
    },
  },
];
