import { gql } from '@apollo/client';

const OUTGOING_STOCK_SPLIT_FRAGMENT = gql`
  fragment OutgoingStockSplitEventFragment on StockSplitEvent {
    id
    effectiveDate
    displayRatio
    workflow {
      id
      ownerId
    }
    instrumentTo {
      ... on Valuation {
        id
        valuePerShare {
          dollars
          fractional
          currency
          betterFormat
        }
      }
    }
  }
`;

export default OUTGOING_STOCK_SPLIT_FRAGMENT;
