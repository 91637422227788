import { gql } from '@apollo/client';

const EXERCISE_STATS_QUERY = gql`
  query grantQuery($id: ID!, $asOf: Date) {
    esopGrant(id: $id) {
      id
      optionsVested(asOf: $asOf)
      optionsUnvested(asOf: $asOf)
    }
  }
`;

export default EXERCISE_STATS_QUERY;
