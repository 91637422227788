import { ObjectType } from '../../../types';
import { getOptionsNameMap } from '../../helpers/utils';

export const INVITE_STATUSES = {
  MISSING_EMAIL: 'missingEmail',
  NOT_INVITED: 'notInvited',
  INVITED: 'invited',
  ACTIVE: 'active',
};

export const BULK_INVITE_FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: 'active',
    value: true,
    label: 'Active',
  },
  {
    key: 'inactive',
    value: false,
    label: 'Inactive',
  },
];

export const INVITE_STATUS_OPTIONS = [
  {
    key: INVITE_STATUSES.MISSING_EMAIL,
    label: 'Missing Email',
    value: INVITE_STATUSES.MISSING_EMAIL,
  },
  {
    key: INVITE_STATUSES.NOT_INVITED,
    label: 'Not Invited',
    value: INVITE_STATUSES.NOT_INVITED,
  },
  {
    key: INVITE_STATUSES.INVITED,
    label: 'Invited',
    value: INVITE_STATUSES.INVITED,
  },
  {
    key: INVITE_STATUSES.ACTIVE,
    label: 'Active',
    value: INVITE_STATUSES.ACTIVE,
  },
];

export const STAKEHOLDER_TYPES = {
  OPTION_HOLDER: 'option_holder',
  SHAREHOLDER: 'shareholder',
};

export const FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    label: 'Shareholder',
    key: STAKEHOLDER_TYPES.SHAREHOLDER,
    value: STAKEHOLDER_TYPES.SHAREHOLDER,
  },
  {
    label: 'Option Holder',
    key: STAKEHOLDER_TYPES.OPTION_HOLDER,
    value: STAKEHOLDER_TYPES.OPTION_HOLDER,
  },
];

export const STAKEHOLDER_EMPLOYEE_TYPE_MAP: ObjectType = {
  full_time: 'FTE',
  part_time: 'PTE',
  contractor: 'Contractor',
  advisor: 'Advisor',
  other_service_provider: 'Other service provider',
};

export const STAKEHOLDER_ENTITY_TYPE_OPTIONS = [
  {
    label: 'Individual',
    value: 'individual',
  },
  {
    label: 'Entity',
    value: 'institution',
  },
];

export const STAKEHOLDER_ENTITY_TYPE_MAP = getOptionsNameMap(
  STAKEHOLDER_ENTITY_TYPE_OPTIONS,
);

export const STAKEHOLDER_RELATIONSHIP_OPTIONS = [
  {
    value: 'investor',
    label: 'Investor',
  },
  {
    value: 'founder',
    label: 'Founder',
  },
  {
    value: 'officer',
    label: 'Exec/Officer',
  },
  {
    value: 'full_time',
    label: 'FTE',
  },
  {
    value: 'employee',
    label: 'Employee',
  },
  {
    value: 'part_time',
    label: 'PTE',
  },
  {
    value: 'contractor',
    label: 'Contractor',
  },
  {
    value: 'advisor',
    label: 'Advisor',
  },
  {
    value: 'board_member',
    label: 'Board member',
  },
  {
    value: 'other_service_provider',
    label: 'Other Service Provider',
  },
];

export const EDIT_STAKEHOLDER_RELATIONSHIP_OPTIONS = [
  {
    value: 'investor',
    label: 'Investor',
  },
  {
    value: 'founder',
    label: 'Founder',
  },
  {
    value: 'officer',
    label: 'Exec/Officer',
  },
  {
    value: 'employee',
    label: 'Employee',
  },
  {
    value: 'advisor',
    label: 'Advisor',
  },
  {
    value: 'board_member',
    label: 'Board member',
  },
  {
    value: 'other_service_provider',
    label: 'Other Service Provider',
  },
];

export const EMPLOYEE_TYPES = {
  FULL_TIME: 'full_time',
  PART_TIME: 'part_time',
  CONTRACTOR: 'contractor',
  ADVISOR: 'advisor',
  OTHER_SERVICE_PROVIDER: 'other_service_provider',
  OTHER: 'other',
};

export const EMPLOYEE_TYPE_OPTIONS = [
  {
    value: EMPLOYEE_TYPES.FULL_TIME,
    label: 'Full time employee',
  },
  {
    value: EMPLOYEE_TYPES.PART_TIME,
    label: 'Part time employee',
  },
  {
    value: EMPLOYEE_TYPES.CONTRACTOR,
    label: 'Contractor',
  },
  {
    value: EMPLOYEE_TYPES.ADVISOR,
    label: 'Advisor',
  },
  {
    value: EMPLOYEE_TYPES.OTHER_SERVICE_PROVIDER,
    label: 'Other service provider',
  },
  {
    value: EMPLOYEE_TYPES.OTHER,
    label: 'Other',
  },
];

export const EMPLOYEE_TYPE_NAME_MAP = getOptionsNameMap(EMPLOYEE_TYPE_OPTIONS);

export const NEW_STAKEHOLDER_RELATIONSHIP_OPTIONS = [
  {
    value: 'founder',
    label: 'Founder',
  },
  {
    value: 'board_member',
    label: 'Board member',
  },
  {
    value: 'investor',
    label: 'Investor',
  },
  {
    value: 'advisor',
    label: 'Advisor',
  },
  {
    value: 'full_time',
    label: 'FTE',
  },
  {
    value: 'part_time',
    label: 'PTE',
  },
  {
    value: 'contractor',
    label: 'Contractor',
  },
  {
    value: 'other',
    label: 'Other',
  },
];

export const RELATIONSHIP_MAP = getOptionsNameMap(
  NEW_STAKEHOLDER_RELATIONSHIP_OPTIONS,
);

export const COST_CENTERS = [
  'R&D',
  'Operations',
  'Sales & Marketing',
  'Executives & Officers',
  'Support',
  'Other',
];

export const JOB_TITLES = [
  'Account Executive',
  'Accountant',
  'Administrator',
  'Assistant Manager',
  'Chief Executive Officer',
  'Chief Financial Officer',
  'Chief Operating Officer',
  'Chief Technology Officer',
  'Designer',
  'Director',
  'Executive',
  'Individual Contributor',
  'IT Professional',
  'Manager',
  'Operations',
  'Other',
  'President',
  'Product Manager',
  'Software Engineer',
  'Team Leader',
  'Vice President',
];

export const DEFAULT_ADDRESS_VALUES = {
  street: '',
  city: '',
  state: '',
  country: 'US',
  postalCode: '',
};

export const ADMIN_VISIBILITY_POLICY_OPTIONS = [
  {
    label: 'Everyone',
    value: '',
    description: 'Everyone can view the stakeholder holdings.',
  },
  {
    label: 'Hidden from limited drafter',
    value: 'hidden_limited',
    description: 'Limited drafter cannot view the stakeholder holdings.',
  },
];
