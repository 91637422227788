import React from 'react';
import {
  EquityTransaction,
  HealthCheck,
  RsaGrant,
  Security,
  SecurityInvite,
  ShareCertificate,
} from '../../../graphql';
import { NameColumn, statusFormatter } from '../../helpers/formatters';
import {
  AllShareCertificateDraftsBreadcrumbs,
  ShareCertificateBreadcrumbs,
  ShareCertificateSecurityBreadcrumbs,
  STATUS,
} from './constant';
import { formatNumber, formatTitle } from '../../helpers/utils';
import StatusTag, { STATUS_TAG_INTENT_MAPPING } from '../common/StatusTag';
import UnitsColumn from '../common/UnitsColumn';
import { COLUMN_ATTRIBUTES } from '../../utils/column';
import {
  IdFormatter,
  SecurityNameFormatter,
} from '../equity_transactions/utils';
import { ISSUANCE_METHODS } from '../constants';
import paths from '../../paths';
import { isDraft } from '../security_drafts/share_certificates/utils';
import { getTransactionTypeValue } from '../equity_transactions/constant';

export const shareCertificateBreadcrumbs = (
  isDraftShareCertificate: boolean,
  securityDraftsEnabled: boolean,
) => {
  if (isDraftShareCertificate) {
    return [
      {
        title: 'Drafts',
        link: paths.equity.drafts.all,
      },
      ...AllShareCertificateDraftsBreadcrumbs,
    ];
  }
  if (securityDraftsEnabled) {
    return ShareCertificateSecurityBreadcrumbs;
  }
  return ShareCertificateBreadcrumbs;
};

export const isDraftCert = (shareCert: ShareCertificate) =>
  (shareCert.rsaGrant && !shareCert.rsaGrant.filledOut) ||
  (!shareCert.rsaGrant && shareCert.state === STATUS.DRAFT);

export const certificateIdFormatter = (
  certificateId: string,
  shareCert: ShareCertificate,
  draftCheckFn: (shareCert: ShareCertificate) => boolean = isDraftCert,
) => {
  const healthChecks =
    shareCert.healthChecks?.reduce(
      (acc: { [key: string]: HealthCheck }, curr) => {
        if (!curr.source) acc[curr.title] = curr;
        if (curr.source && curr.status === 'active') acc[curr.title] = curr;
        return acc;
      },
      {},
    ) || {};

  const isADraft = draftCheckFn(shareCert);

  return (
    <NameColumn
      name={certificateId}
      isDraft={isADraft}
      healthChecks={Object.values(healthChecks)}
    />
  );
};

export const pendingIssuanceItemsCSVFormatter = (
  _: boolean,
  shareCertificate: ShareCertificate,
) => {
  if (shareCertificate.awardPending) {
    return 'Award Pending';
  }
  if (shareCertificate.certificatePending) {
    return 'Certificate Pending';
  }
  if (shareCertificate.noticePending) {
    return 'Notice Pending';
  }
  return '';
};

export const pendingIssuanceItemsFormatter = (
  _: boolean,
  shareCertificate: ShareCertificate,
) => {
  if (shareCertificate.awardPending) {
    return <StatusTag label="Award Pending" intent="warning" />;
  }
  if (shareCertificate.certificatePending) {
    return <StatusTag label="Certificate Pending" intent="neutral" />;
  }
  if (shareCertificate.noticePending) {
    return <StatusTag label="Notice Pending" intent="neutral" />;
  }
  return null;
};

export const getInviteStatus = (
  state: string,
  securityInvite: SecurityInvite,
) => {
  const issuanceMethods = [
    ISSUANCE_METHODS.BOOK_ENTRY_NO_EMAIL,
    ISSUANCE_METHODS.INVITE,
  ];
  if (
    [
      STATUS.PARTIAL_TRANSFER,
      STATUS.TRANSFERRED,
      STATUS.FULL_TRANSFER,
    ].includes(state) ||
    !securityInvite ||
    issuanceMethods.includes(securityInvite?.issuanceMethod) ||
    securityInvite.status === 'completed' ||
    state === 'draft'
  ) {
    const status = state || 'active';
    return { transactionStatus: status, status, title: formatTitle(status) };
  }

  const status = state || 'active';
  return {
    transactionStatus: STATUS.PENDING_ACCEPTANCE,
    status,
    intent: 'warning' as 'warining',
    title: `${formatTitle(status)} (Pending Acceptance)`,
  };
};

export const rsaStatusFormatter = (
  cert: ShareCertificate,
  grant: RsaGrant,
  securityInvite: SecurityInvite,
) => {
  let intent;
  let status;

  if (cert?.state === 'draft' && !grant?.grantIssuance?.completedAt) {
    intent = 'draft' as 'draft';
    status = 'Draft (Pending Execution)';
  } else if (securityInvite?.status !== 'completed') {
    const formattedTitle = formatTitle(cert?.state || 'active');
    status = `${formattedTitle || 'Active'} (Pending Issuance)`;
    intent = STATUS_TAG_INTENT_MAPPING[cert?.state || 'active'];
  } else {
    status = formatTitle(cert?.state || 'active');
    intent = STATUS_TAG_INTENT_MAPPING[cert?.state || 'active'];
  }

  return {
    status,
    intent,
  };
};

export const outstandingStatusFormatter = (outstandingStatus: string) =>
  statusFormatter(
    outstandingStatus,
    STATUS_TAG_INTENT_MAPPING[outstandingStatus],
  );

export const outstandingCsvStatusFormatter = (outstandingStatus: string) =>
  formatTitle(outstandingStatus || '—');

export const inviteStatusFormatter = (
  state: string,
  cert: ShareCertificate,
) => {
  const { securityInvite, rsaGrant } = cert;
  if (rsaGrant) {
    const { status, intent } = rsaStatusFormatter(
      cert,
      rsaGrant,
      securityInvite,
    );

    return statusFormatter(status, intent, status);
  }

  const { status, title, intent } = getInviteStatus(state, securityInvite);
  return statusFormatter(status, intent, title);
};

export const isVestingCertificate = (shareCertificate: ShareCertificate) =>
  !!shareCertificate.vestingTemplate?.id;

export const formatContributionAttributes = (formData: any): any => {
  const {
    contributionAttributes,
    hasCapitalContribution,
    hasValueOfDebtCancelled,
    hasValueOfIpContribution,
    hasValueOfOtherConsideration,
    hasConversionAmount,
  } = formData;

  return {
    capitalContributionCents: hasCapitalContribution
      ? contributionAttributes?.capitalContribution?.fractional
      : null,
    capitalContributionCurrency: hasCapitalContribution
      ? contributionAttributes?.capitalContribution?.currency
      : null,
    valueOfIpContributionCents: hasValueOfIpContribution
      ? contributionAttributes?.valueOfIpContribution?.fractional
      : null,
    valueOfIpContributionCurrency: hasValueOfIpContribution
      ? contributionAttributes?.valueOfIpContribution?.currency
      : null,
    valueOfDebtCancelledCents: hasValueOfDebtCancelled
      ? contributionAttributes?.valueOfDebtCancelled?.fractional
      : null,
    valueOfDebtCancelledCurrency: hasValueOfDebtCancelled
      ? contributionAttributes?.valueOfDebtCancelled?.currency
      : null,
    valueOfOtherConsiderationCents: hasValueOfOtherConsideration
      ? contributionAttributes?.valueOfOtherConsideration?.fractional
      : null,
    valueOfOtherConsiderationCurrency: hasValueOfOtherConsideration
      ? contributionAttributes?.valueOfOtherConsideration?.currency
      : null,
    conversionAmountCents: hasConversionAmount
      ? contributionAttributes?.conversionAmount?.fractional
      : null,
    conversionAmountCurrency: hasConversionAmount
      ? contributionAttributes?.conversionAmount?.currency
      : null,
  };
};

const formatVestingAttributes = (formData: any) => ({
  ...formData.vestingAttributes,
  vestingTemplateId: formData.hasVestingSchedule
    ? formData.vestingAttributes.vestingTemplateId
    : null,
  accelerationTemplateId: formData.hasAcceleration
    ? formData.vestingAttributes.accelerationTemplateId
    : null,
  companyLegendIds: formData.hasCompanyLegends
    ? formData.vestingAttributes.companyLegendIds
    : [],
  vestingStartBackdate: formData.hasVestingStartBackdate
    ? formData.vestingAttributes.vestingStartBackdate
    : null,
});

export const formatShareAttributes = (formData: any) => {
  const {
    pricePerShare,
    numShares,
    ...restCertificateDetailAttributes
  } = formData.certificateDetailAttributes;

  return {
    ...restCertificateDetailAttributes,
    ...formData.securitiesComplianceAttributes,
    ...formData.boardConsentAttributes,
    ...formatVestingAttributes(formData),
    ...formatContributionAttributes(formData),
    ...formData.documentsAndNotesAttributes,
    ...formData.issuanceMethodAttributes,
    ...formData.spaMethodAttributes,
    numShares: Number(numShares),
    pricePerShareCents: pricePerShare?.fractional,
    pricePerShareCurrency: pricePerShare?.currency,
    spaDocument:
      // eslint-disable-next-line no-underscore-dangle
      formData.spaMethodAttributes?.spaDocument?.__typename === 'Document'
        ? undefined
        : formData.spaMethodAttributes?.spaDocument,
  };
};

export const isCertificateTransferred = (state: string) =>
  [STATUS.PARTIAL_TRANSFER, STATUS.FULL_TRANSFER, STATUS.TRANSFERRED].includes(
    state,
  );

export const unitsColumnFormatter = (
  _: never,
  shareCertificate: ShareCertificate,
) => {
  const { unitsVested, numShares, state } = shareCertificate;
  if (isVestingCertificate(shareCertificate) && numShares) {
    return (
      <UnitsColumn
        numerator={unitsVested}
        denominator={numShares}
        hideProgress={state === STATUS.DRAFT}
      />
    );
  }
  return formatNumber(numShares);
};

export const unitsCsvFormatter = (
  _: never,
  shareCertificate: ShareCertificate,
) => {
  const { unitsVested, numShares } = shareCertificate;
  if (isVestingCertificate(shareCertificate) && numShares) {
    return `${formatNumber(unitsVested)}/${formatNumber(numShares)}`;
  }
  return formatNumber(numShares);
};

export const unitsSearchValue = (
  _: never,
  shareCertificate: ShareCertificate,
) => {
  const { unitsVested, numShares } = shareCertificate;
  if (isVestingCertificate(shareCertificate) && numShares) {
    return `${unitsVested}_${numShares}_${formatNumber(
      unitsVested,
    )}_${formatNumber(numShares)}`;
  }

  return numShares;
};

export const TRANSACTION_COLUMNS = [
  {
    ...COLUMN_ATTRIBUTES.DATE,
    dataField: 'transactionDate',
    text: 'Date',
    formatter: (_: string, equityTransaction: EquityTransaction) => (
      <IdFormatter equityTransaction={equityTransaction} />
    ),
  },
  {
    dataField: 'transactionType',
    text: 'Type',
    formatter: getTransactionTypeValue,
    searchValue: getTransactionTypeValue,
    sort: true,
    headerStyle: { minWidth: 120 },
  },
  {
    ...COLUMN_ATTRIBUTES.NUMBER,
    dataField: 'numUnits',
    text: 'Quantity',
    formatter: formatNumber,
  },
  {
    dataField: 'origin',
    text: 'Origin',
    formatter: (security: Security) => (
      <SecurityNameFormatter security={security} />
    ),
  },
  {
    dataField: 'destination',
    text: 'Destination',
    formatter: (security: Security) => (
      <SecurityNameFormatter security={security} />
    ),
  },
];

export const getShareCertificatePath = (
  shareCertificate: ShareCertificate,
  tab?: string,
  securityDraftsEnabled: boolean = false,
) => {
  if (!shareCertificate) return null;
  if (securityDraftsEnabled) {
    if (isDraft(shareCertificate)) {
      return paths.equity.drafts.shares.show(shareCertificate.id, tab);
    }
    return paths.equity.securities.shares.show(shareCertificate.id, tab);
  }
  return paths.equity.shareCertificate(shareCertificate.id, tab);
};
