import React from 'react';
import {
  Convertible,
  ConvertibleDebt,
  ConvertibleSafe,
  HealthCheck,
  Saft,
  TokenWarrant,
  WarrantCertificate,
} from '../../../graphql';
import {
  CAPITAL_CONTRIBUTION_FIELD_FOR_SECURITY,
  CONVERTIBLE_DEBT_CONVERTIBLE_TYPE_NAME_MAP,
  CONVERTIBLE_TYPE_NAME_MAP,
  STATUS,
} from './constant';
import { NameColumn } from '../../helpers/formatters';
import { formatDate, formatMoneyObject } from '../../helpers/utils';
import paths from '../../paths';
import { BreadcrumbItem } from '../../../types';

const aiHealthChecks = (convertible: Convertible) => {
  if (convertible.__typename === 'ConvertibleSafe') {
    return convertible.aiHealthChecks?.filter(
      (hc) => !hc.source || hc.status === 'active',
    );
  }

  return [];
};

export const getConvertibleName = (convertible: Convertible) => {
  if (convertible.__typename === 'ConvertibleSafe') {
    return convertible.name;
  }
  if (convertible.__typename === 'ConvertibleDebt') {
    return convertible.certificateId;
  }
  if (convertible.__typename === 'WarrantCertificate') {
    return convertible.name;
  }
  if (convertible.__typename === 'Saft') {
    return convertible.name;
  }
  if (convertible.__typename === 'TokenWarrant') {
    return convertible.name;
  }

  return null;
};

export const nameFormatter = (_: string, convertible: Convertible) => {
  let convertibleName = getConvertibleName(convertible);
  if (!convertibleName) {
    convertibleName = '—';
  }

  const healthChecks =
    aiHealthChecks(convertible)?.reduce(
      (acc: { [key: string]: HealthCheck }, curr) => {
        if (!curr.source) acc[curr.title] = curr;
        if (curr.source && curr.status === 'active') acc[curr.title] = curr;
        return acc;
      },
      {},
    ) || {};

  return (
    <NameColumn
      name={convertibleName}
      isDraft={convertible.status === STATUS.DRAFT}
      healthChecks={Object.values(healthChecks)}
    />
  );
};

export const issueDate = (convertible: Convertible) => {
  if (convertible.__typename === 'ConvertibleSafe') {
    return convertible.issueDate;
  }
  if (convertible.__typename === 'ConvertibleDebt') {
    return convertible.issueDate;
  }
  if (convertible.__typename === 'WarrantCertificate') {
    return convertible.grantDate;
  }
  if (convertible.__typename === 'Saft') {
    return convertible.issueDate;
  }
  if (convertible.__typename === 'TokenWarrant') {
    return convertible.issueDate;
  }

  return null;
};

export const issueDateFormatter = (
  _: string,
  convertible: Convertible,
  emptyString = '—',
) => formatDate(issueDate(convertible), emptyString);

export const capitalContribution = (convertible: Convertible) => {
  const field = CAPITAL_CONTRIBUTION_FIELD_FOR_SECURITY[convertible.__typename];
  if (field) {
    // @ts-ignore
    return convertible[field];
  }
  return null;
};

export const capitalContributionFormatter = (
  _: string,
  convertible: Convertible,
) => formatMoneyObject(capitalContribution(convertible));

export const capitalContributionSortSearchFormat = (
  _: string,
  convertible: Convertible,
) => {
  const cc = capitalContribution(convertible);
  if (cc) {
    return Number(cc.dollars);
  }
  return 0;
};

export const capitalContributionCsvFormat = (
  _: string,
  convertible: Convertible,
) => {
  const cc = capitalContribution(convertible);
  if (cc) {
    return Number(cc.dollars);
  }
  return '';
};

export const convertibleType = (convertible: Convertible) => {
  if (convertible.__typename === 'ConvertibleDebt') {
    // @ts-ignore
    return CONVERTIBLE_DEBT_CONVERTIBLE_TYPE_NAME_MAP[
      convertible.convertibleType
    ];
  }
  return CONVERTIBLE_TYPE_NAME_MAP[convertible.__typename];
};

export const convertibleTypeFormatter = (_: string, convertible: Convertible) =>
  convertibleType(convertible);

export const getSafePath = (
  convertibleSafe: ConvertibleSafe,
  tab?: string,
  securityDraftsEnabled = false,
) => {
  if (!convertibleSafe) return null;
  if (securityDraftsEnabled) {
    if (convertibleSafe.status === STATUS.DRAFT) {
      return paths.equity.drafts.convertibles.safe(convertibleSafe.id, tab);
    }
    return paths.equity.securities.convertibles.safe(convertibleSafe.id, tab);
  }
  return paths.equity.safe(convertibleSafe.id, tab);
};

export const getDebtPath = (
  convertibleDebt: ConvertibleDebt,
  tab?: string,
  securityDraftsEnabled = false,
) => {
  if (!convertibleDebt) return null;
  if (securityDraftsEnabled) {
    if (convertibleDebt.groupingStatus === STATUS.DRAFT) {
      return paths.equity.drafts.convertibles.debt(convertibleDebt.id, tab);
    }
    return paths.equity.securities.convertibles.debt(convertibleDebt.id, tab);
  }
  return paths.equity.convertibleDebt(convertibleDebt.id, tab);
};

export const getWarrantPath = (
  warrant: WarrantCertificate,
  securityDraftsEnabled = false,
) => {
  if (!warrant) return null;
  if (securityDraftsEnabled) {
    if (warrant.status === STATUS.DRAFT) {
      return paths.equity.drafts.convertibles.warrant(warrant.id);
    }
    return paths.equity.securities.convertibles.warrant(warrant.id);
  }
  return paths.equity.warrant(warrant.id);
};

export const getTokenWarrantPath = (
  tokenWarrant: TokenWarrant,
  securityDraftsEnabled = false,
) => {
  if (!tokenWarrant) return null;
  if (securityDraftsEnabled) {
    if (tokenWarrant.status === STATUS.DRAFT) {
      return paths.equity.drafts.convertibles.tokenWarrant(tokenWarrant.id);
    }
    return paths.equity.securities.convertibles.tokenWarrant(tokenWarrant.id);
  }
  return paths.equity.tokenWarrant(tokenWarrant.id);
};

export const getSaftPath = (saft: Saft, securityDraftsEnabled = false) => {
  if (!saft) return null;
  if (securityDraftsEnabled) {
    if (saft.status === STATUS.DRAFT) {
      return paths.equity.drafts.convertibles.saft(saft.id);
    }
    return paths.equity.securities.convertibles.saft(saft.id);
  }
  return paths.equity.saft(saft.id);
};

export const getConvertibleLink = (
  convertible: Convertible,
  securityDraftsEnabled = false,
) => {
  const { __typename } = convertible;
  if (__typename === 'ConvertibleSafe') {
    return getSafePath(convertible, undefined, securityDraftsEnabled);
  }
  if (__typename === 'ConvertibleDebt') {
    return getDebtPath(convertible, undefined, securityDraftsEnabled);
  }
  if (__typename === 'WarrantCertificate') {
    return getWarrantPath(convertible, securityDraftsEnabled);
  }
  if (__typename === 'Saft') {
    return getSaftPath(convertible, securityDraftsEnabled);
  }
  if (__typename === 'TokenWarrant') {
    return getTokenWarrantPath(convertible, securityDraftsEnabled);
  }
  return null;
};

export const convertibleBreadcrumbs = (
  isDraft?: Boolean,
  securityDraftsEnabled = false,
): Array<BreadcrumbItem> => {
  if (securityDraftsEnabled) {
    if (isDraft) {
      return [
        {
          title: 'Drafts',
          link: paths.equity.drafts.all,
        },
        {
          title: 'Convertibles',
          link: paths.equity.drafts.convertibles.all,
        },
      ];
    }
    return [
      {
        title: 'Securities',
        link: paths.equity.securities.all,
      },
      {
        title: 'Convertibles',
        link: paths.equity.securities.convertibles.all,
      },
    ];
  }
  // REMOVE below once enabled securityDraftsEnabled
  return [
    {
      title: 'Convertibles',
      link: paths.equity.convertibles,
    },
  ];
};
