import { gql } from '@apollo/client';
import SHARE_CERTIFICATE_SUMMARY_FRAGMENT from '../fragments/ShareCertificateSummaryFragment';

const ALL_CERTIFICATES_QUERY = gql`
  query allCertificatesQuery($id: ID!, $rsasOnly: Boolean) {
    allCertificates(companyId: $id, rsasOnly: $rsasOnly) {
      ...ShareCertificateSummaryFragment
    }
  }
  ${SHARE_CERTIFICATE_SUMMARY_FRAGMENT}
`;

export default ALL_CERTIFICATES_QUERY;
