import React from 'react';
import { groupBy } from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip } from '@angellist/adapt';

import UnitsColumn from '../common/UnitsColumn';
import HealthCheckRowActions from './components/HealthCheckRowActions';
import { COLUMN_ATTRIBUTES } from '../../utils/column';
import { LinkColumn } from '../../helpers/formatters';
import {
  HealthCheck,
  EsopGrant,
  Employee,
  AiParseResult,
} from '../../../graphql';

import paths from '../../paths';
import { fromNow } from '../../utils/date';
import { parseLocation } from '../../helpers/locations';
import { CHECK_TYPES, DEGREE } from './constant';
import { isDeletedEmail } from '../../utils';
import { useCompanyContext } from '../../context/CompanyContext';
import { getShareCertificatePath } from '../share_certificates/utils';
import { getSafePath } from '../convertibles/utils';
import { getEsopGrantPath } from '../esop_grants/utils';

export const shareClassFormatter = (_: any, healthCheck: HealthCheck) =>
  healthCheck.equityPlan ? (
    <LinkColumn
      to={paths.equity.shareClass(healthCheck.equityPlan.shareClass?.id)}
      name={healthCheck.equityPlan.shareClass?.name}
    />
  ) : (
    '—'
  );

export const equityPlanNameFormatter = (_: any, healthCheck: HealthCheck) => (
  <LinkColumn
    to={paths.equity.plans.equityPlan(healthCheck.equityPlan?.id)}
    name={healthCheck.equityPlan?.name}
  />
);

export const grantDocumentSetFormatter = (_: any, healthCheck: HealthCheck) => (
  <LinkColumn
    to={paths.equity.grantDocument.grantDocumentSet(
      healthCheck.grantDocumentSet?.id,
    )}
    name={healthCheck.grantDocumentSet?.name}
  />
);

export const numSharesFormatter = (_: never, healthCheck: HealthCheck) => {
  const { numShares, numSharesAvailable } = healthCheck.equityPlan;
  if (numShares) {
    return (
      <UnitsColumn
        numerator={numShares - numSharesAvailable}
        denominator={numShares}
      />
    );
  }
  return null;
};

export const employeeNameFormatter = (
  name: string,
  healthCheck: HealthCheck,
) => (
  <Link
    className="table-link"
    to={paths.equity.stakeholders.show(healthCheck.employee.id)}
  >
    <div className="d-flex align-items-center">
      <h5 className="mb-0 flex-1 ml-2 label-3-table-link">
        {name || healthCheck.employee.id}
      </h5>
    </div>
  </Link>
);

export const stakeholderNameFormatter = (
  _name: string,
  healthCheck: HealthCheck,
) => (
  <Link
    className="table-link"
    to={paths.equity.stakeholders.show(healthCheck.stakeholder.employee.id)}
  >
    <div className="d-flex align-items-center">
      <h5 className="mb-0 flex-1 ml-2 label-3-table-link">
        {healthCheck.stakeholder.name || healthCheck.stakeholder.employee.id}
      </h5>
    </div>
  </Link>
);

export const stakeholderFormatter = (
  _name: string,
  healthCheck: HealthCheck,
) => (
  <Link
    className="table-link"
    to={paths.equity.stakeholders.show(
      healthCheck.shareCertificate.stakeholder.id,
    )}
  >
    <div className="d-flex align-items-center">
      <h5 className="mb-0 flex-1 ml-2 label-3-table-link">
        {healthCheck.shareCertificate.stakeholder.name}
      </h5>
    </div>
  </Link>
);

export const ShareCertificateLinkNameFormatter = ({
  healthCheck,
}: {
  healthCheck: HealthCheck;
}) => {
  const { securityDraftsEnabled } = useCompanyContext();
  return (
    <LinkColumn
      to={getShareCertificatePath(
        healthCheck.shareCertificate,
        undefined,
        securityDraftsEnabled,
      )}
      name={healthCheck.shareCertificate.certificateId}
    />
  );
};

export const shareCertificateShareClassFormatter = (
  _: any,
  healthCheck: HealthCheck,
) =>
  healthCheck.shareCertificate ? (
    <LinkColumn
      to={paths.equity.shareClass(healthCheck.shareCertificate.shareClass?.id)}
      name={healthCheck.shareCertificate.shareClass?.name}
    />
  ) : (
    '—'
  );

export const emailFormatter = (email: string) => {
  if (!email || isDeletedEmail(email)) {
    return '';
  }

  return <a href={`mailto:${email}`}>{email}</a>;
};

export const unitsColumnFormatter = (_: never, grant: EsopGrant) => {
  const { unitsVested, totalUnits } = grant;
  if (totalUnits) {
    return <UnitsColumn numerator={unitsVested} denominator={totalUnits} />;
  }
  return null;
};

export const EsopGrantNameFormatter = (props: { healthCheck: HealthCheck }) => {
  const { healthCheck } = props;
  const { securityDraftsEnabled } = useCompanyContext();

  return (
    <LinkColumn
      to={getEsopGrantPath(
        healthCheck.esopGrant,
        undefined,
        securityDraftsEnabled,
      )}
      name={healthCheck.esopGrant?.name}
    />
  );
};

export const esopExerciseNameFormatter = (_: any, healthCheck: HealthCheck) => (
  <LinkColumn
    to={paths.equity.exercise(healthCheck.esopExercise?.id)}
    name={healthCheck.esopExercise?.esopGrant?.name}
  />
);

export const aiHealthCheckFixPath = (
  aiParseResult: AiParseResult,
  securityDraftsEnabled: boolean,
) => {
  if (aiParseResult.convertibleSafe) {
    return getSafePath(
      aiParseResult.convertibleSafe,
      undefined,
      securityDraftsEnabled,
    );
  }
  if (aiParseResult.esopGrant) {
    return getEsopGrantPath(
      aiParseResult.esopGrant,
      undefined,
      securityDraftsEnabled,
    );
  }
  return null;
};

export const healthCheckFixPath = (
  healthCheck: HealthCheck,
  securityDraftsEnabled: boolean,
) => {
  const { ownerId, ownerType, category, checkType } = healthCheck;
  let path = '';
  if (ownerType === 'EquityPlan') {
    path = paths.equity.plans.equityPlan(ownerId);
  } else if (ownerType === 'Employee') {
    path = paths.equity.stakeholders.show(ownerId);
  } else if (category === 'board_consent') {
    path = paths.legal.boardConsents.all;
  } else if (ownerType === 'EsopGrant') {
    path = getEsopGrantPath(
      { id: ownerId } as EsopGrant,
      undefined,
      securityDraftsEnabled,
    );
  } else if (category === 'valuation') {
    path = paths.equity.valuations.all;
  } else if (ownerType === 'AiParseResult') {
    path = aiHealthCheckFixPath(
      healthCheck.aiParseResult,
      securityDraftsEnabled,
    );
  } else if (ownerType === 'EsopExercise') {
    path = paths.equity.exercise(ownerId);
  } else if (checkType === CHECK_TYPES.COI_MISSING_SHARE_CLASSES) {
    path = paths.equity.shareClasses;
  } else if (ownerType === 'Stakeholder') {
    path = paths.equity.stakeholders.show(healthCheck.stakeholder.employee.id);
  }

  return path;
};

export const processStatusesData = (data: any[]) => {
  if (data.length) {
    const groupHash = groupBy(data, (obj: any) => obj.category);
    return Object.keys(groupHash).map((category: string) => ({
      category,
      items: groupHash[category],
    }));
  }

  return [];
};

export const parsedDataFormatter = (parsedData: any) => {
  const text = parsedData?.text;
  const pageNum = parsedData?.page;

  if (text) {
    return (
      <Tooltip
        variant="underline"
        content={`Found on page ${pageNum} of ${parsedData.filename}`}
      >
        {text}
      </Tooltip>
    );
  }
  return '';
};

export const equityPlanColumns = (refetch: any) => [
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'equityPlan.name',
    text: 'Name',
    formatter: equityPlanNameFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'equityPlan.shareClass',
    text: 'Share Class',
    formatter: shareClassFormatter,
  },
  {
    dataField: 'equityPlan.numShares',
    text: 'Number of shares',
    formatter: numSharesFormatter,
    headerStyle: { minWidth: 140 },
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'displayDescription',
    text: 'Description',
    headerStyle: { minWidth: 180 },
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const grantDocumentSetColumns = (refetch: any) => [
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'grantDocumentSet.name',
    text: 'Name',
    formatter: grantDocumentSetFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'grantDocumentSet.version',
    text: 'Version',
  },
  {
    dataField: 'grantDocumentSet.uploadedByUser.name',
    text: 'Created by',
    headerStyle: { minWidth: 140 },
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'displayDescription',
    text: 'Description',
    headerStyle: { minWidth: 180 },
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const shareCertificateColumns = (refetch: any) => [
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'shareCertificate.certificateId',
    text: 'Name',
    formatter: (_: any, healthCheck: HealthCheck) => (
      <ShareCertificateLinkNameFormatter healthCheck={healthCheck} />
    ),
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'shareCertificate.shareClass.name',
    text: 'Share Class',
    formatter: shareCertificateShareClassFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'shareCertificate.stakeholder.name',
    text: 'Stakeholder',
    formatter: stakeholderFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.DATE,
    dataField: 'shareCertificate.issueDate',
    text: 'Issued On',
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const employeeColumns = (refetch: any) => [
  {
    dataField: 'employee.user.name',
    text: 'Name',
    formatter: employeeNameFormatter,
    sort: true,
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'employee.user.email',
    text: 'Email',
    formatter: emailFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.STATUS_TAG,
    dataField: 'employee.employeeStatus',
    text: 'Status',
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'displayDescription',
    text: 'Description',
    headerStyle: { minWidth: 180 },
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const stakeholderColumns = (refetch: any) => [
  {
    dataField: 'name',
    text: 'Name',
    formatter: stakeholderNameFormatter,
    sort: true,
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'stakeholder.employee.user.email',
    text: 'Email',
    formatter: emailFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.STATUS_TAG,
    dataField: 'stakeholder.employee.employeeStatus',
    text: 'Status',
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'displayDescription',
    text: 'Description',
    headerStyle: { minWidth: 180 },
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const terminateEmployeeColumns = (refetch: any) => [
  {
    dataField: 'employee.user.name',
    text: 'Name',
    formatter: employeeNameFormatter,
    sort: true,
  },
  {
    ...COLUMN_ATTRIBUTES.TITLE,
    dataField: 'employee.level',
    text: 'Level',
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'employee.startDate',
    text: 'Tenure',
    formatter: (startDate: string) => fromNow(startDate, true),
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'employee',
    text: 'Location',
    formatter: (employee: Employee) => {
      const { parts } = parseLocation({
        city: employee.city,
        stateNameOrCode: employee.stateOfResidence,
        countryCode: employee.country,
      });

      if (parts.length === 0) {
        return '—';
      }

      return parts.slice(0, -1).join(', ');
    },
  },
  {
    ...COLUMN_ATTRIBUTES.DATE,
    dataField: 'employee.hrisEndDate',
    text: 'Termination date',
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const optionGrantColumns = (refetch: any) => [
  {
    dataField: 'esopGrant.name',
    text: 'Grant',
    formatter: (_name: string, healthCheck: HealthCheck) => (
      <EsopGrantNameFormatter healthCheck={healthCheck} />
    ),
    headerStyle: { minWidth: 140 },
  },
  {
    dataField: 'esopGrant.grantType',
    text: 'Type',
  },
  {
    ...COLUMN_ATTRIBUTES.STATUS_TAG,
    dataField: 'esopGrant.groupingGrantStatus',
    text: 'Status',
  },
  {
    ...COLUMN_ATTRIBUTES.DATE,
    dataField: 'esopGrant.grantTerms.grantedAt',
    text: 'Issue Date',
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'displayDescription',
    text: 'Description',
    headerStyle: { minWidth: 180 },
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const optionExerciseColumns = (refetch: any) => [
  {
    dataField: 'esopExercise.esopGrant.name',
    text: 'Grant',
    formatter: esopExerciseNameFormatter,
    headerStyle: { minWidth: 140 },
  },
  {
    dataField: 'esopExercise.esopGrant.grantType',
    text: 'Type',
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'esopExercise.numExercise',
    text: 'Quantity',
  },
  {
    ...COLUMN_ATTRIBUTES.BETTER_MONEY,
    dataField: 'esopExercise.pricePaid',
    text: 'Exercise Price',
  },
  {
    ...COLUMN_ATTRIBUTES.DATE,
    dataField: 'esopExercise.exerciseAt',
    text: 'Exercise Initiated On',
  },
  {
    ...COLUMN_ATTRIBUTES.DATE,
    dataField: 'esopExercise.resolvedAt',
    text: 'Exercise Approved On',
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const shareholderColumns = (refetch: any) => [
  {
    dataField: 'shareholder.entityName',
    text: 'Name',
    formatter: employeeNameFormatter,
    sort: true,
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'shareholder.user.email',
    text: 'Email',
    formatter: emailFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.STATUS_TAG,
    dataField: 'shareholder.status',
    text: 'Status',
  },
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'displayDescription',
    text: 'Description',
    headerStyle: { minWidth: 180 },
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const shareClassColumns = (refetch: any) => [
  {
    ...COLUMN_ATTRIBUTES.TEXT,
    dataField: 'displayDescription',
    text: 'Description',
    headerStyle: { minWidth: 180 },
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, healthCheck: HealthCheck) => (
      <HealthCheckRowActions healthCheck={healthCheck} refetch={refetch} />
    ),
  },
];

export const getDegreeColor = (degree: string) => {
  if (degree === DEGREE.PRIORITY) {
    return 'dangerFill';
  }
  if (degree === DEGREE.RECOMMENDED) {
    return 'warningFill';
  }

  return 'successFill';
};

export const healthCheckBreadCrumbs = [
  {
    title: 'Health Checks',
    link: paths.equity.healthChecks.base,
  },
];
