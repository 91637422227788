export const getLPParticipation = (participation: boolean | string) => {
  if (typeof participation === 'boolean') {
    return participation ? 'yes' : 'no';
  }
  return '';
};

export const requiredDataValidator = (value: string, _row: any) => {
  if (value === '' || value === null || value === undefined) {
    return 'Missing data';
  }
  return true;
};
