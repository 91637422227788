import { Box, Text } from '@angellist/adapt';
import React from 'react';
import FileView from './FileView';
import { AttachmentListProps } from './index.types';

const AttachmentList = (props: AttachmentListProps) => {
  const {
    documents,
    canDownload,
    canRemove,
    afterRemove,
    emptyDocumentText,
    deleteConfirmTitle,
    deleteConfirmText,
    onRowClick,
    rowClickAllowedFileTypes,
  } = props;

  if (documents.length) {
    return (
      <Box
        borderRadius="container"
        borderColor="border10"
        borderWidth="default"
      >
        {documents.map((document, index) => (
          <FileView
            key={document.id}
            description={document.description}
            onRowClick={onRowClick}
            rowClickAllowedFileTypes={rowClickAllowedFileTypes}
            {...document}
            afterRemove={afterRemove}
            canRemove={canRemove}
            canDownload={canDownload}
            deleteConfirmTitle={deleteConfirmTitle}
            deleteConfirmText={deleteConfirmText}
            boxProps={{
              borderColor: 'border10',
              borderBottomWidth:
                index === documents.length - 1 ? '0' : 'default',
            }}
          />
        ))}
      </Box>
    );
  }

  return (
    <Text size="300" color="text70">
      {emptyDocumentText}
    </Text>
  );
};

AttachmentList.defaultProps = {
  canDownload: true,
  canRemove: false,
  documents: [],
  emptyDocumentText: 'No documents available.',
};

export default AttachmentList;
