import React from 'react';
import {
  EsopGrant,
  HealthCheck,
  NewStakeholder,
  RsaGrant,
  RsuGrant,
} from '../../../graphql';
import { formatNumber, getEmployeeName } from '../../helpers/utils';
import paths from '../../paths';
import UnitsColumn from '../common/UnitsColumn';
import {
  ESOP_GRANT_TYPES,
  GRANT_TYPES,
  ALL_GRANT_TYPE_NAME_MAP,
  STATUS,
} from './constants';
import { NameColumn } from '../../helpers/formatters';
import { getIssuanceSigners } from '../grant_issuance_actions/utils';

export const isEsopGrant = (type: string) => ESOP_GRANT_TYPES.includes(type);

export const isRsaGrant = (type: string) => type === GRANT_TYPES.RSA_GRANT;

export const grantTypeFormatter = (type: string) =>
  ALL_GRANT_TYPE_NAME_MAP[type];

export const canAllowEditGrant = (state: string) =>
  [
    STATUS.DRAFT,
    STATUS.BOARD_APPROVED,
    STATUS.AWAITING_BOARD_CONSENT,
    STATUS.PENDING_COMPANY_SIGNATURE,
    STATUS.PENDING_EMPLOYEE_SIGNATURE,
    STATUS.PENDING_ISSUANCE,
  ].includes(state);

export const canAllowModifyGrant = (state: string) => !canAllowEditGrant(state);

export const getStakeholderName = (grant: EsopGrant, emptyString = '') => {
  const { employee } = grant;
  if (employee) {
    return (
      employee?.stakeholder?.name || getEmployeeName(employee, emptyString)
    );
  }
  return emptyString;
};

export const getOptionHolderValue = (
  _: never,
  grant: EsopGrant,
  emptyString = '',
) => getStakeholderName(grant, emptyString);

export const nameFormatter = (name: string, grant: EsopGrant) => {
  const healthChecks =
    grant.healthChecks?.reduce((acc: { [key: string]: HealthCheck }, curr) => {
      if (!curr.source) acc[curr.title] = curr;
      if (curr.source && curr.status === 'active') acc[curr.title] = curr;
      return acc;
    }, {}) || {};

  return (
    <NameColumn
      name={name}
      isDraft={grant.transactionStatus === STATUS.DRAFT}
      healthChecks={Object.values(healthChecks)}
    />
  );
};

export const unitsColumnFormatter = (_: never, grant: EsopGrant) => {
  const { unitsVested, totalUnits } = grant;
  if (totalUnits) {
    return (
      <UnitsColumn
        numerator={unitsVested}
        denominator={totalUnits}
        hideProgress={grant.transactionStatus === STATUS.DRAFT}
      />
    );
  }
  return null;
};

export const filterCompanySignedRows = (row: any) =>
  !!getIssuanceSigners(row?.grantIssuance)?.companyGrantIssuanceSigner
    ?.signedAt;

export const sharesColumnFormatter = (_: never, grant: RsaGrant) => {
  const { shareCertificate } = grant;
  if (shareCertificate?.numShares) {
    return (
      <UnitsColumn
        numerator={shareCertificate?.unitsVested}
        denominator={shareCertificate?.numShares}
        hideProgress={grant.transactionStatus === STATUS.DRAFT}
      />
    );
  }
  return null;
};

export const unitsCsvFormatter = (_: never, grant: EsopGrant) => {
  const { unitsVested, totalUnits } = grant;
  if (totalUnits) {
    return `${unitsVested}/${totalUnits}`;
  }
  return null;
};

export const unitsSearchValue = (_: never, grant: EsopGrant) => {
  const { unitsVested, totalUnits } = grant;

  return `${unitsVested}_${totalUnits}_${formatNumber(
    unitsVested,
  )}_${formatNumber(totalUnits)}`;
};

export const getSelectedGrantRows = (
  getUpdatedRows: Function | Array<RsuGrant | EsopGrant>,
  selectedRows: Array<RsuGrant | EsopGrant>,
  alert: any,
) => {
  if (Array.isArray(getUpdatedRows)) {
    return getUpdatedRows?.filter(filterCompanySignedRows);
  }
  const difference = getUpdatedRows(selectedRows).find(
    (row: RsuGrant) => !selectedRows.includes(row),
  );
  if (
    difference &&
    !getIssuanceSigners(difference?.grantIssuance)?.companyGrantIssuanceSigner
      ?.signedAt
  ) {
    alert.error(
      'You cannot invite this stakeholder until the company signatory has signed the grant. They will receive an email notification once the company signatory has signed.',
    );
  }
  return getUpdatedRows(selectedRows)?.filter(filterCompanySignedRows);
};

export const notEligibleForIso = (grantHolderDetail: NewStakeholder) =>
  (grantHolderDetail?.employee?.employeeType &&
    [
      'contractor',
      'freelance',
      'advisor',
      'other_service_provider',
      'other',
    ].includes(grantHolderDetail?.employee?.employeeType)) ||
  (grantHolderDetail?.shareholder?.role &&
    ['angel', 'investor', 'advisor', 'consultant'].includes(
      grantHolderDetail?.shareholder?.role,
    ));

export const esopGrantBreadcrumbs = (
  isDraft: boolean,
  breadCrumbLabel: string,
  securityDraftsEnabled: boolean,
) => {
  if (securityDraftsEnabled) {
    if (isDraft) {
      return [
        {
          title: 'Drafts',
          link: paths.equity.drafts.all,
        },
        {
          title: breadCrumbLabel,
          link: paths.equity.drafts.options.all,
        },
      ];
    }
    return [
      {
        title: 'Securities',
        link: paths.equity.securities.all,
      },
      {
        title: breadCrumbLabel,
        link: paths.equity.securities.options.all,
      },
    ];
  }
  return [
    {
      title: breadCrumbLabel,
      link: paths.equity.grants.base,
    },
  ];
};
