import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Stack, Heading, Box, TextButton, Callout } from '@angellist/adapt';

import {
  DECIMAL_LIMIT,
  formatNumber,
  getDate,
  getObjectValue,
  processError,
} from '../../../helpers/utils';
import ErrorAlert from '../../common/ErrorAlert';
import { EsopExercise, EsopGrant } from '../../../../graphql';
import {
  DateInput,
  NumberInput,
  Textarea,
  FileUpload,
  AdaptMoneyInput,
  Select,
  Input,
  Row,
} from '../../common/adapt-form';
import { useCompanyContext } from '../../../context/CompanyContext';
import useCertificatesOptionsList from '../../share_certificates/hooks/useCertificatesOptionsList';
import { DateType, OptionType } from '../../../../types';
import useExerciseStats from '../hooks/useExerciseStats';
import { formatDateInMDY } from '../../../utils/date';

type Props = {
  esopGrant: EsopGrant;
  mutationError: any;
  esopExercise?: EsopExercise;
  addNewShareCertificate: boolean;
  setAddNewShareCertificate: (value: boolean) => void;
};

const ExerciseForm = (props: Props) => {
  const {
    esopGrant,
    mutationError,
    esopExercise,
    addNewShareCertificate,
    setAddNewShareCertificate,
  } = props;
  const { errors, control, watch } = useFormContext();
  const { currency: defaultCurrency } = useCompanyContext();
  const [optionLoading, shareCertificateOptions] = useCertificatesOptionsList();
  const watchExerciseAt = watch('exerciseAt', esopExercise?.exerciseAt);
  const watchIsEarlyExercise = watch(
    'isEarlyExercise',
    esopExercise?.isEarlyExercise,
  );

  const defaultValue = esopExercise?.pricePaid?.fractional;
  const defaultCurrencyValue = getObjectValue(
    esopExercise?.pricePaid,
    'currency',
    defaultCurrency,
  );

  const { grantedAt } = esopGrant;

  const [statsLoading, exerciseStats] = useExerciseStats(
    esopGrant.id,
    watchExerciseAt,
  );

  const maxAllowedNumExercise = watchIsEarlyExercise
    ? exerciseStats?.optionsUnvested
    : exerciseStats?.optionsVested;

  let maxRule;
  if (!watchExerciseAt) {
    maxRule = {
      value: 0,
      message: 'Please select an exercise date to see the available options',
    };
  } else if (maxAllowedNumExercise) {
    maxRule = {
      value: maxAllowedNumExercise,
      message: `Number of shares exercised must be less than or equal to ${formatNumber(
        maxAllowedNumExercise,
      )}`,
    };
  } else if (maxAllowedNumExercise <= 0) {
    maxRule = {
      value: 0,
      message: 'No unexercised options available for exercise',
    };
  }

  return (
    <Stack gap="250">
      <ErrorAlert error={mutationError} />
      <Stack gap="100">
        <DateInput
          label="Exercise request date"
          name="exerciseAt"
          control={control}
          rules={{
            required: true,
            validate: {
              validate_grant_date: (date?: DateType) => {
                if (grantedAt && getDate(date) < getDate(grantedAt)) {
                  return `You cannot set the exercise date to be before the grant date (${formatDateInMDY(
                    grantedAt,
                  )}).`;
                }
                return true;
              },
            },
          }}
          defaultValue={esopExercise ? esopExercise.exerciseAt : null}
          error={processError(errors.exerciseAt, 'Exercise date')}
          shouldUnregister
        />
        <Row
          control={control}
          label="Early exercise"
          name="isEarlyExercise"
          suffix={{ field: 'switch' }}
          defaultSelected={esopExercise?.isEarlyExercise}
          divider={false}
          shouldUnregister
        />
        <NumberInput
          key={`numExercise-${watchExerciseAt}-${watchIsEarlyExercise}`}
          label="Number of shares exercised"
          name="numExercise"
          maxLength={12}
          min={0}
          decimalsLimit={DECIMAL_LIMIT.NUM_EXERCISE}
          control={control}
          isLoading={statsLoading}
          defaultValue={esopExercise?.numExercise}
          rules={{
            required: true,
            min: 0,
            max: maxRule,
          }}
          error={processError(errors.numExercise, 'Number of shares')}
          placeholder="How many shares under the option are exercised?"
          shouldUnregister
        />
        <DateInput
          label="Payment received date"
          name="paymentReceivedAt"
          control={control}
          rules={{ required: true }}
          defaultValue={esopExercise ? esopExercise.paymentReceivedAt : null}
          error={processError(errors.paymentReceivedAt, 'Payment date')}
          shouldUnregister
        />
        <AdaptMoneyInput
          label="Total exercise price"
          name="pricePaid"
          defaultCurrency={defaultCurrencyValue}
          defaultFractional={defaultValue}
          control={control}
          rules={{ required: true }}
          error={errors.pricePaid?.message}
          shouldUnregister
        />
      </Stack>
      <Stack gap="100">
        <Heading size="100">Share Certificate Details</Heading>
        <Callout intent="info">
          This share will be linked to the equity plan associated with the
          option being exercised.
        </Callout>
        {addNewShareCertificate ? (
          <>
            <Input
              label="Certificate ID"
              name="shareCertificateName"
              control={control}
              rules={{ required: true }}
              shouldUnregister
              error={processError(
                errors.shareCertificateName,
                'Certificate ID',
              )}
            />
            <AdaptMoneyInput
              label="Price Per Share"
              name="pricePerShare"
              defaultCurrency={defaultCurrency}
              control={control}
              rules={{ required: true }}
              shouldUnregister
              supportMicroCent
              error={errors.pricePerShare?.message}
            />
            <Box>
              <DateInput
                label="Issue Date"
                name="issueDate"
                control={control}
                rules={{ required: true }}
                shouldUnregister
                error={processError(errors?.issueDate, 'Issue date')}
              />
              <Box paddingTop="50">
                <TextButton
                  label="Select existing share certificate"
                  color="accent"
                  onPress={() => setAddNewShareCertificate(false)}
                />
              </Box>
            </Box>
          </>
        ) : (
          <Box>
            <Select
              label="Share certificate"
              name="shareCertificateId"
              rules={{ required: true }}
              control={control}
              defaultValue={esopExercise?.shareCertificateId}
              isLoading={optionLoading as boolean}
              items={shareCertificateOptions as OptionType[]}
              error={processError(
                errors.shareCertificateId,
                'Share certificate',
              )}
              shouldUnregister
            />
            <Box paddingTop="50">
              <TextButton
                label="Add new share certificate"
                color="accent"
                onPress={() => setAddNewShareCertificate(true)}
              />
            </Box>
          </Box>
        )}
      </Stack>
      <Stack gap="100">
        <Heading size="100">Documents & Notes</Heading>
        <Textarea
          label="Admin note"
          name="adminNote"
          control={control}
          defaultValue={esopExercise?.adminNote}
          minHeight="500"
          shouldUnregister
        />
        <FileUpload
          name="documents"
          control={control}
          multiple
          shouldUnregister
        />
      </Stack>
    </Stack>
  );
};

export default ExerciseForm;
