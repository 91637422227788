import { useQuery } from '@apollo/client';
import EXERCISE_STATS_QUERY from '../queries/ExerciseStatsQuery';
import { cloneObject } from '../../../utils';

const useExerciseStats = (esopGrantId: string, asOf: string) => {
  const { loading, error, data } = useQuery(EXERCISE_STATS_QUERY, {
    variables: { id: esopGrantId, asOf },
    skip: !asOf,
  });

  if (error) {
    throw error;
  }
  const stats = cloneObject(
    data?.esopGrant || {
      optionsVested: 0,
      optionsUnvested: 0,
    },
  );

  return [loading, stats];
};

export default useExerciseStats;
