import { Box, Icon, Stack } from '@angellist/adapt';
import React from 'react';

import RowLoader from '../RowLoader';

type Props = {
  numLoadingRows?: number;
  boxProps?: any;
};

const AttachmentListLoader = (props: Props) => {
  const {
    numLoadingRows = 3,
    boxProps = {
      borderRadius: 'container',
      borderColor: 'border10',
      borderWidth: 'default',
    },
  } = props;
  const rows = Array(numLoadingRows).fill('');

  return (
    <Box {...boxProps}>
      {rows.map((_, index) => (
        <Box
          key={index}
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="100"
          minWidth="full"
          padding="100"
          borderColor="border10"
          borderBottomWidth={index === rows.length - 1 ? '0' : 'default'}
        >
          <Icon
            named="loading"
            container="box"
            backgroundColor="surface10"
            animation="spin"
          />
          <Stack gap="25">
            <RowLoader />
            <RowLoader height={10} />
          </Stack>
        </Box>
      ))}
    </Box>
  );
};

export default AttachmentListLoader;
