import { GrantIssuance } from '../../../graphql';

export const getIssuanceSigners = (grantIssuance?: GrantIssuance) => {
  const grantIssuanceSigners = grantIssuance?.grantIssuanceSigners || [];
  const companyGrantIssuanceSigner = grantIssuanceSigners.find(
    ({ role }) => role === 'company',
  );
  const employeeGrantIssuanceSigner = grantIssuanceSigners.find(
    ({ role }) => role === 'recipient',
  );
  const spouseGrantIssuanceSigner = grantIssuanceSigners.find(
    ({ role }) => role === 'spouse',
  );

  return {
    companyGrantIssuanceSigner,
    employeeGrantIssuanceSigner,
    spouseGrantIssuanceSigner,
  };
};

export const getIssuanceSignersFlags = (grantIssuance: GrantIssuance) => {
  const {
    companyGrantIssuanceSigner,
    employeeGrantIssuanceSigner,
    spouseGrantIssuanceSigner,
  } = getIssuanceSigners(grantIssuance);

  const isCompanySigned = !!companyGrantIssuanceSigner?.signedAt;

  const employeeSigned = employeeGrantIssuanceSigner
    ? !!employeeGrantIssuanceSigner?.signedAt
    : false;

  const spouseSigned = spouseGrantIssuanceSigner
    ? !!spouseGrantIssuanceSigner?.signedAt
    : true;

  const pendingSpouseEsign =
    spouseGrantIssuanceSigner && !spouseGrantIssuanceSigner.signedAt;

  return {
    isCompanySigned,
    isStakeholderSigned: employeeSigned && spouseSigned,
    showSpouseSignAction: employeeSigned,
    canRemindEmployeeInvite:
      !!employeeGrantIssuanceSigner?.inviteSentAt && !employeeSigned,
    pendingSpouseEsign,
  };
};
