import { Box, Heading, Stack } from '@angellist/adapt';
import { useHistory } from 'react-router-dom';
import React from 'react';

import { formatDate } from '../../utils/date';
import SlidingPane from '../common/SlidingPane';
import { AdaptRowList } from '../common/AdaptRowItem';
import StatusTag from '../common/StatusTag';
import useGrantSurrender from './hooks/useGrantSurrender';
import { LazyText } from '../common/RowLoader';
import {
  BuybackEligibility,
  EsopGrant,
  GrantSurrender,
} from '../../../graphql';
import paths from '../../paths';
import {
  betterMoneyObjectFormat,
  formatMoneyObject,
  formatNumber,
  formatTitle,
  getEmployeeName,
} from '../../helpers/utils';
import { useCompanyContext } from '../../context/CompanyContext';
import { getEsopGrantPath } from '../esop_grants/utils';

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
}

const getGrantSurrenderDetails = (
  grantSurrender: GrantSurrender,
  loading: boolean,
  push: any,
  securityDraftsEnabled: boolean,
) => {
  const { grant } = grantSurrender as { grant: EsopGrant };
  const employeeName = getEmployeeName(grant?.employee);
  const grantName = grant?.name ?? '—';

  const handleGrantClick = () => {
    if (grantName) {
      push(getEsopGrantPath(grant, undefined, securityDraftsEnabled));
    }
  };

  return [
    {
      label: 'Grant',
      suffix: {
        kind: grantName ? 'textButton' : 'text',
        label: grantName,
        onPress: handleGrantClick,
      },
      loading,
    },
    {
      label: 'Surrender Type',
      suffix: { label: formatTitle(grantSurrender.surrenderType) },
      loading,
    },
    {
      label: 'Option Holder',
      suffix: {
        kind: employeeName ? 'textButton' : 'text',
        label: employeeName,
        onPress: employeeName
          ? () => window.open(`/employee/${grant.employee.id}`)
          : null,
      },
      loading,
    },
    {
      label: 'Number of Options',
      suffix: { label: formatNumber(grantSurrender.numUnits) },
      loading,
    },
    {
      label: 'Price Paid',
      suffix: { label: formatMoneyObject(grantSurrender.price) },
      loading,
    },
    {
      label: 'Surrendered Date',
      suffix: { label: formatDate(grantSurrender.surrenderedAt, '—') },
      loading,
    },
    {
      label: 'Filed On',
      suffix: { label: formatDate(grantSurrender.createdAt, '—') },
      loading,
    },
    {
      label: 'Surrender Reason',
      suffix: { label: grantSurrender.reason || '—' },
      loading,
    },
    {
      label: 'Admin Notes',
      suffix: { label: grantSurrender.adminNotes || '—' },
      loading,
    },
  ];
};

const getBuybackDetails = (
  buybackEligibility: BuybackEligibility,
  paymentMode: string,
  push: any,
) => {
  if (buybackEligibility) {
    const {
      priceOfferedPerShare = {},
      maxBuybackShares,
      maxBuybackPercentOfVested,
      buyback,
    } = buybackEligibility;

    const handleBuyBackNameClick = () => {
      if (buyback?.id) {
        push(paths.equity.buyBack.show(buyback?.id));
      }
    };

    return [
      {
        label: 'Buyback Program',
        suffix: {
          kind: buyback?.name ? 'textButton' : 'text',
          label: buyback?.name,
          onPress: handleBuyBackNameClick,
        },
      },
      {
        label: 'Price offered per share unit',
        suffix: { label: betterMoneyObjectFormat(priceOfferedPerShare) },
      },
      {
        label: 'Max shares eligible',
        suffix: { label: formatNumber(maxBuybackShares) },
      },
      {
        label: 'Maximum shares percent allowed to exercise',
        suffix: { label: `${maxBuybackPercentOfVested}%` },
      },
      {
        label: 'Expected payment mode',
        suffix: { label: paymentMode },
      },
    ];
  }

  return [];
};

const SurrenderDetailPane = (props: Props) => {
  const { isOpen, onClose, id } = props;
  const [loading, grantSurrender] = useGrantSurrender(id);
  const { state, grant, buybackEligibility, paymentMode } = grantSurrender;
  const { securityDraftsEnabled } = useCompanyContext();

  const { push } = useHistory();
  const items = getGrantSurrenderDetails(
    grantSurrender,
    loading,
    push,
    securityDraftsEnabled,
  );
  const buyBackItems = getBuybackDetails(buybackEligibility, paymentMode, push);

  return (
    <SlidingPane isOpen={isOpen} closeSlidingPane={onClose} size="sm">
      <Stack gap="250" key={id}>
        <Stack gap="50">
          <Heading>
            <LazyText loading={loading} text={grant?.name} />
          </Heading>
          <Box>{!!state && <StatusTag status={state} />}</Box>
        </Stack>
        <AdaptRowList items={items} />
        {!!buybackEligibility && (
          <Stack gap="100">
            <Heading size="200">Buyback Details:</Heading>
            <AdaptRowList items={buyBackItems} />
          </Stack>
        )}
      </Stack>
    </SlidingPane>
  );
};

export default SurrenderDetailPane;
