import { useMemo } from 'react';
import {
  Convertible,
  MissingField,
  Security,
  ShareCertificate,
} from '../../../graphql';
import {
  CONVERTIBLE_GQL_SQL_TYPE_MAP,
  CONVERTIBLE_TYPES_LIST,
} from '../convertibles/constant';
import {
  convertibleType,
  getDebtPath,
  getSafePath,
  getSaftPath,
  getTokenWarrantPath,
  getWarrantPath,
  getConvertibleName,
} from '../convertibles/utils';
import { grantTypeFormatter } from '../grants/utils';
import { getEsopGrantPath } from '../esop_grants/utils';
import { getRsuGrantPath } from '../rsu_grants/utils';
import { isDraft } from './share_certificates/utils';
import paths from '../../paths';

export const isGrantTypeName = (security: Security) =>
  security.__typename === 'EsopGrant' || security.__typename === 'RsuGrant';

export const getSecurityName = (security: Security) => {
  if (CONVERTIBLE_TYPES_LIST.includes(security.__typename))
    return getConvertibleName(security as Convertible);
  if (isGrantTypeName(security)) return security.name;
  if (security.__typename === 'ShareCertificate') return security.certificateId;
  return '—';
};

export const securityType = (security: Security) => {
  if (CONVERTIBLE_TYPES_LIST.includes(security.__typename))
    return convertibleType(security as Convertible);
  if (security.__typename === 'EsopGrant')
    return grantTypeFormatter(security.grantType);
  if (security.__typename === 'RsuGrant') return grantTypeFormatter('RSU');
  if (security.__typename === 'ShareCertificate') return 'Share Certificate';
  return security.__typename;
};

const getMissingFieldsForPublish = (
  security: Security,
): Array<MissingField> => {
  if (isGrantTypeName(security)) {
    return security.fieldsMissingForPublish;
  }

  if (security.__typename === 'ShareCertificate') {
    if (security.rsaGrant) {
      return security.rsaGrant.fieldsMissingForPublish;
    }
    return security.fieldsMissingForPublish;
  }

  return (security as Convertible)?.missingFields || [];
};

const getInvalidFieldsForPublish = (security: Security): Array<MissingField> =>
  (security as Convertible)?.invalidFieldsForPublish || [];

export const getMissingFieldsMetadata = (security: Security) => {
  const missingFields = (getMissingFieldsForPublish(security) || []).filter(
    (x) => x.required,
  );
  const invalidFields = getInvalidFieldsForPublish(security).filter(
    (x) => x.required,
  );

  return {
    security,
    missingFields,
    invalidFields,
    isValid: missingFields.length === 0 && invalidFields.length === 0,
  };
};

export const backendType = (security: Security) => {
  if (
    ['EsopGrant', 'RsuGrant', 'ShareCertificate'].includes(security.__typename)
  ) {
    return security.__typename;
  }

  return CONVERTIBLE_GQL_SQL_TYPE_MAP[(security as Convertible).__typename];
};

export const sortByCreatedAt = (a: Security, b: Security) => {
  if (a.createdAt && b.createdAt) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }
  return 0;
};

export const useCalculatedSecurities = (securities: Security[]) =>
  useMemo(() => {
    const allSecurities = securities.map((security) =>
      getMissingFieldsMetadata(security),
    );
    const validSecurities = allSecurities.filter((x) => x.isValid);
    const missinInfoSecurities = allSecurities.filter((x) => !x.isValid);

    return { allSecurities, validSecurities, missinInfoSecurities };
  }, [securities]);

export const getShareCertificatePath = (
  shareCertificate: ShareCertificate,
  tab?: string,
  securityDraftsEnabled: boolean = false,
) => {
  if (!shareCertificate) return null;
  if (securityDraftsEnabled) {
    if (isDraft(shareCertificate)) {
      return paths.equity.drafts.shares.show(shareCertificate.id, tab);
    }
    return paths.equity.securities.shares.show(shareCertificate.id, tab);
  }
  return paths.equity.shareCertificate(shareCertificate.id, tab);
};

export const getSecurityPath = (
  security: Security,
  securityDraftsEnabled = false,
) => {
  const { __typename } = security;
  if (__typename === 'EsopGrant') {
    return getEsopGrantPath(security, undefined, securityDraftsEnabled);
  }
  if (__typename === 'RsuGrant') {
    return getRsuGrantPath(security, undefined, securityDraftsEnabled);
  }
  if (__typename === 'ShareCertificate') {
    return getShareCertificatePath(security, undefined, securityDraftsEnabled);
  }
  if (__typename === 'ConvertibleSafe') {
    return getSafePath(security, undefined, securityDraftsEnabled);
  }
  if (__typename === 'ConvertibleDebt') {
    return getDebtPath(security, undefined, securityDraftsEnabled);
  }
  if (__typename === 'WarrantCertificate') {
    return getWarrantPath(security, securityDraftsEnabled);
  }
  if (__typename === 'Saft') {
    return getSaftPath(security, securityDraftsEnabled);
  }
  if (__typename === 'TokenWarrant') {
    return getTokenWarrantPath(security, securityDraftsEnabled);
  }
  return null;
};
