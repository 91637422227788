import React from 'react';
import { EquityTransaction, EsopGrant, ExercisePeriod } from '../../../graphql';
import { ObjectType, VoidFunc } from '../../../types';
import { COLUMN_ATTRIBUTES } from '../../utils/column';
import {
  IdFormatter,
  getTransactionTypeValue,
  granttransactionStatusFormatter,
} from '../equity_transactions/utils';
import ExerciseRowActions from './PriorExercises/ExerciseRowActions';
import {
  DECIMAL_LIMIT,
  formatNumberWithPrecision,
  formatToNumber,
} from '../../helpers/utils';
import { TRANSACTION_TYPES } from '../equity_transactions/constant';
import paths from '../../paths';
import { STATUS } from '../grants/constants';
import { EXERCISE_PERIOD_REGEX } from '../../../shared/components/importer/validators';

interface TransactionRow {
  transactionType: string;
  numUnits: number;
  [key: string]: any;
}

const quantityFormatter = (num: number, row: TransactionRow): string =>
  row.transactionType === TRANSACTION_TYPES.AMENDMENT
    ? '—'
    : formatNumberWithPrecision(num, DECIMAL_LIMIT.NUM_EXERCISE);

export const formatGrantType = (grantType?: string, isIsoNso?: boolean) => {
  if (isIsoNso) {
    return 'Option (ISO/NSO)';
  }

  if (grantType === 'NSO') {
    return 'Option (NSO)';
  }
  if (grantType === 'ISO') {
    return 'Option (ISO)';
  }
  if (grantType === 'RSU') {
    return 'RSU Award';
  }
  if (grantType === 'INTL') {
    return 'Option (INTL)';
  }
  if (grantType === 'EMI') {
    return 'Option (EMI)';
  }
  return 'Option';
};

export const getDurationValue = (duration: number, interval: string) => {
  if (interval === 'unlimited') {
    return 'Unlimited';
  }
  if (duration || duration === 0) {
    const intervalText = duration <= 1 ? interval : `${interval}s`;
    return `${duration} ${intervalText}`;
  }

  return '—';
};

export const getExercisePeriodOptions = (exercisePeriod: ExercisePeriod) => [
  {
    label: 'Voluntary Termination',
    value: getDurationValue(
      exercisePeriod?.voluntaryTerminationDuration,
      exercisePeriod?.voluntaryTerminationInterval,
    ),
  },
  {
    label: 'Involuntary Termination',
    value: getDurationValue(
      exercisePeriod?.involuntaryTerminationDuration,
      exercisePeriod?.involuntaryTerminationInterval,
    ),
  },
  {
    label: 'Termination For Cause',
    value: getDurationValue(
      exercisePeriod?.terminationForCauseDuration,
      exercisePeriod?.terminationForCauseInterval,
    ),
  },
  {
    label: 'Death',
    value: getDurationValue(
      exercisePeriod?.deathDuration,
      exercisePeriod?.deathInterval,
    ),
  },
  {
    label: 'Disability',
    value: getDurationValue(
      exercisePeriod?.disabilityDuration,
      exercisePeriod?.disabilityInterval,
    ),
  },
  {
    label: 'Retirement',
    value: getDurationValue(
      exercisePeriod?.retirementDuration,
      exercisePeriod?.retirementInterval,
    ),
  },
];

export const shouldRegenerateGrant = (data: any, esopGrant: EsopGrant) => {
  const {
    grantDetailAttributes: {
      numOptions,
      exercisePrice,
      expiryDate,
      grantType,
      grantedAt,
      name,
      stakeholderId,
    },
    vestingAttributes: {
      vestingStartBackdate = null,
      vestingTemplateId = null,
      allowEarlyExercise = false,
      accelerationTemplateId = null,
      acceleratedVestingDescription = null,
    },
    grantDocumentAttributes: { usedGrantDocumentSetId },
  } = data;

  const regenFields = [];
  if (numOptions !== esopGrant.grantTerms.numOptions) {
    regenFields.push('Number of options');
  }
  if (
    exercisePrice.fractional !== esopGrant.grantTerms.exercisePrice.fractional
  ) {
    regenFields.push('Exercise price');
  }
  if (expiryDate !== esopGrant.grantTerms.expiryDate) {
    regenFields.push('Expiry date');
  }
  if (vestingStartBackdate !== esopGrant.grantTerms.vestingStartBackdate) {
    regenFields.push('Vesting start backdate');
  }
  if (vestingTemplateId !== (esopGrant.vestingTemplate?.id || null)) {
    regenFields.push('Vesting template');
  }
  if (!!vestingTemplateId && esopGrant.vestingEvents.length === 0) {
    regenFields.push('Vesting schedule (template set but no events)');
  }
  if (usedGrantDocumentSetId !== esopGrant.usedGrantDocumentSetId) {
    regenFields.push('Grant document template');
  }
  if (grantType !== esopGrant.grantType) {
    regenFields.push('Grant type');
  }
  if (grantedAt !== esopGrant.grantTerms.grantedAt) {
    regenFields.push('Grant date');
  }
  if (name !== esopGrant.name) {
    regenFields.push('Grant name');
  }
  if (stakeholderId !== esopGrant.stakeholder?.id) {
    regenFields.push('Grant holder');
  }
  if (allowEarlyExercise !== esopGrant.allowEarlyExercise) {
    regenFields.push('Allow early exercise');
  }
  if (accelerationTemplateId !== (esopGrant.accelerationTemplate?.id || null)) {
    regenFields.push('Acceleration template');
  }
  if (
    acceleratedVestingDescription !== esopGrant.acceleratedVestingDescription
  ) {
    regenFields.push('Acceleration description');
  }

  return regenFields;
};

export const TRANSACTION_COLUMNS = (
  esopGrant: EsopGrant,
  refetchGrant: VoidFunc,
) => [
  {
    ...COLUMN_ATTRIBUTES.DATE,
    dataField: 'transactionDate',
    text: 'Transaction Date',
    formatter: (_: string, equityTransaction: EquityTransaction) => (
      <IdFormatter equityTransaction={equityTransaction} />
    ),
  },
  {
    dataField: 'transactionType',
    text: 'Transaction Type',
    formatter: getTransactionTypeValue,
    searchValue: getTransactionTypeValue,
    sort: true,
    headerStyle: { minWidth: 120 },
  },
  {
    ...COLUMN_ATTRIBUTES.NUMBER,
    dataField: 'numUnits',
    text: 'Quantity',
    formatter: quantityFormatter,
  },
  {
    dataField: 'state',
    text: 'Status',
    formatter: granttransactionStatusFormatter,
  },
  {
    ...COLUMN_ATTRIBUTES.ADAPT_ACTION,
    formatter: (_: any, transaction: EquityTransaction) => {
      // eslint-disable-next-line no-underscore-dangle
      if (transaction.transactionEvent.__typename === 'EsopExercise') {
        return (
          <ExerciseRowActions
            esopGrant={esopGrant}
            esopExercise={transaction.transactionEvent}
            refetchExercises={refetchGrant}
          />
        );
      }

      return null;
    },
  },
];

export const formatExercisePeriodAttaributes = (
  exercisePeriodAttributes: any,
): any => {
  if (exercisePeriodAttributes) {
    const attrs: any = {};
    Object.keys(exercisePeriodAttributes).forEach((periodKey: string) => {
      const period = exercisePeriodAttributes[periodKey];
      attrs[`${periodKey}Duration`] = formatToNumber(period?.duration);
      attrs[`${periodKey}Interval`] = period?.interval;
    });
    return attrs;
  }

  return null;
};

export const formatUpdateArgs = (formData: any) => {
  const {
    exercisePrice,
    fairMarketValue,
    valuationId,
    ...restGrantDetailAttributes
  } = formData.grantDetailAttributes;

  const fmvAttributes: { [key: string]: any } = {};
  if (valuationId === 'custom') {
    fmvAttributes.fairMarketValueCurrency = fairMarketValue?.currency;
    fmvAttributes.fairMarketValueCents = fairMarketValue?.fractional;
  } else {
    fmvAttributes.valuationId = valuationId;
  }

  const newGrantDetailAttributes = {
    exercisePriceCurrency: exercisePrice?.currency,
    exercisePriceCents: exercisePrice?.fractional,
    ...fmvAttributes,
    ...restGrantDetailAttributes,
  };

  const newFormData = {
    ...newGrantDetailAttributes,
    ...formData.boardConsentAttributes,
    ...formData.documentsAndNotesAttributes,
    ...formData.grantDocumentAttributes,
    ...formData.securitiesComplianceAttributes,
    ...formData.vestingAttributes,
    exercisePeriodAttributes: formatExercisePeriodAttaributes(
      formData.exercisePeriodAttributes,
    ),
  };

  return newFormData;
};

export const getEsopGrantPath = (
  esopGrant: EsopGrant,
  tab?: string,
  securityDraftsEnabled: boolean = false,
) => {
  if (!esopGrant) return null;
  if (securityDraftsEnabled) {
    if (esopGrant.transactionStatus === STATUS.DRAFT) {
      return paths.equity.drafts.options.show(esopGrant.id, tab);
    }
    return paths.equity.securities.options.show(esopGrant.id, tab);
  }

  return paths.equity.grants.esopGrant(esopGrant.id, tab);
};

const INTERVAL_MAP: ObjectType = {
  days: 'day',
  weeks: 'week',
  months: 'month',
  quarters: 'quarter',
  years: 'year',
};

const extractExercisePeriodValue = (
  exercisePeriod: string,
  periodKey: string,
) => {
  const match = exercisePeriod.match(EXERCISE_PERIOD_REGEX);
  if (match) {
    const duration = match[1] ? parseInt(match[1], 10) : null;
    const interval = (match[2] || match[0])?.toLowerCase();
    return {
      [`${periodKey}Duration`]: formatToNumber(duration),
      [`${periodKey}Interval`]: INTERVAL_MAP[interval] || interval,
    };
  }
  return {};
};

export const processBulkUpsertGrants = (grants: any[]) =>
  grants.map((grant) => {
    const {
      voluntaryTermination,
      involuntaryTermination,
      terminationForCause,
      death,
      disability,
      retirement,
      ...restAttributes
    } = grant;

    const exercisePeriodAttributes = {
      ...extractExercisePeriodValue(
        voluntaryTermination,
        'voluntaryTermination',
      ),
      ...extractExercisePeriodValue(
        involuntaryTermination,
        'involuntaryTermination',
      ),
      ...extractExercisePeriodValue(terminationForCause, 'terminationForCause'),
      ...extractExercisePeriodValue(death, 'death'),
      ...extractExercisePeriodValue(disability, 'disability'),
      ...extractExercisePeriodValue(retirement, 'retirement'),
    };

    return {
      ...restAttributes,
      exercisePeriodAttributes: Object.keys(exercisePeriodAttributes).length
        ? exercisePeriodAttributes
        : undefined,
    };
  });

export const hasRequiredExerciseInfo = (esopGrant: EsopGrant) => {
  const {
    grantTerms,
    exerciseLetterTemplateDocument,
    requiresHtmlConverter,
    hasExerciseLetterHtmlTemplate,
  } = esopGrant;
  const { fairMarketValue } = grantTerms || {};

  const hasExerciseTemplate = requiresHtmlConverter
    ? hasExerciseLetterHtmlTemplate
    : exerciseLetterTemplateDocument;
  const isMissingFairMarketValue = !fairMarketValue;
  const isMissingExerciseAgreement = !hasExerciseTemplate;
  const isMissingEmployeeType = !esopGrant?.employee?.employeeType;
  const isMissingRequiredInfo =
    isMissingFairMarketValue ||
    isMissingExerciseAgreement ||
    isMissingEmployeeType;

  return !isMissingRequiredInfo;
};
