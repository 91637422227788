import React from 'react';
import { Stack, Text } from '@angellist/adapt';

import {
  ConvertibleDebt,
  ConvertibleSafe,
  EquityTransaction,
  EsopGrant,
  NewStakeholder,
  Security,
  ShareCertificate,
  WarrantCertificate,
} from '../../../graphql';
import {
  LinkColumn,
  LinkTextColumn,
  NameColumn,
} from '../../helpers/formatters';
import { formatDate } from '../../helpers/utils';
import {
  AMENDMENT_STATUS_MAP,
  EXERCISE_STATUS_MAP,
  TRANSACTION_TYPES,
  TRANSACTION_TYPE_NAME_MAP,
} from './constant';

import paths from '../../paths';
import SurrenderDetailPane from '../surrender/SurrenderDetailPane';
import ShareRepurchaseDetailPane from './components/ShareRepurchaseDetailPane';
import useAdaptPane from '../common/hooks/useAdaptPane';
import StatusTag from '../common/StatusTag';
import { useCompanyContext } from '../../context/CompanyContext';
import {
  getDebtPath,
  getSafePath,
  getWarrantPath,
} from '../convertibles/utils';
import { getEsopGrantPath } from '../esop_grants/utils';
import { getShareCertificatePath } from '../security_drafts/utils';

export const SecurityNameFormatter = ({ security }: { security: Security }) => {
  const { securityDraftsEnabled } = useCompanyContext();
  if (security === null || security === undefined) {
    return null;
  }

  const { __typename } = security;

  if (__typename === 'ShareCertificate') {
    return (
      <LinkColumn
        to={getShareCertificatePath(security, undefined, securityDraftsEnabled)}
        name={security.certificateId}
      />
    );
  }

  if (__typename === 'ConvertibleSafe') {
    return (
      <LinkColumn
        to={getSafePath(security, undefined, securityDraftsEnabled)}
        name={security.name}
      />
    );
  }

  if (__typename === 'ConvertibleDebt') {
    return (
      <LinkColumn
        to={getDebtPath(security, undefined, securityDraftsEnabled)}
        name={security.certificateId}
      />
    );
  }

  if (__typename === 'WarrantCertificate') {
    return (
      <LinkColumn
        to={getWarrantPath(security, securityDraftsEnabled)}
        name={security.name}
      />
    );
  }

  if (__typename === 'EsopGrant') {
    return (
      <LinkColumn
        to={getEsopGrantPath(security, undefined, securityDraftsEnabled)}
        name={security.name}
      />
    );
  }

  return null;
};

export const NameFormatter = (props: {
  equityTransaction: EquityTransaction;
}) => {
  const {
    equityTransaction: {
      instrumentId,
      transactionType,
      instrumentType,
      instrumentName,
    },
  } = props;

  const { securityDraftsEnabled } = useCompanyContext();

  if (transactionType === 'original_issuance') {
    let path;
    switch (instrumentType) {
      case 'EsopGrant':
        path = getEsopGrantPath(
          { id: instrumentId } as EsopGrant,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ShareCertificate':
        path = getShareCertificatePath(
          { id: instrumentId } as ShareCertificate,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleSafeInstrument':
        path = getSafePath(
          { id: instrumentId } as ConvertibleSafe,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleDebt':
        path = getDebtPath(
          { id: instrumentId } as ConvertibleDebt,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'WarrantCertificate':
        path = getWarrantPath(
          { id: instrumentId } as WarrantCertificate,
          securityDraftsEnabled,
        );
        break;
      default:
        path = '';
    }

    if (path) {
      return <LinkColumn to={path} name={instrumentName} />;
    }
    return <LinkTextColumn name={instrumentName} />;
  }

  if (
    transactionType === 'share_repurchase' ||
    transactionType === 'balance_transfer'
  ) {
    return (
      <LinkColumn
        to={getShareCertificatePath(
          { id: instrumentId } as ShareCertificate,
          undefined,
          securityDraftsEnabled,
        )}
        name={instrumentName}
      />
    );
  }

  if (transactionType === 'transfer') {
    let path;
    switch (instrumentType) {
      case 'EsopGrant':
        path = getEsopGrantPath(
          { id: instrumentId } as EsopGrant,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ShareCertificate':
        path = getShareCertificatePath(
          { id: instrumentId } as ShareCertificate,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleSafeInstrument':
        path = getSafePath(
          { id: instrumentId } as ConvertibleSafe,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleDebt':
        path = getDebtPath(
          { id: instrumentId } as ConvertibleDebt,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'WarrantCertificate':
        path = getWarrantPath(
          { id: instrumentId } as WarrantCertificate,
          securityDraftsEnabled,
        );
        break;
      default:
        path = '';
    }

    if (path) {
      return <LinkColumn to={path} name={instrumentName} />;
    }
    return <LinkTextColumn name={instrumentName} />;
  }

  if (transactionType === 'conversion_event') {
    let path;
    switch (instrumentType) {
      case 'EsopGrant':
        path = getEsopGrantPath(
          { id: instrumentId } as EsopGrant,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ShareCertificate':
        path = getShareCertificatePath(
          { id: instrumentId } as ShareCertificate,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleSafeInstrument':
        path = getSafePath(
          { id: instrumentId } as ConvertibleSafe,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleDebt':
        path = getDebtPath(
          { id: instrumentId } as ConvertibleDebt,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'WarrantCertificate':
        path = getWarrantPath(
          { id: instrumentId } as WarrantCertificate,
          securityDraftsEnabled,
        );
        break;
      default:
        path = '';
    }

    if (path) {
      return <LinkColumn to={path} name={instrumentName} />;
    }
    return <LinkTextColumn name={instrumentName} />;
  }

  if (transactionType === 'amendment_event') {
    let path;
    switch (instrumentType) {
      case 'EsopGrant':
        path = getEsopGrantPath(
          { id: instrumentId } as EsopGrant,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ShareCertificate':
        path = getShareCertificatePath(
          { id: instrumentId } as ShareCertificate,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleSafeInstrument':
        path = getSafePath(
          { id: instrumentId } as ConvertibleSafe,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'ConvertibleDebt':
        path = getDebtPath(
          { id: instrumentId } as ConvertibleDebt,
          undefined,
          securityDraftsEnabled,
        );
        break;
      case 'WarrantCertificate':
        path = getWarrantPath(
          { id: instrumentId } as WarrantCertificate,
          securityDraftsEnabled,
        );
        break;
      default:
        path = '';
    }

    if (path) {
      return <LinkColumn to={path} name={instrumentName} />;
    }
    return <LinkTextColumn name={instrumentName} />;
  }

  return (
    <LinkColumn
      to={getEsopGrantPath(
        { id: instrumentId } as EsopGrant,
        undefined,
        securityDraftsEnabled,
      )}
      name={instrumentName}
    />
  );
};

const SurrenderDateCell = (props: { equityTransaction: EquityTransaction }) => {
  const { equityTransaction } = props;
  const detailPane = useAdaptPane();

  return (
    <>
      <LinkTextColumn
        name={formatDate(equityTransaction.transactionDate)}
        onClick={() => detailPane.onOpen({ id: equityTransaction.id })}
      />
      {detailPane.paneProps.id && (
        <SurrenderDetailPane
          isOpen={detailPane.isOpen}
          onClose={detailPane.onClose}
          id={detailPane.paneProps.id}
        />
      )}
    </>
  );
};

const ShareRepurchaseDateCell = (props: {
  equityTransaction: EquityTransaction;
}) => {
  const { equityTransaction } = props;
  const detailPane = useAdaptPane();

  return (
    <>
      <LinkTextColumn
        name={formatDate(equityTransaction.transactionDate)}
        onClick={() => detailPane.onOpen({ id: equityTransaction.id })}
      />
      {detailPane.paneProps.id && (
        <ShareRepurchaseDetailPane
          isOpen={detailPane.isOpen}
          onClose={detailPane.onClose}
          id={detailPane.paneProps.id}
        />
      )}
    </>
  );
};

export const IdFormatter = (props: {
  equityTransaction: EquityTransaction;
}) => {
  const { equityTransaction } = props;
  const { id, transactionType, transactionDate } = equityTransaction;
  const { securityDraftsEnabled } = useCompanyContext();

  if (transactionType === 'option_exercise') {
    return (
      <LinkColumn
        to={paths.equity.exercise(id)}
        name={formatDate(transactionDate, '—')}
      />
    );
  }

  if (transactionType === 'share_repurchase') {
    return <ShareRepurchaseDateCell equityTransaction={equityTransaction} />;
  }

  if (
    [
      'balance_transfer',
      'transfer',
      'conversion_event',
      'stock_split',
    ].includes(transactionType)
  ) {
    return (
      <NameColumn name={formatDate(equityTransaction.transactionDate, '—')} />
    );
  }
  if (transactionType === 'amendment_event') {
    return (
      <LinkColumn
        to={paths.equity.amendment(id)}
        name={formatDate(transactionDate)}
      />
    );
  }

  if (transactionType === 'original_issuance') {
    switch (equityTransaction.instrumentType) {
      case 'EsopGrant':
        return (
          <LinkColumn
            to={getEsopGrantPath(
              { id: equityTransaction.instrumentId } as EsopGrant,
              undefined,
              securityDraftsEnabled,
            )}
            name={formatDate(transactionDate, '—')}
          />
        );
      case 'ShareCertificate':
        return (
          <LinkColumn
            to={getShareCertificatePath(
              { id: equityTransaction.instrumentId } as ShareCertificate,
              undefined,
              securityDraftsEnabled,
            )}
            name={formatDate(transactionDate, '—')}
          />
        );
      case 'ConvertibleSafeInstrument':
        return (
          <LinkColumn
            to={getSafePath(
              { id: equityTransaction.instrumentId } as ConvertibleSafe,
              undefined,
              securityDraftsEnabled,
            )}
            name={formatDate(transactionDate, '—')}
          />
        );
      case 'ConvertibleDebt':
        return (
          <LinkColumn
            to={getDebtPath(
              { id: equityTransaction.instrumentId } as ConvertibleDebt,
              undefined,
              securityDraftsEnabled,
            )}
            name={formatDate(transactionDate, '—')}
          />
        );
      case 'WarrantCertificate':
        return (
          <LinkColumn
            to={getWarrantPath(
              { id: equityTransaction.instrumentId } as WarrantCertificate,
              securityDraftsEnabled,
            )}
            name={formatDate(transactionDate, '—')}
          />
        );
      default:
        return <NameColumn name={formatDate(transactionDate, '—')} />;
    }
  }

  if (transactionType === 'surrender') {
    return <SurrenderDateCell equityTransaction={equityTransaction} />;
  }

  return <NameColumn name={formatDate(transactionDate, '—')} />;
};

export const stakeholderFormatter = (stakeholder: NewStakeholder) => {
  if (stakeholder?.name) {
    return stakeholder.name;
  }
  return '—';
};

export const stakeholderValue = (stakeholder: NewStakeholder) => {
  if (stakeholder?.name) {
    return stakeholder.name;
  }
  return '';
};

export const getTransactionTypeValue = (transactionType: string) =>
  TRANSACTION_TYPE_NAME_MAP[transactionType];

export const transactionStatusFormatter = (
  transactionType: string,
  equityTransaction: EquityTransaction,
) => {
  const { state } = equityTransaction;

  return (
    <>
      {transactionType === TRANSACTION_TYPES.OPTION_EXERCISE && (
        <StatusTag status={state} label={EXERCISE_STATUS_MAP[state]} />
      )}
    </>
  );
};

export const granttransactionStatusFormatter = (
  transactionType: string,
  equityTransaction: EquityTransaction,
) => {
  const { state } = equityTransaction;

  const statusLabel =
    transactionType === TRANSACTION_TYPES.AMENDMENT
      ? AMENDMENT_STATUS_MAP[state]
      : EXERCISE_STATUS_MAP[state];

  return <StatusTag status={state} label={statusLabel} />;
};
export const transactionTypeFormatter = (transactionType: string) => (
  <Stack gap="50">
    <Text>{getTransactionTypeValue(transactionType)}</Text>
  </Stack>
);
