import { gql } from '@apollo/client';

const ALL_GRANT_DOCUMENT_SETS_QUERY = gql`
  query AllGrantDocumentSetsQuery($companyId: ID!) {
    allGrantDocumentSets(companyId: $companyId) {
      id
      name
      version
      adminNotes
      status
      grantCount
      documentNames
      securityType
      hasMissingVars
      requiresHtmlConverter
      grantNoticeTemplate {
        id
        name
        requiresCompanySignature
        requiresSpousalConsent
        requiresRecipientSignature
        signaturePageText
        spousalConsentText
        participantReferenceTitle
        customizedLetterDocxMetadata {
          id
          documentName
          documentPath
          createdAt
        }
        exhibitDocumentsMetadata {
          id
          documentName
          documentPath
          createdAt
        }
        skipVestingScheduleGeneration
        adminNotes
      }
      exerciseLetterTemplate {
        id
        name
        requiresCompanySignature
        requiresSpousalConsent
        requiresRecipientSignature
        participantReferenceTitle
        customizedLetterDocxMetadata {
          id
          documentName
          documentPath
          createdAt
        }
      }
      earlyExerciseLetterTemplate {
        id
        name
        requiresCompanySignature
        requiresSpousalConsent
        requiresRecipientSignature
        participantReferenceTitle
        customizedLetterDocxMetadata {
          id
          documentName
          documentPath
          createdAt
        }
      }
      uploadedByUser {
        id
        name
      }
    }
  }
`;

export default ALL_GRANT_DOCUMENT_SETS_QUERY;
