import { gql } from '@apollo/client';

const UPDATE_ESOP_EXERCISE_MUTATION = gql`
  mutation UpdateEsopGrantExercise(
    $id: ID!
    $esopExerciseAttributes: EsopExerciseAttributes!
  ) {
    updateEsopGrantExercise(
      id: $id
      esopExerciseAttributes: $esopExerciseAttributes
    ) {
      id
      numExercise
      exerciseAt
      employeeNote
      adminNote
      state
      pricePaid {
        fractional
        currency
        betterFormat
      }
    }
  }
`;

export default UPDATE_ESOP_EXERCISE_MUTATION;
