import React from 'react';

import UploadModal from './UploadModal';
import useUploadFiles from '../hooks/useUploadFiles';
import { FileUploadProps } from './index.types';
import useModal from '../hooks/useModal';
import AdaptModalButton from '../AdaptModalButton';

type Props = {
  afterUpload: (formData: any) => void;
} & FileUploadProps;

const FileUploadModal = (props: Props) => {
  const modalActions = useModal();
  const {
    ownerObjectType,
    ownerObjectId,
    ownerObjectField,
    afterUpload,
    buttonText = 'Add document',
    buttonType,
    buttonProps = {},
    ...rest
  } = props;

  const { submitting, onUpload, mutationError } = useUploadFiles((data) => {
    afterUpload(data);
    modalActions.closeModal();
  });

  const uploadFiles = ({ documents }: { documents: File[] }) => {
    onUpload({
      ownerObjectType,
      ownerObjectId,
      ownerObjectField,
      documents,
    });
  };

  return (
    <AdaptModalButton
      label={buttonText}
      buttonType={buttonType}
      buttonProps={buttonProps}
      modalActions={modalActions}
      modalComponent={UploadModal}
      modalProps={{
        isUploading: submitting,
        onUpload: uploadFiles,
        uploadError: mutationError,
        ...rest,
      }}
    />
  );
};

export default FileUploadModal;
