import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import { Modal } from '@angellist/adapt';

import { ModalActions, VoidFunc } from '../../../../types';
import useUpdateEsopExercise from '../hooks/useUpdateEsopExercise';
import ExerciseForm from './ExerciseForm';
import { EsopExercise, EsopGrant } from '../../../../graphql';

type Props = {
  esopGrant: EsopGrant;
  esopExercise: EsopExercise;
  refetchExercises: VoidFunc;
} & Pick<ModalActions, 'modal' | 'closeModal'>;

const EditExerciseModal = (props: Props) => {
  const {
    esopGrant,
    esopExercise,
    refetchExercises,
    modal,
    closeModal,
  } = props;
  const formProps = useForm();
  const [addNewShareCertificate, setAddNewShareCertificate] = useState(false);

  const { submitting, mutationError, onUpdate } = useUpdateEsopExercise(
    esopExercise.id,
    addNewShareCertificate,
    () => {
      closeModal();
      refetchExercises();
    },
  );

  return (
    <Modal
      header={{
        title: 'Edit Exercise',
        buttons: [
          {
            key: 'submit',
            label: 'Save',
            isDisabled: submitting,
            isLoading: submitting,
            onPress: (e: any) => {
              formProps.handleSubmit(onUpdate)(e);
            },
          },
        ],
      }}
      onClose={closeModal}
      isOpen={modal}
    >
      <FormProvider {...formProps}>
        <Form autoComplete="off" onSubmit={formProps.handleSubmit(onUpdate)}>
          <ExerciseForm
            esopGrant={esopGrant}
            mutationError={mutationError}
            esopExercise={esopExercise}
            addNewShareCertificate={addNewShareCertificate}
            setAddNewShareCertificate={setAddNewShareCertificate}
          />
        </Form>
      </FormProvider>
    </Modal>
  );
};

export default EditExerciseModal;
