import React from 'react';
import { Box, FileType } from '@angellist/adapt';

import AdaptDeleteDialog from '../AdaptDeleteDialog';
import useDeleteAttachment from '../hooks/useDeleteAttachment';
import useModal from '../hooks/useModal';
import { FileViewProps } from './index.types';
import AdaptFilePreview from './AdaptFilePreview';

const FileView = (props: FileViewProps) => {
  const { modal, openModal, closeModal } = useModal();
  const {
    id,
    documentName,
    createdAt,
    canDownload,
    canRemove,
    afterRemove,
    documentPath,
    deleteConfirmTitle,
    deleteConfirmText,
    onRowClick,
    description,
    rowClickAllowedFileTypes,
    ...previewProps
  } = props;

  const names = documentName.split('.');
  const fileType = names[names.length - 1] || '';

  let canRowClick = !!onRowClick;
  if (canRowClick && rowClickAllowedFileTypes) {
    canRowClick = rowClickAllowedFileTypes.includes(fileType);
  }

  const handleRowClick = () => {
    if (canRowClick) {
      onRowClick({ id, documentName });
    }
  };

  const { onDelete, deleting, deleteError } = useDeleteAttachment(() => {
    if (afterRemove) {
      afterRemove();
    }
    closeModal();
  });

  return (
    <>
      <Box
        id={`attachment-${id}`}
        onClick={handleRowClick}
        cursor={canRowClick ? 'pointer' : 'default'}
        display="grid"
      >
        <AdaptFilePreview
          fileName={documentName}
          fileType={fileType as FileType}
          // @ts-ignore
          description={description}
          fileUploadedAt={createdAt ? new Date(createdAt) : undefined}
          href={canDownload ? documentPath : null}
          onPressRemove={canRemove ? () => openModal() : null}
          {...previewProps}
        />
      </Box>
      {canRemove && (
        <AdaptDeleteDialog
          isOpen={modal}
          onDelete={() => onDelete(id)}
          onCancel={closeModal}
          isDeleting={deleting}
          deleteError={deleteError}
          headerText={deleteConfirmTitle || `Delete ${documentName}`}
          confirmText={deleteConfirmText}
        />
      )}
    </>
  );
};

export default FileView;
