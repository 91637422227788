import { gql } from '@apollo/client';

const SHARE_CERTIFICATE_SUMMARY_FRAGMENT = gql`
  fragment ShareCertificateSummaryFragment on ShareCertificate {
    id
    certificateId
    notes
    numShares
    outstandingShares
    state
    isActive
    issueDate
    boardApprovalDate
    vestingStartBackdate
    unitsVested
    createdAt
    status
    outstandingStatus
    awardPending
    certificatePending
    noticePending
    fieldsMissingForPublish {
      field
      required
    }
    equityPlan {
      id
      name
    }
    vestingTemplate {
      id
    }
    rsaGrant {
      id
      name
      filledOut
      fieldsMissingForPublish {
        field
        required
      }
      grantState
      grantDocumentSet {
        id
        hasMissingSignatureBlockFields
      }
      groupingGrantStatus
      transactionStatus
      grantedAt
      equityPlan {
        id
        name
      }
      shareCertificate {
        id
        unitsVested
        numShares
        certificateId
      }
      stakeholder {
        id
        entityName
        entityType
        role
      }
      grantIssuance {
        id
        completedAt
      }
    }
    pricePerShare {
      dollars
      fractional
      currency
      betterFormat
    }
    capitalContribution {
      dollars
      fractional
      currency
      betterFormat
    }
    valueOfIpContribution {
      fractional
      dollars
      currency
    }
    valueOfDebtCancelled {
      fractional
      dollars
      currency
    }
    valueOfOtherConsideration {
      fractional
      dollars
      currency
    }
    esopScheme {
      id
      name
    }
    shareClass {
      name
      id
      preferenceLevel
    }
    shareholder {
      id
      entityName
      entityType
      role
      user {
        id
        email
      }
      stakeholder {
        id
        name
      }
    }
    securityInvite {
      id
      issuanceMethod
      status
      sentAt
      recipientEmail
      certificateIssuance {
        id
      }
    }
    convertedAt
    convertedToId
    conversionNotes
    converted
  }
`;

export default SHARE_CERTIFICATE_SUMMARY_FRAGMENT;
