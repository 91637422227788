export const BOARD_APPROVAL_STATUS_OPTIONS = [
  {
    label: 'Yes',
    value: 'approved',
  },
  {
    label: 'No (or unknown)',
    value: 'not_approved',
  },
];

export const HELP_URLS = {
  RAISE: `https://help.angelliststack.com/fundraising/raise/what-is-raise`,
  ROLLUPS: `https://help.angelliststack.com/guides/equity-administration/safe-cleanups`,
  ROLLUPS_MARKETING: `https://angellist.com/startups/equity/safe-cleanups`,
  CLOSING_RUV: `https://help.angelliststack.com/fundraising/ruv/closing-an-ruv`,
  STATE_REGULATORY_FEES: `https://help.angelliststack.com/fundraising/ruv/setting-up-an-ruv#fees-and-economics`,
  STAKEHOLDER: {
    DELETE_STAKEHOLDER:
      'https://help.angelliststack.com/guides/equity-administration/stakeholder-management#how-do-i-change-the-login-email-for-a-stakeholder',
  },
  SHAREHOLDERS: {
    AFTER_INVITE: `https://help.angelliststack.com/guides/stakeholder-user-experience/stakeholder-experience#how-does-a-stakeholder-login-to-their-dashboard-after-they've-accepted-their-invitation`,
  },
  STOCK_SPLIT: 'https://help.angelliststack.com/guides',
};

export const INDUSTRY_OPTIONS = [
  { label: 'Software', value: 'Software' },
  { label: 'Finance and Insurance', value: 'Finance and Insurance' },
  {
    label: 'Real Estate and Rental and Leasing',
    value: 'Real Estate and Rental and Leasing',
  },
  {
    label: 'Health Care and Social Assistance',
    value: 'Health Care and Social Assistance',
  },
  {
    label: 'Arts, Entertainment, and Recreation',
    value: 'Arts, Entertainment, and Recreation',
  },
  { label: 'Construction', value: 'Construction' },
  {
    label: 'Agriculture, Forestry, Fishing and Hunting',
    value: 'Agriculture, Forestry, Fishing and Hunting',
  },
  { label: 'Educational Services', value: 'Educational Services' },
  { label: 'Other Services', value: 'Other Services' },
  { label: 'Manufacturing', value: 'Manufacturing' },
  {
    label: 'Transportation and Warehousing',
    value: 'Transportation and Warehousing',
  },
];

export const STOCK_ISSUANCE_TYPES = {
  NON_TRACKING_STOCK_ISSUANCE: 'None - Tracking Only',
  UNCERTIFICATED_STOCK_ISSUANCE: 'Uncertificated / Certificate Free Issuances',
  CERTIFICATED_STOCK_ISSUANCE: 'Digital Certificate Generation',
};

export const ISSUANCE_METHODS = {
  BOOK_ENTRY_NO_EMAIL: 'book_entry_no_email',
  CERTIFICATE_FREE: 'certificate_free',
  CERTIFICATE: 'certificate',
  INVITE: 'invite',
};

export const CERTIFICATE_TYPE_OPTIONS = [
  {
    value: ISSUANCE_METHODS.BOOK_ENTRY_NO_EMAIL,
    label: 'Book Entry Only (No Email or Notice of Issuance)',
    mapValue: STOCK_ISSUANCE_TYPES.NON_TRACKING_STOCK_ISSUANCE,
    displayValue: 'Book Entry Only',
  },
  {
    value: ISSUANCE_METHODS.CERTIFICATE_FREE,
    label: 'Certificate Free Stock Issuance (Includes notice of issuance)',
    mapValue: STOCK_ISSUANCE_TYPES.UNCERTIFICATED_STOCK_ISSUANCE,
    displayValue: 'Certificate Free Stock Issuance',
  },
  {
    value: ISSUANCE_METHODS.CERTIFICATE,
    label: 'Certificated Stock Issuance (Requires Company Signatures)',
    mapValue: STOCK_ISSUANCE_TYPES.CERTIFICATED_STOCK_ISSUANCE,
    displayValue: 'Certificated Stock Issuance',
  },
];
