import { useQuery } from '@apollo/client';

import { useCompanyContext } from '../../../context/Context';
import ALL_VALUATIONS_QUERY from '../queries/allValuationsQuery';
import { Valuation } from '../../../../graphql';
import { cloneList } from '../../../utils';

const useValuations = (): [boolean, Valuation[]] => {
  const { id: companyId } = useCompanyContext();
  const { loading, error, data } = useQuery(ALL_VALUATIONS_QUERY, {
    variables: { companyId },
  });

  if (error) {
    throw error;
  }

  const valuations = cloneList(data?.allValuations);

  return [loading, valuations];
};

export default useValuations;
