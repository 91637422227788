import { getOptionsNameMap } from '../../helpers/utils';

export const STATUS = {
  DRAFT: 'draft',
  ACTIVE: 'active',
  CANCELLED: 'cancelled',
  REPURCHASED: 'repurchased',
  CONVERTED: 'converted',
};

export const STATUS_SORT_ORDER = {
  [STATUS.DRAFT]: 0,
  [STATUS.ACTIVE]: 1,
  [STATUS.CANCELLED]: 2,
  [STATUS.REPURCHASED]: 3,
  [STATUS.CONVERTED]: 4,
};

export const STATUS_OPTIONS = [
  {
    key: STATUS.DRAFT,
    label: 'Draft',
    value: STATUS.DRAFT,
  },
  {
    key: STATUS.ACTIVE,
    label: 'Active',
    value: STATUS.ACTIVE,
  },
  {
    key: STATUS.CONVERTED,
    label: 'Converted',
    value: STATUS.CONVERTED,
  },
  {
    key: STATUS.CANCELLED,
    label: 'Cancelled',
    value: STATUS.CANCELLED,
  },
  {
    key: STATUS.REPURCHASED,
    label: 'Repurchased',
    value: STATUS.REPURCHASED,
  },
];

export const STATUS_FILTER_MAP: any = {
  drafts: [STATUS.DRAFT],
  issued: [
    STATUS.ACTIVE,
    STATUS.CANCELLED,
    STATUS.CONVERTED,
    STATUS.REPURCHASED,
  ],
};

export const FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: 'drafts',
    value: 'drafts',
    label: 'Drafts',
  },
  {
    key: 'issued',
    value: 'issued',
    label: 'Issued',
  },
];

export const CONVERTIBLE_TYPES = {
  CONVERTIBLE_SAFE: 'ConvertibleSafe',
  CONVERTIBLE_DEBT: 'ConvertibleDebt',
  WARRANT_CERTIFICATE: 'WarrantCertificate',
  TOKEN_WARRANT: 'TokenWarrant',
  SAFT: 'Saft',
};

export const CONVERTIBLE_GQL_SQL_TYPE_MAP = {
  ConvertibleSafe: 'ConvertibleSafeInstrument',
  ConvertibleDebt: 'ConvertibleDebt',
  WarrantCertificate: 'WarrantCertificate',
  TokenWarrant: 'CryptoInstrument::TokenWarrant',
  Saft: 'CryptoInstrument::Saft',
};

export const CONVERTIBLE_TYPES_LIST = Object.values(CONVERTIBLE_TYPES);

export const CONVERTIBLE_DEBT_CONVERTIBLE_TYPE_NAME_MAP = {
  convertible_note: 'Convertible Note',
  ccd: 'CCD',
  ocd: 'OCD',
};
export const CONVERTIBLE_TYPE_OPTIONS = [
  {
    key: CONVERTIBLE_TYPES.CONVERTIBLE_SAFE,
    label: 'SAFE',
    value: CONVERTIBLE_TYPES.CONVERTIBLE_SAFE,
  },
  {
    key: CONVERTIBLE_TYPES.CONVERTIBLE_DEBT,
    label: 'Convertible Note',
    value: CONVERTIBLE_TYPES.CONVERTIBLE_DEBT,
  },
  {
    key: CONVERTIBLE_TYPES.WARRANT_CERTIFICATE,
    label: 'Warrant',
    value: CONVERTIBLE_TYPES.WARRANT_CERTIFICATE,
  },
  {
    key: CONVERTIBLE_TYPES.TOKEN_WARRANT,
    label: 'Token Warrant',
    value: CONVERTIBLE_TYPES.TOKEN_WARRANT,
  },
  {
    key: CONVERTIBLE_TYPES.SAFT,
    label: 'SAFT',
    value: CONVERTIBLE_TYPES.SAFT,
  },
];

export const CONVERTIBLE_TYPE_NAME_MAP = getOptionsNameMap(
  CONVERTIBLE_TYPE_OPTIONS,
);

export const CAPITAL_CONTRIBUTION_FIELD_FOR_SECURITY = {
  ConvertibleSafe: 'capitalContributionForCompany',
  ConvertibleDebt: 'capitalContributionForCompany',
  WarrantCertificate: 'capitalContribution',
  Saft: 'purchaseAmount',
  TokenWarrant: 'purchasePrice',
};
