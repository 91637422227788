import { ObjectType } from '../../../types';
import { formatTitle, getOptionsNameMap } from '../../helpers/utils';
import {
  employeeColumns,
  equityPlanColumns,
  optionGrantColumns,
  shareholderColumns,
  terminateEmployeeColumns,
  optionExerciseColumns,
  shareClassColumns,
  stakeholderColumns,
  grantDocumentSetColumns,
  shareCertificateColumns,
} from './utils';

import paths from '../../paths';
import BulkFixEmployeeHealthCheckButton from './components/BulkFixEmployeeHealthCheckButton';
import BulkEnableExerciseCheckButton from './components/BulkEnableExerciseCheckButton';

export const STATUS = {
  ACTIVE: 'active',
  RESOLVED: 'resolved',
  DELETED: 'deleted',
};

export const CATEGORIES = {
  VALUATION: 'valuation',
  SHARE_CLASS: 'share_class',
  SECURITIES: 'securities',
  COMMON: 'common',
  EQUITY_PLAN: 'equity_plan',
  STAKEHOLDER: 'stakeholder',
  BANKING: 'banking',
  ESOP_GRANT: 'esop_grant',
  BOARD_CONSENT: 'board_consent',
  ESOP_EXERCISE: 'esop_exercise',
  COMPANY_CONFIGURATION: 'company_configuration',
  SHARE_CERTIFICATE: 'share_certificate',
};

export const DEGREE = {
  RECOMMENDED: 'recommended',
  PRIORITY: 'priority',
  HEALTHY: 'healthy',
};

export const STATUS_GROUPS = [
  [STATUS.ACTIVE, [STATUS.ACTIVE]],
  [STATUS.RESOLVED, [STATUS.RESOLVED]],
  [STATUS.DELETED, [STATUS.DELETED]],
];

export const STATUS_OPTIONS = [
  {
    label: 'Active',
    value: STATUS.ACTIVE,
  },
  {
    label: 'Resolved',
    value: STATUS.RESOLVED,
  },
  {
    label: 'Deleted',
    value: STATUS.DELETED,
  },
];

export const STATUS_DISPLAY_NAME: ObjectType = getOptionsNameMap(
  STATUS_OPTIONS,
);

export const DEGREE_FILTER_MAP: any = {
  priority: [DEGREE.PRIORITY],
  recommended: [DEGREE.RECOMMENDED],
};

export const FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: DEGREE.PRIORITY,
    value: DEGREE.PRIORITY,
    label: 'Priority',
  },
  {
    key: DEGREE.RECOMMENDED,
    value: DEGREE.RECOMMENDED,
    label: 'Recommended',
  },
];

export const STATUS_FILTER_ITEMS = [
  {
    key: 'all',
    value: 'all',
    label: 'All',
  },
  {
    key: DEGREE.PRIORITY,
    value: DEGREE.PRIORITY,
    label: 'Priority',
  },
  {
    key: DEGREE.RECOMMENDED,
    value: DEGREE.RECOMMENDED,
    label: 'Warning',
  },
  {
    key: DEGREE.HEALTHY,
    value: DEGREE.HEALTHY,
    label: 'Healthy',
  },
];

export const CHECK_TYPES = {
  // Equity plan
  EQUITY_PLAN_SIZE_OVERDRAWN: 'equity_plan_size_overdrawn',
  EQUITY_PLAN_DOCUMENT_NOT_CONFIGURED: 'equity_plan_document_not_configured',
  EQUITY_PLAN_SIZE_APPROACHING_OVERDRAWN:
    'equity_plan_size_approaching_overdrawn',
  EQUITY_PLAN_EXERCISE_NOT_ENABLED: 'equity_plan_exercise_not_enabled',

  // Valuation
  VALUATION_MISSING_409A: 'valuation_missing_409a',
  VALUATION_APPROACHING_EXPIRY_409A: 'valuation_approaching_expiry_409a',
  VALUATION_EXPIRED_409A: 'valuation_expired_409a',

  // COI
  COI_MISSING_SHARE_CLASSES: 'coi_missing_share_classes',

  // Employee
  EMPLOYEE_MISSING_ADDRESS: 'employee_missing_address',
  EMPLOYEE_MISSING_EMAIL: 'employee_missing_email',
  EMPLOYEE_SHAREHOLDER_NOT_LINKED: 'employee_shareholder_not_linked',
  HRIS_TERMINATED_EMPLOYEES: 'hris_terminated_employees',
  EMPLOYMENT_TYPE_MISSING: 'missing_employment_type',
  EMPLOYEE_MISSING_GRANT: 'employee_missing_grant',

  // Shareholder
  SHAREHOLDER_MISSING_EMAIL: 'shareholder_missing_email',

  // Equity Grant
  EQUITY_GRANT_MISSING_VESTING_EVENTS: 'equity_grant_missing_vesting_events',
  EQUITY_GRANT_MISSING_VESTING_SCHEDULE_MISALIGNED:
    'equity_grant_missing_vesting_schedule_misaligned',
  EQUITY_GRANT_ISO_NSO_MISMATCH: 'equity_grant_iso_nso_mismatch',
  EQUITY_GRANT_GRANTED_OPTION_VESTING_MISALIGNED:
    'equity_grant_granted_option_vesting_misaligned',
  EQUITY_GRANT_EMPLOYEE_ESIGN_PENDING: 'equity_grant_employee_esign_pending',
  EQUITY_GRANT_COMPANY_ESIGN_PENDING: 'equity_grant_company_esign_pending',
  SECURITY_BOARD_CONSENT_MISSING: 'security_board_consent_missing',
  EQUITY_GRANT_INVALID_CONSENT: 'equity_grant_invalid_consent',
  EQUITY_GRANT_MISSING_EXERCISE_PERIOD: 'equity_grant_missing_exercise_period',
  EQUITY_GRANT_MISSING_EXERCISE_AGREEMENT:
    'equity_grant_missing_exercise_agreement',
  EQUITY_GRANT_MISSING_GRANT_TERM_END_DATE:
    'equity_grant_missing_grant_term_end_date',
  EQUITY_GRANT_NOT_EXERCISE_ELIGIBLE: 'equity_grant_not_exercise_eligible',
  EQUITY_GRANT_OVER_EXERCISED: 'equity_grant_over_exercised',
  EQUITY_GRANT_EXCESS_RETURNING_OPTIONS:
    'equity_grant_excess_returning_options',
  EQUITY_GRANT_VESTING_AFTER_STAKEHOLDER_TERMINATION:
    'equity_grant_vesting_after_stakeholder_termination',

  // ESOP Exercise
  ESOP_EARLY_EXERCISE_MISSING_83B: 'early_exercise_missing_83b',
  COMPLETED_EXERCISE_WITHOUT_SHARE: 'completed_exercise_without_share',

  // Company Signatory
  COMPANY_SIGNATORY_MISSING: 'company_signatory_missing',

  // Stakeholder
  STAKEHOLDER_MISSING_ADDRESS: 'stakeholder_missing_address',

  // Grant Document Set
  GRANT_DOCUMENT_SET_ENABLE_COMPANY_SIGN:
    'grant_document_set_enable_company_sign',

  // Share Certificate
  SHARE_CERTIFICATE_MISSING_VESTING_EVENTS:
    'share_certificate_missing_vesting_events',
  SHARE_CERTIFICATE_VESTING_AFTER_STAKEHOLDER_TERMINATION:
    'share_certificate_vesting_after_stakeholder_termination',
  SHARE_CERTIFICATE_VESTING_SUM_EQUALS_NUM_GRANTED:
    'share_certificate_vesting_sum_equals_num_granted',
  PARTIAL_REPURCHASE_HAS_INCORRECT_SHARES:
    'partial_repurchase_has_incorrect_shares',
  PARTIAL_REPURCHASE_MISSING_BALANCE_TRANSFER_INSTRUMENT:
    'partial_repurchase_missing_balance_transfer_instrument',
  SHARE_ISSUED_EARLY_EXERCISE_MISSING_VESTING_EVENTS:
    'share_issued_early_exercise_missing_vesting_events',
  SHARE_ISSUED_FROM_EXERCISE_INCORRECT_SHARES:
    'share_issued_from_exercise_incorrect_shares',
  SHARE_IN_DRAFT_STATE_OVER_90_DAYS: 'share_in_draft_state_over_90_days',
  SHARE_VESTING_SUM_NOT_EQUAL_NUM_GRANTED:
    'share_vesting_sum_not_equal_num_granted',
};

export const CATEGORIES_SUFFIX_NAME_MAP: ObjectType = {
  valuation: 'plan',
  share_class: 'share class',
  securities: 'securities',
  common: '',
  banking: '',
  equity_plan: 'plan',
  stakeholder: 'stakeholder',
  esop_grant: 'grant',
  board_consent: 'board consent',
  grant_document_set: 'grant document set',
};

export const CATEGORY_OPTIONS = Object.values(CATEGORIES).map(
  (category: string) => ({
    label: formatTitle(category),
    value: category,
    key: category,
  }),
);

export const CATEGORY_DISPLAY_NAME: ObjectType = getOptionsNameMap(
  CATEGORY_OPTIONS,
);

export const EQUITY_PLAN_CHECK_TYPE_MAP = {
  [CHECK_TYPES.EQUITY_PLAN_SIZE_OVERDRAWN]: {
    title: 'Equity plan overdrawn',
    description:
      'The following equity plans have exceeded their authorized shares',
    columns: equityPlanColumns,
  },
  [CHECK_TYPES.EQUITY_PLAN_DOCUMENT_NOT_CONFIGURED]: {
    title: 'Equity Plan document not uploaded',
    description:
      'The following equity plans do not have plan documents associated with them. A properly adopted plan document is an important step in issuing grants.',
    columns: equityPlanColumns,
  },
  [CHECK_TYPES.EQUITY_PLAN_SIZE_APPROACHING_OVERDRAWN]: {
    title: 'Approaching plan size limit',
    description:
      'The following equity plans are approaching their authorized share limit',
    columns: equityPlanColumns,
  },
  [CHECK_TYPES.EQUITY_PLAN_EXERCISE_NOT_ENABLED]: {
    title: 'Exercise not enabled',
    description: 'The following equity plans do not have exercise enabled',
    columns: equityPlanColumns,
  },
};

export const GRANT_DOCUMENT_SET_CHECK_TYPE_MAP = {
  [CHECK_TYPES.GRANT_DOCUMENT_SET_ENABLE_COMPANY_SIGN]: {
    title: 'Enable company signature on exercise template',
    description:
      'The following grant document sets have the company signature disabled on the exercise template, but the exercise template contains {{company_signature_block}} template variable.',
    columns: grantDocumentSetColumns,
  },
};

export const SHARE_CERTIFICATE_CHECK_TYPE_MAP = {
  [CHECK_TYPES.SHARE_CERTIFICATE_MISSING_VESTING_EVENTS]: {
    title: 'Missing vesting events',
    description: 'The following share certificates are missing vesting events',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.SHARE_CERTIFICATE_VESTING_AFTER_STAKEHOLDER_TERMINATION]: {
    title: 'Vesting after stakeholder termination',
    description:
      'The following share certificates are vesting after stakeholder termination',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.SHARE_CERTIFICATE_VESTING_SUM_EQUALS_NUM_GRANTED]: {
    title: 'Vesting sum does not equal number of granted shares',
    description:
      'The following share certificates vesting sum does not equal the number of granted shares',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.PARTIAL_REPURCHASE_HAS_INCORRECT_SHARES]: {
    title: 'Repurchased share value',
    description:
      'The following partial repurchase events may record an incorrect number of repurchased shares',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.PARTIAL_REPURCHASE_MISSING_BALANCE_TRANSFER_INSTRUMENT]: {
    title: 'Partial repurchase missing balance transfer instrument',
    description:
      'The following partial repurchase events are missing a balance transfer instrument',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.SHARE_ISSUED_EARLY_EXERCISE_MISSING_VESTING_EVENTS]: {
    title: 'Share issued early exercise missing vesting events',
    description:
      'The following shares were issued from an early exercise but do not have any associated vesting events',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.SHARE_ISSUED_FROM_EXERCISE_INCORRECT_SHARES]: {
    title: 'Share issued from exercise incorrect shares',
    description:
      'The following shares were issued from an exercise event with an incorrect number of shares',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.SHARE_IN_DRAFT_STATE_OVER_90_DAYS]: {
    title: 'Share in draft state for more than 90 days',
    description:
      'The following shares have been in draft state for more than 90 days',
    columns: shareCertificateColumns,
  },
  [CHECK_TYPES.SHARE_VESTING_SUM_NOT_EQUAL_NUM_GRANTED]: {
    title: 'Vesting sum does not equal number of granted shares',
    description:
      "The following share certificates' vesting sums do not equal the number of granted shares",
    columns: shareCertificateColumns,
  },
};

export const VALUATION_CHECK_TYPE_MAP = {
  [CHECK_TYPES.VALUATION_MISSING_409A]: {
    title: 'No 409A',
    description:
      'No 409A valuation has been uploaded. If you are planning to issue options to employees, your company generally needs a 409A valuation.',
    nullState: true,
    buttonLabel: 'Request a new valuation',
    redirectionPath: paths.equity.valuations.all,
  },
  [CHECK_TYPES.VALUATION_APPROACHING_EXPIRY_409A]: {
    title: 'Approaching 409A expiry',
    description:
      'Your 409A valuation is approaching its expiration date. Request a new 409A valuation by clicking below.',
    nullState: true,
    buttonLabel: 'Request a new valuation',
    redirectionPath: paths.equity.valuations.all,
  },
  [CHECK_TYPES.VALUATION_EXPIRED_409A]: {
    title: 'Expired 409A',
    description:
      'Your 409A valuation has expired. You can request a new 409A valuation by clicking below.',
    nullState: true,
    buttonLabel: 'Request a new valuation',
    redirectionPath: paths.equity.valuations.all,
  },
};

export const EMPLOYEE_CHECK_TYPE_MAP = {
  [CHECK_TYPES.STAKEHOLDER_MISSING_ADDRESS]: {
    title: 'Missing stakeholder address',
    description:
      'The following stakeholders are missing addresses. Addresses are required to calculate tax withholding.',
    columns: stakeholderColumns,
  },
  [CHECK_TYPES.EMPLOYEE_MISSING_EMAIL]: {
    title: 'Missing employee email address',
    description:
      'The following employees are missing email addresses. Email addresses are required to issue equity grants.',
    columns: employeeColumns,
  },
  [CHECK_TYPES.EMPLOYEE_SHAREHOLDER_NOT_LINKED]: {
    title: 'Link employee shareholders',
    description:
      'The following employees are also shareholders and have not had their employee profile linked to their shareholdings. On the employee profile, click "Actions -> Link Shareholder" in order to link.',
    columns: employeeColumns,
  },
  [CHECK_TYPES.HRIS_TERMINATED_EMPLOYEES]: {
    title: 'HRIS terminated service providers',
    description:
      'The following service providers are terminated in your HRIS but not in AngelList. Please review and confirm if they should be terminated in AngelList.',
    columns: terminateEmployeeColumns,
  },
  [CHECK_TYPES.EMPLOYMENT_TYPE_MISSING]: {
    title: 'Missing service provider type',
    description:
      "Service provider relationship type is required to calculate withholding. Click 'Bulk Resolve' to set the service provider type for all stakeholders.",
    columns: employeeColumns,
    headerActionComponent: BulkFixEmployeeHealthCheckButton,
  },
  [CHECK_TYPES.EMPLOYEE_MISSING_GRANT]: {
    title: 'Employee missing equity grant',
    description: 'Employee missing equity grant',
    columns: employeeColumns,
  },
};

export const SHAREHOLDER_CHECK_TYPE_MAP = {
  [CHECK_TYPES.SHAREHOLDER_MISSING_EMAIL]: {
    title: 'Missing shareholder email address',
    description:
      'The following shareholders are missing email addresses. Email addresses are required to issue share instruments.',
    columns: shareholderColumns,
  },
};

export const ESOP_EXERCISE_CHECK_TYPE_MAP = {
  [CHECK_TYPES.ESOP_EARLY_EXERCISE_MISSING_83B]: {
    title: 'Missing 83(b) election',
    description:
      'The following equity grants that were early-exercised do not have 83(b) election documents uploaded.',
    columns: optionExerciseColumns,
  },
  [CHECK_TYPES.COMPLETED_EXERCISE_WITHOUT_SHARE]: {
    title: 'Completed exercise lacks associated share security',
    description:
      'The following equity grants that were exercised do not have a corresponding share security.',
    columns: optionExerciseColumns,
  },
};

export const EQUITY_GRANT_CHECK_TYPE_MAP = {
  [CHECK_TYPES.EQUITY_GRANT_MISSING_VESTING_EVENTS]: {
    title: 'Missing vesting events',
    description: 'The following equity grants are missing vesting events',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_MISSING_VESTING_SCHEDULE_MISALIGNED]: {
    title: 'Vesting schedule misaligned',
    description: 'The following vesting schedules contain inconsistencies',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_ISO_NSO_MISMATCH]: {
    title: 'ISO/NSO mismatch',
    description:
      'The following equity awards are listed as ISOs but exceed the $100,000 annual ISO limit',
    columns: employeeColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_GRANTED_OPTION_VESTING_MISALIGNED]: {
    title: 'Vesting schedule inconsistencies',
    description: 'The following vesting schedules contain inconsistencies',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_EMPLOYEE_ESIGN_PENDING]: {
    title: 'Pending grantee signature',
    description:
      'The following equity awards are pending e-signatures by the grantees',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_COMPANY_ESIGN_PENDING]: {
    title: 'Pending company signature',
    description:
      'The following equity awards are pending e-signature by the company',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.SECURITY_BOARD_CONSENT_MISSING]: {
    title: 'Missing board approval dates',
    description:
      'The following securities are missing recorded board approval dates',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_MISSING_EXERCISE_PERIOD]: {
    title: 'Missing post-termination exercise period terms',
    description:
      'The following equity awards are missing one or more post-termination exercise period terms',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_MISSING_EXERCISE_AGREEMENT]: {
    title: 'Missing exercise agreement template',
    description:
      'The following options are missing the option exercise template',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_INVALID_CONSENT]: {
    title: 'Missing linked board consent document',
    description:
      'The following grants are not associated with a linked board consent document',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_MISSING_GRANT_TERM_END_DATE]: {
    title: 'Missing grant term end date',
    description: 'The following grants are missing the grant term end date',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_NOT_EXERCISE_ELIGIBLE]: {
    title: 'Outstanding grant not exercisable',
    description:
      'The following grants are not currently exercisable on AngelList due to missing data or template configuration issues.',
    columns: optionGrantColumns,
    headerActionComponent: BulkEnableExerciseCheckButton,
  },
  [CHECK_TYPES.EQUITY_GRANT_EXCESS_RETURNING_OPTIONS]: {
    title: 'Excess returning options',
    description:
      'The following grants have more options returning to the pool than were issued.',
    columns: optionGrantColumns,
  },
  [CHECK_TYPES.EQUITY_GRANT_VESTING_AFTER_STAKEHOLDER_TERMINATION]: {
    title: 'Vesting after stakeholder termination',
    description:
      'The following grants have vesting events after the stakeholder termination date',
    columns: optionGrantColumns,
  },
};

export const COMPANY_CONFIGURATION_CHECK_TYPE_MAP = {
  [CHECK_TYPES.COMPANY_SIGNATORY_MISSING]: {
    title: 'Company signatory missing',
    description: 'No company signatory is set',
    nullState: true,
    buttonLabel: 'Set company signatory',
    redirectionPath: paths.company.signatories,
  },
  [CHECK_TYPES.COI_MISSING_SHARE_CLASSES]: {
    title: 'Share class from COI not found',
    description: 'Share classes that were detected from the COI are missing',
    columns: shareClassColumns,
  },
};

export const CHECK_TYPE_MAP: any = {
  ...EQUITY_PLAN_CHECK_TYPE_MAP,
  ...VALUATION_CHECK_TYPE_MAP,
  ...EMPLOYEE_CHECK_TYPE_MAP,
  ...SHAREHOLDER_CHECK_TYPE_MAP,
  ...EQUITY_GRANT_CHECK_TYPE_MAP,
  ...ESOP_EXERCISE_CHECK_TYPE_MAP,
  ...COMPANY_CONFIGURATION_CHECK_TYPE_MAP,
  ...GRANT_DOCUMENT_SET_CHECK_TYPE_MAP,
  ...SHARE_CERTIFICATE_CHECK_TYPE_MAP,
};
