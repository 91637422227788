import React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'reactstrap';
import { FileType, Modal, Stack } from '@angellist/adapt';
import { ApolloError } from '@apollo/client';

import ErrorAlert from '../ErrorAlert';
import { FileUpload } from '../adapt-form';
import { processError } from '../../../helpers/utils';
import { ModalActions } from '../../../../types';

type Props = {
  name?: string;
  uploadError?: ApolloError;
  onUpload: (formData: object) => void;
  isUploading?: boolean;
  headerText?: string;
  isRequired?: boolean;
  multiple?: boolean;
  supportedFileTypes?: FileType[];
} & Pick<ModalActions, 'modal' | 'closeModal'>;

const UploadModal = (props: Props) => {
  const {
    closeModal,
    modal,
    onUpload,
    uploadError,
    isUploading,
    headerText,
    name,
    isRequired,
    multiple,
    ...restProps
  } = props;
  const { control, handleSubmit, errors } = useForm();
  const uploadState = isUploading ? 'in-progress' : undefined;

  return (
    <Modal
      header={{
        title: headerText,
        pinned: true,
        buttons: [
          {
            key: 'submit',
            label: 'Upload',
            isDisabled: isUploading,
            isLoading: isUploading,
            onPress: (e: any) => {
              handleSubmit(onUpload)(e);
            },
          },
        ],
      }}
      onClose={closeModal}
      isOpen={modal}
    >
      <Form autoComplete="off">
        <Stack gap="100">
          <ErrorAlert error={uploadError} />
          <FileUpload
            name={name}
            maxSizeInMb={1500}
            control={control}
            multiple={multiple}
            state={uploadState}
            rules={{ required: isRequired }}
            error={processError(errors[name])}
            {...restProps}
          />
        </Stack>
      </Form>
    </Modal>
  );
};

UploadModal.defaultProps = {
  name: 'documents',
  isUploading: false,
  isRequired: true,
  multiple: true,
  headerText: 'Upload Document',
};

export default UploadModal;
